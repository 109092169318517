import React, { useMemo, useReducer } from 'react';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import AppContent from './authPages/AppContent/AppContent';
import { AppContext } from './Context/AppContext';
import { EditFeatureInitialState } from './Reducers/InitialStates/EditFeatureInitialState';
import { PpaInsightInitialState } from './Reducers/InitialStates/PpaInsightInitialState';
import { BiaReportsReducers } from './Reducers/ActionHandlers/BiaReportsReducers';
import { BiaReportsInitialState } from './Reducers/InitialStates/BiaReportsInitialState';
import { AnalyticsHomeReducers } from './Reducers/ActionHandlers/AnalyticsHomeReducers';
import { AnalyticsHomeInitialState } from './Reducers/InitialStates/AnalyticsHomeInitialState';
import { CiTradeAndVolumeReducers } from './Reducers/ActionHandlers/CiTradeAndVolumeReducers';
import { CiTradeAndVolumeInitialState } from './Reducers/InitialStates/CiTradeAndVolumeInitialState';
import { DataEditReducers } from './Reducers/ActionHandlers/DataEditReducers';
import { PpaInsightReducers } from './Reducers/ActionHandlers/PpaInsightReducers';
import { AnalyticsSnapshotReportReducers } from './Reducers/ActionHandlers/AnalyticsSnapshotReportReducers';
import { AnalyticsSnapshotReportInitialState } from './Reducers/InitialStates/AnalyticsSnapshotReportInitialState';
import { PptReportsInitialState } from './Reducers/InitialStates/PptInitialState';
import { PptReportsReducers } from './Reducers/ActionHandlers/PptReducers';
import { ReconReportsReducers } from './Reducers/ActionHandlers/ReconReducers';
import { ReconReportsInitialState } from './Reducers/InitialStates/ReconInitialState';

const App = () => {
  const [state, dispatch] = useReducer(
    DataEditReducers,
    EditFeatureInitialState
  );
  const [ppaInsightState, ppaInsightDispatch] = useReducer(
    PpaInsightReducers,
    PpaInsightInitialState
  );
  const [biaReportState, biaReportDispatch] = useReducer(
    BiaReportsReducers,
    BiaReportsInitialState
  );
  const [analyticsHomeState, analyticsHomeDispatch] = useReducer(
    AnalyticsHomeReducers,
    AnalyticsHomeInitialState
  );
  const [ciTradeAndVolumeState, ciTradeAndVolumeDispatch] = useReducer(
    CiTradeAndVolumeReducers,
    CiTradeAndVolumeInitialState
  );

  const [
    analyticsSnapshotReportState,
    analyticsSnapshotReportDispatch,
  ] = useReducer(
    AnalyticsSnapshotReportReducers,
    AnalyticsSnapshotReportInitialState
  );
  const [pptReportsState, pptReportsDispatch] = useReducer(
    PptReportsReducers,
    PptReportsInitialState
  );
  const [reconReportsState, reconReportsDispatch] = useReducer(
    ReconReportsReducers,
    ReconReportsInitialState
  );

  const value = useMemo(
    () => ({
      appData: { state, dispatch },
      ppaInsight: { state: ppaInsightState, dispatch: ppaInsightDispatch },
      biaReports: {
        state: biaReportState,
        dispatch: biaReportDispatch,
      },
      analyticsHome: {
        state: analyticsHomeState,
        dispatch: analyticsHomeDispatch,
      },
      ciTradeAndVolume: {
        state: ciTradeAndVolumeState,
        dispatch: ciTradeAndVolumeDispatch,
      },
      analyticsSnapshotReport: {
        state: analyticsSnapshotReportState,
        dispatch: analyticsSnapshotReportDispatch,
      },
      pptReports: {
        state: pptReportsState,
        dispatch: pptReportsDispatch,
      },
      reconReports: {
        state: reconReportsState,
        dispatch: reconReportsDispatch,
      },
    }),
    [
      state,
      dispatch,
      ppaInsightState,
      ppaInsightDispatch,
      biaReportState,
      biaReportDispatch,
      analyticsHomeState,
      analyticsHomeDispatch,
      ciTradeAndVolumeState,
      ciTradeAndVolumeDispatch,
      analyticsSnapshotReportState,
      analyticsSnapshotReportDispatch,
      pptReportsState,
      pptReportsDispatch,
      reconReportsState,
      reconReportsDispatch,
    ]
  );

  return (
    <ShellThemeProvider theme="light">
      <AppContext.Provider value={value}>
        <AppContent />
      </AppContext.Provider>
    </ShellThemeProvider>
  );
};

export default App;