import React, { useContext, useState } from 'react';
import { BaseTable, Divider, Flexbox, Pagination } from '@sede-x/shell-ds-react-framework';
import { getPaginationRowModel } from '@tanstack/react-table';
import { AppContext } from '../../../../../../Context/AppContext';
import DeltaTableTotalValues from '../DeltaTableTotalValues/DeltaTableTotalValues';
import DeltaTableFilters from '../DeltaTableFilters/DeltaTableFilters';
import { ChartContainer, TableHeading } from '../ReconTotalValuesTable/ReconTotalValuesTable.style';

const DeltaTable: React.FC = () => {
  const { state: reconState, dispatch: reconDispatch } = useContext(AppContext).reconReports;
  const [activeFilter, setActiveFilter] = useState<string | null>('DELTA_DETAILS');

  const handleButtonClick = (buttonName: string) => {
    setActiveFilter(buttonName);
      const payload = {
        ...reconState.ReconfilteredValues,
        reportType: buttonName,
      };
      reconDispatch({ type: 'setReconFilteredValues', payload });
  };

  const defaultData = reconState.reconReportVolumeTableData.data || [];
  const filteredData = reconState.reconReportNcfCategoryMismatchTableData || [];

  const defaultColumns = defaultData.length > 0 ? Object.keys(defaultData[0]).map(key => ({
    header: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
    accessorKey: key,
  })) : [];

  const filteredColumns = filteredData.length > 0 ? Object.keys(filteredData[0]).map(key => ({
    header: key.replace(/_/g, ' '), // Replace underscores with spaces for headers
    accessorKey: key,
  })) : [];

  const updateConfigRulePaginationData = (pageNumber: number) => {

    const payload = {
      ...reconState.ReconfilteredValues,
      pageNumber,
    };
    reconDispatch({ type: 'setReconFilteredValues', payload });

  }

  const tableOptions = {
    getPaginationRowModel: getPaginationRowModel()
  };


  return (
    <div>
      <DeltaTableFilters activeFilter={activeFilter} onFilterClick={handleButtonClick} />
      <ChartContainer data-testid="map-container">
        <Flexbox
          style={{ width: '100%', marginBottom: '20px' }}
          justifyContent="space-between"
          flexDirection='column'
        >
          <TableHeading>Delta Details</TableHeading>
          {/* <span style={{ marginBottom: '20px' }}>Showing 20 of 1000</span> */}
          <Divider size="medium" direction="horizontal" />
        </Flexbox>
        <div>
          {activeFilter === 'VALUE_MISMATCH' ? (
            <div>
              <BaseTable
                columns={filteredColumns}
                data={filteredData}
                size="large"
                data-testid="filtered-table"
                style={{ width: '100%', border: '1px solid #C0C0C0', borderRadius: '10px' }}
                className="custom-header"
                tableOptions={tableOptions}
              />
            </div>
          ) : (
            <div>
              <DeltaTableTotalValues />
              <BaseTable
                columns={defaultColumns}
                data={defaultData}
                size="large"
                data-testid="pipeline-gas-table"
                style={{ width: '100%', border: '1px solid #C0C0C0', borderRadius: '10px' }}
                className="custom-header"
              />
              {defaultData.length > 0 && (
                <Pagination
                  data-testid="pagination"
                  total={171}
                  pageSize={5}
                  onChange={(pageNumber: number) =>
                    updateConfigRulePaginationData(pageNumber)
                  }
                />
              )}
            </div>
          )}
        </div>
        <div>

        </div>
      </ChartContainer>
    </div>
  );
};

export default DeltaTable;