import { IppaInsightData } from '../../types/types';

export const PpaInsightInitialState: IppaInsightData = {
  isLoading: false,
  isPpaServerError: false,
  filterOptions: {
    contractTypes: [],
    quarters: [],
    regions: [],
    renewableCategories: [],
    tenures: [],
    years: [],
  },
  filteredValues: {
    year: 2023,
    quarter: '',
    region: '',
    renewableCategory: '',
    tenure: '',
    contractType: '',
  },
  tableData: {
    count: 0,
    rows: [],
  },
  volumeByRegionData: [
    {
      REGION: '',
      CAPACITY_EQUIVALENT: 0,
      PERCENTAGE: '',
    },
  ],
  volumeByTechTypeData: [
    {
      RENEWABLE_CATEGORY: '',
      CAPACITY_EQUIVALENT: 0,
    },
  ],
  longVsShortTermData: {
    longTermPercentage: '',
    longTermTotal: 0,
    shortTermPercentage: '',
    shortTermTotal: 0,
  },
};

export type IppaInsightDataState = typeof PpaInsightInitialState;
