import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import React, { useContext } from 'react';
import { AppContext } from '../../../../Context/AppContext';
import {
  COMMENTS_ERROR_MESSAGE,
  COUNTRY_NAME_ERROR_MESSAGE,
  QTY_ERROR_MESSAGE,
  QTY_WARNING_MESSAGE,
} from '../../../../api/constants';

export const TableAlert = (props: {
  isQtyExceedThreeWholeDigits: boolean;
  isDirtyData: boolean;
  isShowDeploymentToPowerBiMessage: boolean;
}) => {
  const {
    isQtyExceedThreeWholeDigits,
    isDirtyData,
    isShowDeploymentToPowerBiMessage,
  } = props;
  const { state } = useContext(AppContext).appData;
  return (
    <>
      {state.isAnyInvalidComment && (
        <Alert
          title="Please clear the errors"
          sentiment={Sentiments.Negative}
          elevation
        >
          {COMMENTS_ERROR_MESSAGE}
        </Alert>
      )}
      {state.isAnyInvalidCountryName && (
        <Alert
          title="Please clear the errors"
          sentiment={Sentiments.Negative}
          elevation
        >
          {COUNTRY_NAME_ERROR_MESSAGE}
          <strong>IN SCOPE</strong>.
        </Alert>
      )}
      {state.isAnyInvalidQuantity && (
        <Alert
          title="Please clear the errors"
          sentiment={Sentiments.Negative}
          elevation
        >
          {QTY_ERROR_MESSAGE}
        </Alert>
      )}
      {isQtyExceedThreeWholeDigits && (
        <Alert sentiment={Sentiments.Warning} elevation>
          {QTY_WARNING_MESSAGE}
        </Alert>
      )}
      {!isDirtyData && (
        <Alert
          title="There are no changes to Save"
          sentiment={Sentiments.Negative}
          elevation
        >
          Please make changes to save.
        </Alert>
      )}
      {isShowDeploymentToPowerBiMessage && (
        <Alert sentiment={Sentiments.Information} elevation>
          Changes will be reflected in Power BI after 30 Mins
        </Alert>
      )}
    </>
  );
};
