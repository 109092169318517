import {
  Grid,
  Flexbox,
  Card,
  FormField,
  Stepper,
  Button,
  useFileXhrUploader,
  Modal,
} from '@sede-x/shell-ds-react-framework';
import {
  OnError,
  OnProgress,
} from '@sede-x/shell-ds-react-framework/build/esm/components/FileUploader/FileUploader.types.js';
import React, { useContext, useState } from 'react';
import { UpdateInfo } from './cardUtils';
import { uploadSectoralFiles } from '../../../api/services';
import { useFileUploadEffect } from './useFileUploadEffect';
import ReusableFileUploader from './ReusableFileUploader';
import {
  handleOnUpload,
  removeDeleteLinkInFileUploader,
} from './SectoralInfoUtils';
import { AppContext } from '../../../Context/AppContext';
import { hideSpinner, showSpinner } from '../../Utils/utils';
import {
  SECTORAL_STEP02_DESC,
  THOUSAND_FIVE_HUNDRED,
} from '../../../api/constants';

export const UploadMappingSheetCard = (props: {
  cardLog: string;
  setIsServerError: (isServerError: boolean) => void;
}) => {
  const { dispatch } = useContext(AppContext).appData;
  const [goldTierFileUploadSuccess, setGoldTierFileUploadSuccess] = useState(
    false
  );
  const [naceFileUploadSuccess, setNaceFileUploadSuccess] = useState(false);
  const [goldTierFileUploadState, setGoldTierFileUploadState] = useState(
    'none'
  ); // none, success, invalid
  const [naceFileUploadState, setNaceFileUploadState] = useState('none'); // none, success, invalid
  const [
    goldTierFileUploadFailureMessage,
    setGoldTierFileUploadFailureMessage,
  ] = useState('');
  const [
    naceFileUploadFailureMessage,
    setNaceFileUploadFailureMessage,
  ] = useState('');
  const [goldTierFile, setGoldTierFile] = useState<File | null>(null);
  const [naceFile, setNaceFile] = useState<File | null>(null);
  const [isFileUploadSuccess, setIsFileUploadSuccess] = useState(false);
  const url = '';
  const { handleOnDrop } = useFileXhrUploader({
    url,
  });

  // the following code is written to manually hide the progress bar.
  // A github issue is raised to SDS - https://github.com/sede-x/shell-ds-react-framework/issues/2727
  // TODO 1 : refactor the following code after the issue is fixed.
  useFileUploadEffect(
    'goldTierFileId',
    goldTierFileUploadSuccess,
    goldTierFileUploadState
  );
  useFileUploadEffect('naceFileId', naceFileUploadSuccess, naceFileUploadState);
  // END OF TODO 1

  const resetFileUploader = (
    newGoldTierFile: File | null,
    newNaceFile: File | null
  ) => {
    if (newGoldTierFile) {
      removeDeleteLinkInFileUploader('goldTierFileId');
    }
    if (newNaceFile) {
      removeDeleteLinkInFileUploader('naceFileId');
    }
  };

  const onRemoveGoldTierFile = () => {
    setGoldTierFile(null);
    setGoldTierFileUploadSuccess(false);
    setGoldTierFileUploadFailureMessage('');
    setGoldTierFileUploadState('none');
  };

  const onRemoveNaceFile = () => {
    setNaceFile(null);
    setNaceFileUploadSuccess(false);
    setNaceFileUploadFailureMessage('');
    setNaceFileUploadState('none');
  };

  const uploadFilesToDb = async () => {
    const formData = new FormData();
    if (goldTierFile) {
      formData.append('gold_tier_file', goldTierFile);
    }
    if (naceFile) {
      formData.append('nace_file', naceFile);
    }
    showSpinner(dispatch);
    try {
      await uploadSectoralFiles(formData);
      setIsFileUploadSuccess(true);
      setTimeout(() => {
        setIsFileUploadSuccess(false);
      }, THOUSAND_FIVE_HUNDRED);
      props.setIsServerError(false);
      resetFileUploader(goldTierFile, naceFile);
      onRemoveNaceFile();
      removeDeleteLinkInFileUploader('naceFileId');
    } catch (e) {
      //console error statement
      props.setIsServerError(true);
    }
    hideSpinner(dispatch);
  };

  return (
    <Card
      className="card"
      data-testid="card1"
      bodyPadding={true}
      header={{
        meta: 'Step 02',
        title: 'Upload Mapping Sheets',
        description: SECTORAL_STEP02_DESC,
      }}
      style={{
        borderRadius: '4px',
        background: 'var(--Background-Surface, #FFF)',

        /* Elevation/Level 2 */
        boxShadow:
          '0px 6px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <div
        style={{
          borderTop: '1px solid var(--Border-Subtle, rgba(28, 28, 28, 0.04))',
          paddingTop: '16px',
        }}
      >
        <Modal
          open={isFileUploadSuccess}
          onClose={() => {
            setIsFileUploadSuccess(false);
          }}
        >
          File(s) uploaded successfully.
        </Modal>
        <Flexbox gap="80px" justifyContent="center">
          <FormField
            icon={<Stepper.Step order={1} last={true} title="Gold Tier File" />}
          >
            <ReusableFileUploader
              fileId="goldTierFileId"
              onDrop={(
                files: File[],
                onProgress: OnProgress,
                onError: OnError
              ) => handleOnDrop(files, onProgress, onError)}
              onUpload={(files: File[]) =>
                handleOnUpload(files, 'goldTierFile', {
                  setGoldTierFile,
                  setGoldTierFileUploadSuccess,
                  setNaceFile,
                  setNaceFileUploadSuccess,
                  setGoldTierFileUploadState,
                  setGoldTierFileUploadFailureMessage,
                  setNaceFileUploadState,
                  setNaceFileUploadFailureMessage,
                })
              }
              onRemoveFile={onRemoveGoldTierFile}
              uploadState={goldTierFileUploadState}
              uploadFailureMessage={goldTierFileUploadFailureMessage}
            />
          </FormField>
          <FormField
            icon={<Stepper.Step order={2} title="NACE File" last={true} />}
          >
            <ReusableFileUploader
              fileId="naceFileId"
              onDrop={(
                files: File[],
                onProgress: OnProgress,
                onError: OnError
              ) => handleOnDrop(files, onProgress, onError)}
              onUpload={(files: File[]) =>
                handleOnUpload(files, 'naceFile', {
                  setGoldTierFile,
                  setGoldTierFileUploadSuccess,
                  setNaceFile,
                  setNaceFileUploadSuccess,
                  setGoldTierFileUploadState,
                  setGoldTierFileUploadFailureMessage,
                  setNaceFileUploadState,
                  setNaceFileUploadFailureMessage,
                })
              }
              onRemoveFile={onRemoveNaceFile}
              uploadState={naceFileUploadState}
              uploadFailureMessage={naceFileUploadFailureMessage}
            />
          </FormField>
        </Flexbox>
        <Grid flow="column" justifyContent="space-between">
          <Grid.Cell>
            <UpdateInfo text={props.cardLog} />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap="20px" justifyContent="center">
              <Button
                variant="filled"
                onClick={() => {
                  uploadFilesToDb();
                }}
                disabled={!goldTierFileUploadSuccess}
                style={{ fontSize: 'inherit', fontWeight: 'inherit' }}
              >
                Upload Mapping Sheets
              </Button>
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </div>
    </Card>
  );
};
