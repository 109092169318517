import { Button } from '@sede-x/shell-ds-react-framework';
import { FEEDBACK_LINK } from '../../api/constants';
import React from 'react';

const Feedback = () => {
  const handleFeedback = () => {
    window.open(FEEDBACK_LINK, '_blank');
  };

  return (
    <div className="feedback">
      <Button
        id="tool-feedback-btn"
        variant="outlined"
        className="btn-size"
        onClick={handleFeedback}
        data-testid="feedback"
      >
        Feedback
      </Button>
    </div>
  );
};
export default Feedback;
