import React, { createContext } from 'react';
import {
  Action,
  IEditFeatureData,
  IppaInsightData,
  IBiaReports,
  IAnalyticsHomeData,
  ICiTradeAndVolumeData,
  IPptData,
  IReconData,
} from '../types/types';
import { EditFeatureInitialState } from '../Reducers/InitialStates/EditFeatureInitialState';
import { PpaInsightInitialState } from '../Reducers/InitialStates/PpaInsightInitialState';
import { BiaReportsInitialState } from '../Reducers/InitialStates/BiaReportsInitialState';
import { AnalyticsHomeInitialState } from '../Reducers/InitialStates/AnalyticsHomeInitialState';
import { CiTradeAndVolumeInitialState } from '../Reducers/InitialStates/CiTradeAndVolumeInitialState';
import { AnalyticsSnapshotReportInitialState } from '../Reducers/InitialStates/AnalyticsSnapshotReportInitialState';
import { IAnalyticsSnapshotReportType } from '../types/IAnalyticsSnapshotReportType';
import { PptReportsInitialState } from '../Reducers/InitialStates/PptInitialState';
import { ReconReportsInitialState } from '../Reducers/InitialStates/ReconInitialState';


export const AppContext = createContext<{
  appData: {
    state: IEditFeatureData;
    dispatch: React.Dispatch<Action>;
  };
  ppaInsight: {
    state: IppaInsightData;
    dispatch: React.Dispatch<Action>;
  };
  biaReports: {
    state: IBiaReports;
    dispatch: React.Dispatch<Action>;
  };
  analyticsHome: {
    state: IAnalyticsHomeData;
    dispatch: React.Dispatch<Action>;
  };
  ciTradeAndVolume: {
    state: ICiTradeAndVolumeData;
    dispatch: React.Dispatch<Action>;
  };

  analyticsSnapshotReport: {
    state: IAnalyticsSnapshotReportType;
    dispatch: React.Dispatch<Action>;
  };

  pptReports: {
    state: IPptData;
    dispatch: React.Dispatch<Action>;
  };

  reconReports: {
    state: IReconData;
    dispatch: React.Dispatch<Action>;
  };
}>({
  appData: {
    state: EditFeatureInitialState,
    dispatch: () => null,
  },
  ppaInsight: {
    state: PpaInsightInitialState,
    dispatch: () => null,
  },
  biaReports: {
    state: BiaReportsInitialState,
    dispatch: () => null,
  },
  analyticsHome: {
    state: AnalyticsHomeInitialState,
    dispatch: () => null,
  },
  ciTradeAndVolume: {
    state: CiTradeAndVolumeInitialState,
    dispatch: () => null,
  },

  analyticsSnapshotReport: {
    state: AnalyticsSnapshotReportInitialState,
    dispatch: () => null,
  },
  pptReports: {
    state: PptReportsInitialState,
    dispatch: () => null,
  },
  reconReports: {
    state: ReconReportsInitialState,
    dispatch: () => null,
  },
});