/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect } from 'react';
import LoginPage from '../LoginPage/LoginPage';
import Dashboard from '../../components/Dashboard/Dashboard';
import { AppContext } from '../../Context/AppContext';
import { UnAuthUserPage } from '../UnAuthUserPage/UnAuthUserPage';
import { getUser, login, logout } from './AppContentFunctions';
import { ONE, UNAUTHORISED } from '../../api/constants';
import { SelectRolePage } from '../SelectRolePage/SelectRolePage';

const AppContent: React.FC = () => {
  const { state, dispatch } = useContext(AppContext).appData;
  const isProdEnv = process.env.REACT_APP_ENVIRONMENT === 'prod';

  useEffect(() => {
    getUser(dispatch, isProdEnv);
  }, []);
  const { role } = state;
  const singleRole = role.length === ONE ? role[0] : null;
  switch (true) {
    case singleRole === UNAUTHORISED:
      return <UnAuthUserPage />;
    case singleRole === '':
      return <LoginPage login={login} />;
    case role.length > ONE:
      return <SelectRolePage roles={role} />;
    default:
      return <Dashboard logout={logout} />;
  }
};

export default AppContent;
