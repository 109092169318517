import { Flexbox, Heading } from '@sede-x/shell-ds-react-framework';
import React from 'react';

export const NoTableData = () => {
  return (
    <Flexbox justifyContent="center">
      <Heading type="h1">
        <strong>Please use the filters to show data here.</strong>
      </Heading>
    </Flexbox>
  );
};
