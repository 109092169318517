import {
  I_ALIGNE_TABLE,
  ENDUR_I_GAS_TABLE,
  I_GROUP_TABLE,
  ENDUR_SEAM_REGION_SCOPE_TABLE,
  NUCLEUS_SEAM_REGION_SCOPE_TABLE,
  MARKET,
  BUYSELL,
  NCF_CATEGORY,
  QTY,
  DEAL_QUANTITY,
  COMMENTS,
  SCOPE,
  COUNTRY_CODE,
  COUNTRY_NAME,
  OUT_OF_SCOPE,
  IN_SCOPE,
  BUY_SLASH_SELL,
  NUCLEUS_I_POWER_TABLE,
  ENDURSLMT_I_POWER_TABLE,
  ENDURSLMT_STATIC_MAP_NCF_TABLE,
  USA_CODE,
  BRAZIL,
  BRAZIL_CODE,
  CANADA,
  CANADA_CODE,
  MEXICO,
  MEXICO_CODE,
  USA,
  BUY,
  SELL,
  ENDURAU_I_GAS,
  ENDURAU_STATIC_MAP_NCF,
  VOLUME,
  NCF_SCOPE,
} from './constants';

type Editable = {
  editableFields: string[];
};
type ValueLabelPair = {
  value: string;
  label: string;
}[];
type Country = {
  countryName: string;
  countryCode: string;
}[];
type ArrayType = ValueLabelPair | Country;
interface TableEditOptions {
  buySellOpitons: { value: string; label: string }[];
  scopeOptions: { value: string; label: string }[];
  [key: string]: Editable | ArrayType | Record<string, unknown>;
  countryNameAndCodeAmericas: { countryName: string; countryCode: string }[];
  countryOptionsAmericas: { value: string; label: string }[];
  ruleConfigNewRecordObject: {
    RULE_CONFIG_ID: number;
    TABLE_NAME: string;
    COUNTERPARTY_SHORT_NAME: string;
    TRADE_TYPE: string;
    MARKET: string;
    STORAGE: string;
    START_DATE: string;
    END_DATE: string;
    CHANGE_ATTRIBUTE: string;
    CHANGE_FROM: string;
    CHANGE_TO: string;
    CREATED_BY: string;
    UPDATED_BY: string;
    INSERTED_DATE: string;
    LAST_MODIFIED_DATE: string;
    STATUS: string;
  };
  configRuleChangeAttributeOptions: { value: string; label: string }[];
}

const tableEditOptions: TableEditOptions = {
  buySellOpitons: [
    { value: BUY, label: BUY },
    { value: SELL, label: SELL },
  ],
  scopeOptions: [
    { value: OUT_OF_SCOPE, label: OUT_OF_SCOPE },
    { value: IN_SCOPE, label: IN_SCOPE },
  ],
  [I_ALIGNE_TABLE]: {
    editableFields: [MARKET, BUYSELL, NCF_CATEGORY, QTY, COMMENTS],
  },
  [I_GROUP_TABLE]: {
    editableFields: [MARKET, NCF_CATEGORY, QTY, COMMENTS],
  },
  [ENDUR_I_GAS_TABLE]: {
    editableFields: [NCF_CATEGORY, QTY, COMMENTS],
  },
  [NUCLEUS_I_POWER_TABLE]: {
    editableFields: [NCF_CATEGORY, QTY, COMMENTS],
  },
  [ENDUR_SEAM_REGION_SCOPE_TABLE]: {
    editableFields: [COUNTRY_CODE, COUNTRY_NAME, SCOPE, COMMENTS],
  },
  [NUCLEUS_SEAM_REGION_SCOPE_TABLE]: {
    editableFields: [COUNTRY_CODE, COUNTRY_NAME, SCOPE, COMMENTS],
  },
  [ENDURSLMT_I_POWER_TABLE]: {
    editableFields: [DEAL_QUANTITY, COMMENTS],
  },
  [ENDURSLMT_STATIC_MAP_NCF_TABLE]: {
    editableFields: [NCF_CATEGORY, COMMENTS],
  },
  [ENDURAU_I_GAS]: {
    editableFields: [VOLUME, COMMENTS],
  },
  [ENDURAU_STATIC_MAP_NCF]: {
    editableFields: [NCF_CATEGORY, NCF_SCOPE, COMMENTS],
  },
  countryNameAndCodeAmericas: [
    { countryName: '', countryCode: '' },
    { countryName: BRAZIL, countryCode: BRAZIL_CODE },
    { countryName: CANADA, countryCode: CANADA_CODE },
    { countryName: MEXICO, countryCode: MEXICO_CODE },
    { countryName: USA, countryCode: USA_CODE },
  ],
  countryOptionsAmericas: [
    { value: '', label: '' },
    { value: BRAZIL, label: BRAZIL },
    { value: CANADA, label: CANADA },
    { value: MEXICO, label: MEXICO },
    { value: USA, label: USA },
  ],
  ruleConfigNewRecordObject: {
    RULE_CONFIG_ID: -1,
    TABLE_NAME: I_ALIGNE_TABLE,
    COUNTERPARTY_SHORT_NAME: '',
    TRADE_TYPE: '',
    MARKET: '',
    STORAGE: '',
    START_DATE: '',
    END_DATE: '',
    CHANGE_ATTRIBUTE: '',
    CHANGE_FROM: '',
    CHANGE_TO: '',
    CREATED_BY: '',
    UPDATED_BY: '',
    INSERTED_DATE: '',
    LAST_MODIFIED_DATE: '',
    STATUS: '',
  },
  configRuleChangeAttributeOptions: [
    { value: BUY_SLASH_SELL, label: BUY_SLASH_SELL },
    { value: MARKET, label: MARKET },
  ],
};

export default tableEditOptions;
