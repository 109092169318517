// Dashboard.tsx
import React, { useContext } from 'react';
import HeaderBar from '../header/HeaderBar';
import { Grid } from '@sede-x/shell-ds-react-framework';
import ViewEditReportPage from '../pages/ViewEditReportPage/ViewEditReportPage';
import { ViewAuditReportPage } from '../pages/ViewAuditReportPage/ViewAuditReportPage';
import { ConfigureRulesPage } from '../pages/ConfigureRulesPage/ConfigureRulesPage';
import { DataSnapshotPage } from '../pages/publishReportPages/DataSnapshotPage';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import '../style.module.css';
import { SectoralPerformance } from '../pages/SectoralPerformance/SectoralPerformance';
import WithMargin from './WithMargin';
import { AnalyticsHome } from '../pages/NcfReportingPage/AnalyticsHome/AnalyticsHome';
import { CiTrades } from '../pages/NcfReportingPage/CiTrades/CiTrades';
import { TopCounterParties } from '../pages/NcfReportingPage/TopCounterParties/TopCounterParties';
import { PpaInsights } from '../pages/PPAInsights/PpaInsights';
import { AppContext } from '../../Context/AppContext';
import { BiaReportPage } from '../pages/NcfReportingPage/BiaReportPage/BiaReportPage';
import { RegionVolumeDetails } from '../pages/NcfReportingPage/AnalyticsHome/RegionVolumeDetails/RegionVolumeDetails';
import { RegionVolumeDetails as SnapshotRegionVolumeDetails } from '../pages/NcfReportingPage/SnapshotReport/RegionVolumeDetails/RegionVolumeDetails';
import { SnapshotReport } from '../pages/NcfReportingPage/SnapshotReport/SnapshotReport';
import { PptReportingHome } from '../pages/NcfReportingPage/PptReporting/PptReportingHome';
import { DataReconciliationHome } from '../pages/NcfReportingPage/DataReconciliation/DataReconciliationHome';

const Dashboard = ({ logout }: { logout: () => void }) => {
  const { state } = useContext(AppContext).appData;
  return (
    <div data-testid="dashboard-page">
      <Grid gap="0px" areas={['header', 'main']} className="grid">
        <HashRouter basename="/">
          <Grid.Cell area="header">
            <HeaderBar logout={logout} />
          </Grid.Cell>
          <Grid.Cell area="main" className="mainGrid">
            {state.isPpaUser ? (
              <Routes>
                <Route path="/ppa-insights" element={<PpaInsights />} />
                <Route path="/*" element={<Navigate to="/ppa-insights" />} />
              </Routes>
            ) : (
              <Routes>
                {state.showSectoralpage && (
                  <Route
                    path="/sectoral"
                    element={
                      <WithMargin>
                        <SectoralPerformance />
                      </WithMargin>
                    }
                  />
                )}
                <Route
                  path="/viewEditReport"
                  element={
                    <WithMargin>
                      <ViewEditReportPage />
                    </WithMargin>
                  }
                />
                <Route
                  path="/viewauditreport"
                  element={
                    <WithMargin>
                      <ViewAuditReportPage />
                    </WithMargin>
                  }
                />
                {state.showConfigRulePage && (
                  <Route
                    path="/configurerules"
                    element={
                      <WithMargin>
                        <ConfigureRulesPage />
                      </WithMargin>
                    }
                  />
                )}
                <Route
                  path="/publishreport"
                  element={
                    <WithMargin>
                      <DataSnapshotPage />
                    </WithMargin>
                  }
                />
                <Route path="/ncf-reporting" element={<AnalyticsHome />} />
                <Route
                  path="/region-volume-details"
                  element={<RegionVolumeDetails />}
                />
                <Route
                  path="/top-counterparties"
                  element={<TopCounterParties />}
                />
                <Route path="/ppa-insights" element={<PpaInsights />} />
                <Route path="/bia-report" element={<BiaReportPage />} />
                <Route path="/*" element={<Navigate to="/viewEditReport" />} />
                <Route path="/ci-trades" element={<CiTrades />} />
                <Route path="/snapshot-report" element={<SnapshotReport />} />
                <Route
                  path="/snapshot-region-volume-details"
                  element={<SnapshotRegionVolumeDetails />}
                />
                <Route path="/ppt-reporting" element={<PptReportingHome />} />
                <Route path="/data-reconciliation" element={<DataReconciliationHome />} />
                
              </Routes>
            )}
          </Grid.Cell>
        </HashRouter>
      </Grid>
    </div>
  );
};
export default Dashboard;
