import { IEditFeatureData } from '../../types/types';

export const EditFeatureInitialState: IEditFeatureData = {
  allFieldsValidated: true,
  tableData: [],
  masterTableData: [],
  auditTableData: [],
  spinner: false,
  currentPage: '',
  tableSelected: '',
  filteredOptions: {},
  marketOptions: {},
  ncfCategoryOptions: {},
  buySellOptions: [],
  filteredRegion: '',
  role: [''],
  isRoleNcf: false,
  userRegions: [],
  username: '',
  mail: '',
  recordCount: 0,
  auditPaginationData: [],
  paginationData: [],
  masterPaginationData: [],
  currentPaginationNum: 1,
  hasTableChangesPublished: false,
  dirtyData: [],
  erroredRows: [],
  publishRows: [],
  masterErroredRows: [],
  editableCol: [],
  defaultPageSize: 5,
  isGlobalUser: false,
  showConfigRulePage: false,
  canUserManipulateData: false,
  canUserAddAndSubmitSnapshot: false,
  canUserChangeSnapshotStatus: false,
  showAllRegions: false,
  showSnapshotActions: false,
  isAustraliaUser: false,
  isPpaUser: false,
  isLoggedLogin: false,
  showSectoralpage: false,
  isAnyInvalidComment: false,
  isAnyInvalidCountryName: false,
  isAnyInvalidQuantity: false,
  showReport: false,
  showAuditReport: false,
  isGetData: false,
};

export type IEditFeatureDataState = typeof EditFeatureInitialState;
