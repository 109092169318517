import {
  Flexbox,
  Grid,
  Heading,
  Pecten,
} from '@sede-x/shell-ds-react-framework';
import React, { useContext, useEffect } from 'react';
import {
  LoginContainer,
  BackgroundVideo,
  LoginGrid,
  Login,
  GridContainer,
} from '../AppContent/AppContent.styles';
import {
  CLICK_HERE,
  REQUEST_ACCESS_MESSAGE,
  SOM_MAIL_ID,
} from '../../api/constants';
import { AppContext } from '../../Context/AppContext';
import appInsights from './../../appInsights';

export const UnAuthUserPage = () => {
  const { state } = useContext(AppContext).appData;

  useEffect(() => {
    appInsights.trackEvent({
      name: "User's unsuccessful log in",
      properties: {
        email: state?.mail,
        username: state?.username,
        role: state?.role,
      },
    });
  }, []);

  return (
    <Flexbox justifyContent="center" data-testid="unauthPage">
      <LoginContainer data-testid="login-page">
        <BackgroundVideo autoPlay loop muted>
          <source src="/pulsating-pecten.mp4" type="video/mp4" />
        </BackgroundVideo>
        <LoginGrid justifyContent="center" alignItems="center">
          <Login>
            <Grid gap="24px" areas={['blank1', 'login', 'blank2']}>
              <Grid.Cell area="blank1" />
              <Grid.Cell area="login">
                <Flexbox justifyContent="center">
                  <GridContainer>
                    <Grid
                      gap="24px"
                      areas={[
                        'spinner',
                        'pecten',
                        'signonText',
                        'authText',
                        'button',
                      ]}
                    >
                      <Grid.Cell area="pecten">
                        <Flexbox justifyContent="center">
                          <Pecten size="large" />
                        </Flexbox>
                      </Grid.Cell>
                      <Grid.Cell area="signonText">
                        <Flexbox justifyContent="center">
                          <Heading type="h2">
                            <strong>
                              Oops!!! Looks like you do not have access to this
                              application.
                            </strong>
                          </Heading>
                        </Flexbox>
                        <Flexbox justifyContent="center">
                          <Heading type="h2">
                            <strong>
                              Kindly{' '}
                              <a href="https://shell2.service-now.com/sp?id=sc_cat_item_guide&table=sc_cat_item&sys_id=69dd3e8bdbaea49068ea29da4b9619dc">
                                {' '}
                                {CLICK_HERE}
                              </a>{' '}
                              {REQUEST_ACCESS_MESSAGE}
                            </strong>
                          </Heading>
                        </Flexbox>
                        <br />
                        <Flexbox justifyContent="center">
                          <Heading type="h2">
                            Please email{' '}
                            <a key={SOM_MAIL_ID} href={`mailto:${SOM_MAIL_ID}`}>
                              SOM Team
                            </a>{' '}
                            for any further help.
                          </Heading>
                        </Flexbox>
                      </Grid.Cell>
                    </Grid>
                  </GridContainer>
                </Flexbox>
              </Grid.Cell>
              <Grid.Cell area="blank2" />
            </Grid>
          </Login>
        </LoginGrid>
      </LoginContainer>
    </Flexbox>
  );
};
