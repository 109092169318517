import React from 'react';
import {
  ToggleListContainer,
  ToggleList1,
  ToggleButtonParent,
  ToggleButton1,
  Inner,
  Label2,
  Note
} from './DeltaTableFilters.style';

interface DeltaTableFiltersProps {
  activeFilter: string | null;
  onFilterClick: (buttonName: string) => void;
}

const DeltaTableFilters: React.FC<DeltaTableFiltersProps> = ({ activeFilter, onFilterClick }) => {
  const activeStyle = {
    backgroundColor: 'rgba(0, 151, 168, 0.1)',
    border: '2px solid rgba(0, 131, 148, 0.78)',
  };

  return (
    <ToggleListContainer>
      <ToggleList1>
        <ToggleButtonParent>
          <ToggleButton1
            onClick={() => onFilterClick('DELTA_DETAILS')}
            style={activeFilter === 'DELTA_DETAILS' ? activeStyle : {}}
          >
            <Inner>
              <Label2>Delta Details</Label2>
            </Inner>
          </ToggleButton1>
          <ToggleButton1
            onClick={() => onFilterClick('MISSING_IN_TANSO')}
            style={activeFilter === 'MISSING_IN_TANSO' ? activeStyle : {}}
          >
            <Inner>
              <Label2>Missing in Tanso</Label2>
            </Inner>
          </ToggleButton1>
          <ToggleButton1
            onClick={() => onFilterClick('MISSING_IN_ETRM')}
            style={activeFilter === 'MISSING_IN_ETRM' ? activeStyle : {}}
          >
            <Inner>
              <Label2>Missing in ETRM</Label2>
            </Inner>
          </ToggleButton1>
          <ToggleButton1
            onClick={() => onFilterClick('VALUE_MISMATCH')}
            style={activeFilter === 'VALUE_MISMATCH' ? activeStyle : {}}
          >
            <Inner>
              <Label2>Available in both but value mismatch</Label2>
            </Inner>
          </ToggleButton1>
        </ToggleButtonParent>
        <ToggleButton1
          onClick={() => onFilterClick('VALUE_MISMATCH')}
          style={activeFilter === 'VALUE_MISMATCH' ? activeStyle : {}}
        >
          <Inner>
            <Label2>NCF Category Mismatch</Label2>
          </Inner>
        </ToggleButton1>
      </ToggleList1>
      <Note>
        *Delta Details = (Missing in Tanso) + (Missing in ETRM) + (Available in both but value mismatch)
      </Note>
    </ToggleListContainer>
  );
};

export default DeltaTableFilters;