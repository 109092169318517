import React, { useEffect, useContext } from 'react';
import {
  ChartContainer,
  TableHeading,
  ScrollableDiv,
} from './RegionVolumeDetailsTable.style';
import { regionVolumeDetailsTableData } from '../../../../../../api/services';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { RegionVolumeDetailsTableData } from '../../../../../../types/types';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../../Loader/Loader';

const RegionVolumeDetailsTable: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const region = queryParams.get('region');
  const parameterName = queryParams.get('parameterName');
  const { state: analyticsState, dispatch: analyticsDispatch } = useContext(
    AppContext
  ).analyticsHome;

  const isLoading = analyticsState.isLoading;

  const mapData: RegionVolumeDetailsTableData[] =
    analyticsState.regionVolumeDetailsTableData;
  const sum = mapData?.reduce((acc, item) => acc + item.QTY, 0)?.toFixed(2);
  const tableFormattedData = [...mapData].map(el => {
    return {
      ...el,
      QTY: Math.round(Number(el.QTY) * 100) / 100,
    };
  });

  const columnNames = [
    'YEAR',
    'QUARTER',
    'MONTH',
    'COMMODITY',
    'TRANSACTION_TYPE',
    'ORIGINAL_TRANSACTION_TYPE',
    'NCF_CATEGORY',
    'CPTY',
    'CPTY_DESC',
    'QTY',
    'NCF_SCOPE',
  ];
  const columns = columnNames.map(key => ({
    header: key.replace(/_/g, ' '),
    accessorKey: key,
  }));

  useEffect(() => {
    const fetchData = async () => {
      analyticsDispatch({
        type: 'setIsLoading',
        payload: true,
      });
      let response;
      try {
        response = await regionVolumeDetailsTableData({
          ...analyticsState.filteredValues,
          region: [region],
          parameterName,
        });
        // Use the analyticsDispatch function to call setNcfQuarterwise
        analyticsDispatch({
          type: 'setRegionVolumeDetailsTableData',
          payload: response.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
        analyticsDispatch({
          type: 'setIsError',
          payload: {
            isError: true,
            message: 'Failed to fetch data. Please try again.',
          },
        });
        analyticsDispatch({
          type: 'setIsLoading',
          payload: false,
        });
      }
    };

    fetchData();
  }, [analyticsDispatch, analyticsState.filteredValues, region, parameterName]); // Add analyticsDispatch to the dependency array

  if (isLoading) {
    return <Loader loader={true} message={''} />;
  }
  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent="space-between">
        <TableHeading>Detail View of {parameterName}</TableHeading>
      </Flexbox>
      <ScrollableDiv>
        <BaseTable
          columns={columns}
          data={tableFormattedData}
          size="large"
          data-testid="base-table"
          style={{ width: '100%' }}
        />
        Total {sum}
      </ScrollableDiv>
    </ChartContainer>
  );
};

export default RegionVolumeDetailsTable;
