import React from 'react';
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import { TopCtpyFilterSection } from './TopCtpyFilterSection';
import DateSideCard from '../AnalyticsHome/DateSideCard/DateSideCard';
import TopCtpyHeader from './TopCtpyHeader/TopCtpyHeader';
import TopCtpyBarChart from './TopCtpyCharts/TopCtpyBarChart/TopCtpyBarChart';
import TopCtpyTable from './TopCtpyCharts/TopCtpyTable/TopCtpyTable';

export const TopCounterParties = () => {
  return (
    <div
      style={{ height: '100%', background: '#F5F5F5' }}
      data-testid="top-counter-parties"
    >
      <Flexbox
        gap="24px 24px"
        style={{ marginLeft: '15px', justifyContent: 'start' }}
      >
        <TopCtpyFilterSection />
        <Grid gap="10px">
          <Grid.Cell>
            <Flexbox justifyContent="space-between">
              <TopCtpyHeader />
              <DateSideCard />
            </Flexbox>
          </Grid.Cell>
          <Divider size="medium" direction="horizontal" />
          <Grid.Cell>
            <TopCtpyBarChart />
          </Grid.Cell>
          <Grid.Cell>
            <TopCtpyTable />
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
