import {
  Button,
  Flexbox,
  Grid,
  Link,
  Modal,
  Pagination,
  RadioButton,
  DeprecatedTable,
  ButtonLink,
} from '@sede-x/shell-ds-react-framework';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../Context/AppContext';
import { useNavigate } from 'react-router-dom';
import tableStyles from '../../style.module.css';
import './tableHeadingStyle.css';
import {
  NCF_SNAPSHOT_ID,
  SNAPSHOT_STATUS,
  APPROVED,
  RE_SUBMITTED_FOR_APPROVAL,
  APPROVED_UI,
  THOUSAND_FIVE_HUNDRED,
  DATA_SNAPSHOT_PAGE_DESCRIPTION,
  REJECTED,
  REJECTED_UI,
} from '../../../api/constants';
import { AddNewSnapshotModal } from './AddNewSnapshotModal';
import { fetchSnapshotData, updateSnapshotStatus } from '../../../api/services';
import { InternalServerErrorPage } from '../InternalServerErrorPage/InternalServerErrorPage';
import { decorateColName, hideSpinner, showSpinner } from '../../Utils/utils';
import RadioButtonSelected from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components/RadioButtonSelected';
import {
  getUserRegionShortNameForSnapshot,
  modifySnapshotStatusforUI,
} from './SnapshotFunctions';
import { IFetchSnapshotDataOption } from '../../../types/types';

const Container = styled.div`
  width: auto;
  height: auto;
`;

export const DataSnapshotPage = () => {
  const { state, dispatch } = useContext(AppContext).appData;

  const [checkedID, setCheckedID] = useState(-1);
  const [isAddSnapshot, setIsAddSnapshot] = useState(false);
  const [publishPaginationData, setPublishPaginationData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isSnapshotUpdated, setIsSnapshotUpdated] = useState(false);
  const [isServiceError, setIsServiceError] = useState(false);
  const [cols, setCols] = useState<string[]>([]);
  const [currentPaginationNumber, setCurrentPaginationNumber] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: 'setCurrentPage',
      payload: 'Creating an NCF Data Snapshot',
    });
    updateSnapshotData(1);
  }, []);

  const userRegionShortName = getUserRegionShortNameForSnapshot(state);

  const resetTable = () => {
    setPublishPaginationData([]);
    setTotalCount(0);
    setCheckedID(-1);
  };

  const updateSnapshotData = async (pageNumber: number) => {
    setCurrentPaginationNumber(pageNumber);
    showSpinner(dispatch);
    const tableOptions: IFetchSnapshotDataOption = {
      pageNumber,
      pageSize: 5,
    };
    if (userRegionShortName) {
      tableOptions.region = userRegionShortName;
    }
    try {
      const snapshotDataResp = await fetchSnapshotData(tableOptions);
      resetTable();
      const snapshotData = modifySnapshotStatusforUI(
        snapshotDataResp.data.data
      );
      const count = snapshotDataResp.data.count;
      setPublishPaginationData(snapshotData);
      if (snapshotData.length > 0) {
        setCols(Object.keys(snapshotData[0]));
      }
      setTotalCount(count);
      hideSpinner(dispatch);
      setIsServiceError(false);
    } catch (e) {
      hideSpinner(dispatch);
      setIsServiceError(true);
    }
    hideSpinner(dispatch);
  };

  const handleStatusChange = async (snapshotStatus: string) => {
    showSpinner(dispatch);
    const options = {
      ncfSnapshotIds: [checkedID],
      snapshotStatus,
    };
    try {
      const updateResponse = await updateSnapshotStatus(options);
      if (updateResponse.data.message === 'Snapshots updated.') {
        setIsSnapshotUpdated(true);
        setTimeout(() => {
          setIsSnapshotUpdated(false);
        }, THOUSAND_FIVE_HUNDRED);
      } else {
        setIsSnapshotUpdated(false);
      }
    } catch (e) {
      setIsServiceError(true);
    }
    await updateSnapshotData(currentPaginationNumber);
    hideSpinner(dispatch);
  };

  const publishTableCols = () => {
    const colObj: any = [];

    cols.forEach((val: any) => {
      if (colObj.length === 0) {
        colObj.push({
          key: val,
          width: 60,
          className: tableStyles.snapshotTableCols,
          title: (
            <div>
              <RadioButtonSelected width={20} />
            </div>
          ),
          render: (value: any): ReactElement => (
            <RadioButton
              checked={checkedID === value[NCF_SNAPSHOT_ID]}
              onChange={(e: any) => setCheckedID(Number(e.target.value))}
              value={value[NCF_SNAPSHOT_ID]}
              disabled={[APPROVED_UI, REJECTED_UI].includes(
                value[SNAPSHOT_STATUS]
              )}
            />
          ),
        });
      }
      colObj.push({
        dataIndex: val,
        key: val,
        title: <div>{decorateColName(val)}</div>,
        className: tableStyles.snapshotTableCols,
      });
    });
    return colObj;
  };

  return (
    <div data-testid="publishpageid">
      <Grid
        gap="24px"
        areas={['actions actions actions actions', 'table table table table']}
      >
        <Grid.Cell area="actions">
          <Grid gap="20px">
            <Grid.Cell>{DATA_SNAPSHOT_PAGE_DESCRIPTION}</Grid.Cell>
            <Grid.Cell>
              Should changes be required to the data in the Snapshot, then the{' '}
              <Link>
                <ButtonLink onClick={() => navigate('/viewEditReport')}>
                  Data Edit
                </ButtonLink>
              </Link>{' '}
              feature in Tanso can be used to amend data before creating the
              Snapshot.
            </Grid.Cell>
          </Grid>
          {!isServiceError && state.showSnapshotActions && (
            <Flexbox justifyContent="flex-end">
              {state.canUserAddAndSubmitSnapshot && (
                <Button
                  style={styles.publishBtn}
                  onClick={() => setIsAddSnapshot(true)}
                >
                  Create Snapshot
                </Button>
              )}
              {state.canUserChangeSnapshotStatus && (
                <Button
                  data-testid="resubmit"
                  style={styles.publishBtn}
                  onClick={() => handleStatusChange(RE_SUBMITTED_FOR_APPROVAL)}
                  disabled={checkedID < 0}
                >
                  Re-submit Snapshot
                </Button>
              )}
              {state.canUserChangeSnapshotStatus && (
                <Button
                  style={styles.publishBtn}
                  onClick={() => handleStatusChange(APPROVED)}
                  disabled={checkedID < 0}
                >
                  Confirm Snapshot Data
                </Button>
              )}
              {state.canUserChangeSnapshotStatus && (
                <Button
                  data-testid="resubmit"
                  style={styles.publishBtn}
                  onClick={() => handleStatusChange(REJECTED)}
                  disabled={checkedID < 0}
                >
                  Reject Snapshot
                </Button>
              )}
            </Flexbox>
          )}
        </Grid.Cell>
        <Grid.Cell area="table">
          <div>
            <Flexbox gap="24px 24px" justifyContent="center">
              <Grid rowGap="24px">
                <AddNewSnapshotModal
                  isAddSnapshot={isAddSnapshot}
                  setIsAddSnapshot={setIsAddSnapshot}
                  updateSnapshotData={updateSnapshotData}
                />
                <Container>
                  {state.spinner && (
                    <Modal
                      closable={false}
                      loading={true}
                      title={
                        isSnapshotUpdated
                          ? 'Snapshot(s) status updated successfully.'
                          : ''
                      }
                      bodyPadding={true}
                      showFooter={false}
                      open={state.spinner}
                      onClose={() => {
                        //console statement
                      }}
                    ></Modal>
                  )}

                  {isServiceError && <InternalServerErrorPage />}
                  {!isServiceError && (
                    <DeprecatedTable
                      size="small"
                      data={publishPaginationData}
                      rowKey={(record: { [x: string]: any }) =>
                        record[NCF_SNAPSHOT_ID]
                      }
                      columns={publishTableCols()}
                      tableLayout="fixed"
                      wrapperStyle={{ border: '2px solid rgba(0,0,0,0.15)' }}
                      rowClassName={(record: { [x: string]: string }) =>
                        [APPROVED_UI, REJECTED_UI].includes(
                          record[SNAPSHOT_STATUS]
                        )
                          ? tableStyles.confirmSnapshotApprovedRow
                          : tableStyles.confirmSnapshotRow
                      }
                    />
                  )}
                </Container>
                {publishPaginationData.length > 0 && (
                  <Pagination
                    total={totalCount}
                    pageSize={5}
                    onChange={(currentPage: number) =>
                      updateSnapshotData(currentPage)
                    }
                  />
                )}
              </Grid>
            </Flexbox>
          </div>
        </Grid.Cell>
      </Grid>
    </div>
  );
};

const styles = {
  firstContent: {
    marginRight: 'auto',
    marginTop: '12px',
  },
  publishBtn: {
    margin: '5px',
  },
  wip: {
    width: 200,
    borderRadius: 80,
  },
};
