/* eslint-disable no-undef */
import axios from 'axios';
import { AuthService } from '../authPages/AuthService/AuthService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 100000,
});
const sessionId = `oidc.user:${process.env.REACT_APP_SSO_AUTHORITY_URL}:${process.env.REACT_APP_SSO_CLIENT_ID}`;
const loggedInUser = sessionStorage.getItem(sessionId);
let loggedInUserDetails = loggedInUser && JSON.parse(loggedInUser);
const authService = new AuthService();

const refreshToken = async () => {
  try {
    const isTokenExpired = true;
    return await authService.login(isTokenExpired);
  } catch (error) {
    // If there is any error while refreshing the token, logging out the user.
    return authService.logout();
  }
};

instance.interceptors.request.use(
  async config => {
    const expireTimestamp = loggedInUserDetails?.expires_at;
    const milliseconds = 1000;
    const currentTimestamp = new Date().getTime() / milliseconds;
    // checking token expire and getting new token using silent login.
    if (expireTimestamp && expireTimestamp <= currentTimestamp) {
      loggedInUserDetails = await refreshToken();
    }
    const accessToken = loggedInUserDetails?.access_token;
    if (accessToken && config.headers) {
      (config.headers as { [key: string]: string })[
        'Authorization'
      ] = `Bearer ${accessToken}`;
    }
    return config;
  },
  async () => {
    // If there is any error while refresh token, logging out the user.
    await authService.logout();
  }
);

export default instance;
