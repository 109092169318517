import {
  Action,
  Country,
  IBiaReports,
  IBiaUncatCptyAsiaAustraliaData,
  IBiaUncatCptyEuropeAmericaData,
  IOptions,
} from '../../../../types/types';
import {
  filterData,
  getBiaGroupAffData,
  getBiaInactiveCptyData,
  getBiaUncatCptyData,
  getBiaValidationCheckData,
  lastRefesh,
} from '../../../../api/services';
import { Dispatch, SetStateAction } from 'react';

// Function to check if the value is empty (empty string or empty array)
const isEmpty = (value: string | number | undefined | string[]) =>
  value === '' || (Array.isArray(value) && value.length === 0);
// New function to filter out empty attributes from options
const filterEmptyAttributes = (options: IOptions) => {
  return Object.entries(options).reduce((acc, [key, value]) => {
    if (!isEmpty(value)) {
      acc[key as keyof IOptions] = value;
    }
    return acc;
  }, {} as IOptions);
};

export const fetchBiaValidationCheckData = async (
  state: IBiaReports,
  dispatch: Dispatch<Action>
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    region: state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountry,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  dispatch({
    type: 'setIsValidationDataLoading',
    payload: true,
  });
  const filteredOptions = filterEmptyAttributes(options);
  try {
    const response = await getBiaValidationCheckData(filteredOptions);
    if (response.data.length > 0) {
      dispatch({
        type: 'setBiaValidationCheckData',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'setBiaValidationCheckData',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'setBiaValidationCheckData',
      payload: [],
    });
    setUpServerErrorView(dispatch, error);
  }
  dispatch({
    type: 'setIsValidationDataLoading',
    payload: false,
  });
};

export const setUpServerErrorView = (
  dispatch: Dispatch<Action>,
  error: { message: string }
) => {
  dispatch({
    type: 'setIsNcfServerError',
    payload: true,
  });
  dispatch({
    type: 'setNcfServerErrorMessage',
    payload: error.message,
  });
};

export const fetchBiaGroupAffData = async (
  state: IBiaReports,
  dispatch: Dispatch<Action>
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    region: state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountry,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  const filteredOptions = filterEmptyAttributes(options);
  try {
    dispatch({
      type: 'setIsGroupAffDataLoading',
      payload: true,
    });
    const response = await getBiaGroupAffData(filteredOptions);
    if (response.data.length > 0) {
      dispatch({
        type: 'setBiaGroupAffData',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'setBiaGroupAffData',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'setBiaGroupAffData',
      payload: [],
    });
    setUpServerErrorView(dispatch, error);
  }
  dispatch({
    type: 'setIsGroupAffDataLoading',
    payload: false,
  });
};

export const fetchBiaInactiveCptyData = async (
  state: IBiaReports,
  dispatch: Dispatch<Action>
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    region: state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountry,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  const filteredOptions = filterEmptyAttributes(options);
  try {
    dispatch({
      type: 'setIsInactiveCptyDataLoading',
      payload: true,
    });
    const response = await getBiaInactiveCptyData(filteredOptions);
    if (response.data.length > 0) {
      dispatch({
        type: 'setBiaInactiveCptyData',
        payload: response.data,
      });
    } else {
      dispatch({
        type: 'setBiaInactiveCptyData',
        payload: [],
      });
    }
  } catch (error) {
    dispatch({
      type: 'setBiaInactiveCptyData',
      payload: [],
    });
    setUpServerErrorView(dispatch, error);
  }
  dispatch({
    type: 'setIsInactiveCptyDataLoading',
    payload: false,
  });
};

export const fetchUncatCptyData = async (
  isBiaRegionEuropeAmericas: boolean,
  state: IBiaReports,
  dispatch: Dispatch<Action>
) => {
  const options = {
    yearFilter: state.biaFilterData.biaYear,
    region: state.biaFilterData.biaRegion,
    country: state.biaFilterData.biaCountry,
    commodity: state.biaFilterData.biaCommodity,
    unit: state.biaFilterData.biaUnitName,
  };
  const filteredOptions = filterEmptyAttributes(options);
  const dispatchFunction = isBiaRegionEuropeAmericas
    ? dispatchEuropeAmericaData
    : dispatchAsiaAustraliaData;
  try {
    dispatch({
      type: 'setIsUncatCptyDataLoading',
      payload: true,
    });
    const response = await getBiaUncatCptyData(filteredOptions);
    if (response.data.length > 0) {
      dispatchFunction(response.data, dispatch);
    } else {
      dispatchFunction([], dispatch);
    }
  } catch (error) {
    dispatchFunction([], dispatch);
    setUpServerErrorView(dispatch, error);
  }
  dispatch({
    type: 'setIsUncatCptyDataLoading',
    payload: false,
  });
};

const dispatchEuropeAmericaData = (
  data: IBiaUncatCptyEuropeAmericaData | IBiaUncatCptyAsiaAustraliaData | [],
  dispatch: Dispatch<Action>
) => {
  dispatch({
    type: 'setBiaUncatCptyEuropeAmericaData',
    payload: data,
  });
};

const dispatchAsiaAustraliaData = (
  data: IBiaUncatCptyEuropeAmericaData | IBiaUncatCptyAsiaAustraliaData | [],
  dispatch: Dispatch<Action>
) => {
  dispatch({
    type: 'setBiaUncatCptyAsiaAustraliaData',
    payload: data,
  });
};

export const fetchFilterInfo = async (
  dispatch: Dispatch<Action>,
  setYearSelected: Dispatch<SetStateAction<string[]>>,
  handleRegionChange: (
    e: string,
    regionCountryMapping?: Record<string, Country[]>
  ) => void
) => {
  dispatch({
    type: 'setIsBiaFilterOptionsLoading',
    payload: true,
  });
  try {
    const response = await filterData();
    dispatch({
      type: 'setBiaFilterOptions',
      payload: response.data,
    });
    // Set default values after data has been fetched
    setYearSelected(response.data.year[0].toString());
    handleRegionChange(
      response.data.region[0],
      response.data.regionCountryMapping
    );
  } catch (error) {
    setUpServerErrorView(dispatch, error);
  }
  dispatch({
    type: 'setIsBiaFilterOptionsLoading',
    payload: false,
  });
};

export const fetchSideCardData = async (
  dispatch: Dispatch<Action>,
  setDates: Dispatch<
    SetStateAction<{
      ASIA: string;
      AMERICAS: string;
      EUROPE: string;
      AUSTRALIA: string;
    }>
  >
) => {
  try {
    const result = await lastRefesh();
    setDates(result.data);
  } catch (error) {
    setUpServerErrorView(dispatch, error);
  }
};
