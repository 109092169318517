import { Menu, MenuItem } from '@sede-x/shell-ds-react-framework';
import React from 'react';

const Logout = (props: { logoutHandler: () => void }) => {
  return (
    <Menu>
      <MenuItem key="logout" onClick={props.logoutHandler}>
        Logout
      </MenuItem>
    </Menu>
  );
};

export default Logout;
