import React, { useContext, useEffect } from 'react';
import { ChartContainer } from './PPAInsightColumnCharts.style';
import { GlassChart } from '@sede-x/glass-design-library';
import { AppContext } from '../../../../../Context/AppContext';
import { getVolumeByTechTypeData } from '../../PpaInsightUtils';
import { PPAInsightNoRecords } from '../../PPAInsightNoRecordsPage/PPAInsightNoRecords';

export const PPAInsightColumnChart = () => {
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } = useContext(
    AppContext
  ).ppaInsight;
  useEffect(() => {
    getVolumeByTechTypeData(ppaInsightDispatch, ppaInsightState.filteredValues);
  }, [ppaInsightState.filteredValues]);

  const { chartData, categories } = ppaInsightState.volumeByTechTypeData.reduce(
    (acc, item) => ({
      chartData: [...acc.chartData, item.CAPACITY_EQUIVALENT],
      categories: [...acc.categories, item.RENEWABLE_CATEGORY],
    }),
    { chartData: [] as number[], categories: [] as string[] }
  );

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'PPA Volume by Technology Type',
      align: 'left',
    },
    subtitle: {
      text: 'A summary of volume by renewable category',
      align: 'left',
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    series: [
      {
        name: 'Renewable Categories',
        data: chartData,
      },
    ],
  };
  return (
    <ChartContainer>
      {ppaInsightState.volumeByTechTypeData.length === 0 && (
        <PPAInsightNoRecords />
      )}
      {ppaInsightState.volumeByTechTypeData.length > 0 && (
        <GlassChart options={options} />
      )}
    </ChartContainer>
  );
};
