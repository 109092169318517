/* eslint-disable no-prototype-builtins */
import React, { Dispatch, ReactElement } from 'react';
import { Select, TextArea, TextInput } from '@sede-x/shell-ds-react-framework';
import tableEditOptions from '../../../api/TableEditOptions';
import tableStyles from '../../style.module.css';
import '../tableHeadingStyle.css';
import {
  COUNTRY_CODE,
  COUNTRY_NAME,
  IN_SCOPE,
  NUCLEUS_SEAM_REGION_SCOPE_TABLE,
  OUT_OF_SCOPE,
  ENDUR_SEAM_REGION_SCOPE_TABLE,
  ENDURSLMT_STATIC_MAP_NCF_TABLE,
  ENDURSLMT_I_POWER_TABLE,
  NOT_UPDATED,
  THREE,
  TWO,
} from '../../../api/constants';
import { areStringsEqual, decorateColName } from '../../Utils/utils';
import { isQtyValid } from '../../pages/ViewEditReportPage/ViewEditReportFunctions';
import { Action } from '../../../types/types';

type X = [
  string[],
  boolean,
  boolean,
  boolean,
  (arg: boolean) => void,
  string[],
  (arg: boolean) => void,
  (arg: string) => void,
  (arg: string) => void,
  (arg: string) => void,
  { COUNTRY_NAME: string; COUNTRY_CODE: string }[],
  { value: string; label: string },
  any,
  any
];

export const editAndAuditReportTableCols = (
  cols: any,
  state: any,
  dispatch: any,
  ...otherArgs: any[]
) => {
  const [
    currColumns,
    isEditable,
    isReadyToPublish,
    isEditError,
    setIsDirtyData,
    errorTableData,
    setIsQtyExceedThreeWholeDigits,
    setUpdatedCol,
    setOriginalVal,
    setUpdatedVal,
    regionOptionsForAmericasSeamRegionTable,
    ncfCatOptionsForAsiaJapanTable,
  ] = otherArgs as X;

  let colObj: any = [];

  const scopeOptions = tableEditOptions.scopeOptions;
  const countryOptionsAmericas: any = [];
  let countryNameAndCodeAmericas: any[] = [
    { COUNTRY_NAME: 'SELECT', COUNTRY_CODE: 'SELECT' },
  ];
  if (regionOptionsForAmericasSeamRegionTable) {
    countryNameAndCodeAmericas = [...regionOptionsForAmericasSeamRegionTable];
    for (const nameCode of countryNameAndCodeAmericas) {
      countryOptionsAmericas.push({
        value: nameCode.COUNTRY_NAME as string,
        label: nameCode.COUNTRY_NAME as string,
      });
    }
  }

  const colNames = currColumns?.slice(0) || [];

  const isScopeTableSelected =
    state.tableSelected === ENDUR_SEAM_REGION_SCOPE_TABLE ||
    state.tableSelected === NUCLEUS_SEAM_REGION_SCOPE_TABLE;

  const isAsiaJapanTableSelected =
    state.tableSelected === ENDURSLMT_STATIC_MAP_NCF_TABLE ||
    state.tableSelected === ENDURSLMT_I_POWER_TABLE;

  const colData = { cols, colObj, colNames };
  const booleanOptions = {
    isEditable,
    isReadyToPublish,
    isEditError,
    isAsiaJapanTableSelected,
    isScopeTableSelected,
  };
  const setFuncs = {
    setUpdatedCol,
    setOriginalVal,
    setUpdatedVal,
    setIsQtyExceedThreeWholeDigits,
    setIsDirtyData,
  };
  const optionsData = {
    countryNameAndCodeAmericas,
    scopeOptions,
    ncfCatOptionsForAsiaJapanTable,
    countryOptionsAmericas,
  };
  const actions = { state, dispatch };
  colObj = updateCols(
    colData,
    booleanOptions,
    setFuncs,
    optionsData,
    actions,
    errorTableData
  );
  //  create/re-create columns

  return colObj;
};

const setDirtyDataObj = (
  stateVars: {
    setIsQtyExceedThreeWholeDigits?: any;
    dispatch?: any;
    masterData: any;
    state?: any;
  },
  ID1: string | number,
  COMMENTS1: string | number,
  value: { [x: string]: any },
  columnName: string | number
) => {
  let result: { [key: string]: string } = {};
  for (const masterDataObj of stateVars.masterData) {
    if (masterDataObj[ID1] === value[ID1]) {
      result = {
        [ID1]: value[ID1],
        [columnName]: value[columnName],
      };
      if (!isCommentsError(masterDataObj[COMMENTS1], value[COMMENTS1])) {
        result[COMMENTS1] = value[COMMENTS1];
      }
    }
  }
  return result;
};

const setDirtyDataAndPublishData = (options: {
  newDirtyData: any;
  ID1: any;
  SCOPE1: any;
  COMMENTS1: any;
  QTY1: any;
  value: any;
  stateVars: any;
  columnName: any;
  isScopeTableSelected: any;
  publishData: any;
  countryNameAndCodeAmericas: any;
  newDirtyDataObj: any;
}) => {
  const {
    newDirtyData,
    ID1,
    SCOPE1,
    COMMENTS1,
    QTY1,
    value,
    stateVars,
    columnName,
    isScopeTableSelected,
    publishData,
    countryNameAndCodeAmericas,
  } = options;
  const data = {
    columnName,
    COMMENTS1,
    isScopeTableSelected,
    options,
    value,
    countryNameAndCodeAmericas,
    SCOPE1,
    newDirtyData,
    publishData,
  };

  if (newDirtyData.length > 0) {
    let isUpdated = false;
    let currIndex = 0;

    // iterate the array
    for (const obj of newDirtyData) {
      currIndex = newDirtyData.indexOf(obj);
      const nonDirtyRow = false;
      // check if ID (I_Aligne_Id) is already present
      if (obj[ID1] === value[ID1]) {
        const param = {
          stateVars,
          ID1,
          obj,
          columnName,
          value,
          isScopeTableSelected,
          SCOPE1,
          QTY1,
          publishData,
          currIndex,
          countryNameAndCodeAmericas,
          nonDirtyRow,
          COMMENTS1,
          newDirtyData,
        };
        const [updatedNewDirtyData, updatedPublishData] = updateExistingRecord(
          param
        );
        isUpdated = true;
        return [updatedNewDirtyData, updatedPublishData];
      }
    }
    //  for new ID (I_Aligne_Id)
    const [updatedNewDirtyData, updatedPublishData] = addNewRecord(
      data,
      isUpdated
    );
    return [updatedNewDirtyData, updatedPublishData];
  } else {
    const [updatedNewDirtyData, updatedPublishData] = addFirstRecord(data);
    return [updatedNewDirtyData, updatedPublishData];
  }
};

const updateExistingRecord = (data: {
  stateVars?: any;
  ID1?: any;
  obj?: any;
  columnName?: any;
  value?: any;
  isScopeTableSelected?: any;
  SCOPE1?: any;
  QTY1?: any;
  publishData?: any;
  currIndex?: any;
  countryNameAndCodeAmericas?: any;
  nonDirtyRow?: any;
  COMMENTS1?: any;
  newDirtyData?: any;
}) => {
  const {
    stateVars,
    ID1,
    obj,
    columnName,
    value,
    isScopeTableSelected,
    SCOPE1,
    QTY1,
    publishData,
    currIndex,
    countryNameAndCodeAmericas,
    COMMENTS1,
    newDirtyData,
  } = data;
  // compare the updated value with master value
  for (const masterDataObj of stateVars.masterData) {
    if (masterDataObj[ID1] === obj[ID1]) {
      const masterColVal = masterDataObj[columnName];
      const valueColVal = setCurrCellValue(
        isScopeTableSelected,
        columnName,
        SCOPE1,
        QTY1,
        value[columnName]
      );
      // if updated value is not equal to master value, then update the dirty data
      if (masterColVal !== valueColVal) {
        const newValueData = {
          obj,
          columnName,
          value,
          publishData,
          currIndex,
          isScopeTableSelected,
          SCOPE1,
          countryNameAndCodeAmericas,
        };
        updateDataWithNewValue(newValueData);
      } else {
        const params = {
          obj,
          columnName,
          publishData,
          currIndex,
          value,
          isScopeTableSelected,
          SCOPE1,
          ID1,
          COMMENTS1,
          newDirtyData,
          data,
        };
        const [
          updatedNewDirtyData,
          updatedPublishData,
        ] = updateRecordWithRevertedChange(params);
        data.newDirtyData = updatedNewDirtyData;
        data.publishData = updatedPublishData;
      }
    }
  }
  return [newDirtyData, publishData];
};

const updateRecordWithRevertedChange = (params: {
  obj: any;
  columnName: any;
  publishData: any;
  currIndex: any;
  value: any;
  isScopeTableSelected: any;
  SCOPE1: any;
  ID1: any;
  COMMENTS1: any;
  newDirtyData: any;
  data: any;
}) => {
  const {
    obj,
    columnName,
    publishData,
    currIndex,
    value,
    isScopeTableSelected,
    SCOPE1,
    ID1,
    COMMENTS1,
    newDirtyData,
    data,
  } = params;
  // else if updated value matches with master data, remove the key from dirty data
  delete obj[columnName];
  publishData[currIndex] = value;
  // delete country details for scope table if same as master row
  if (isScopeTableSelected && columnName === SCOPE1) {
    delete obj[COUNTRY_NAME];
    delete obj[COUNTRY_CODE];
  } else if (isScopeTableSelected && columnName === COUNTRY_NAME) {
    delete obj[COUNTRY_CODE];
  }
  // if the object has no updated columns, deleted the entire object from dirty data
  //  OR the dirty object just has ID and COMMENTS columns, delete that entry
  const isFirstIndexAvailableAsId =
    Object.keys(obj).length === 1 && Object.keys(obj)[0] === ID1;
  const isSecondIndexAvailableAsComments =
    Object.keys(obj).length === TWO &&
    Object.keys(obj)[0] === ID1 &&
    Object.keys(obj)[1] === COMMENTS1;
  data.nonDirtyRow =
    isFirstIndexAvailableAsId || isSecondIndexAvailableAsComments;
  // during validation error mode, remove the row from dirtyData if all changes are reverted
  if (data.nonDirtyRow) {
    newDirtyData.splice(currIndex, 1);
    publishData.splice(currIndex, 1);
  }
  return [newDirtyData, publishData];
};

const updateDataWithNewValue = (data: {
  obj: any;
  columnName: any;
  value: any;
  publishData: any;
  currIndex: any;
  isScopeTableSelected: any;
  SCOPE1: any;
  countryNameAndCodeAmericas: any;
}) => {
  const {
    obj,
    columnName,
    value,
    publishData,
    currIndex,
    isScopeTableSelected,
    SCOPE1,
    countryNameAndCodeAmericas,
  } = data;
  // update or add column with new column name
  obj[columnName] = value[columnName];
  publishData[currIndex] = value;
  // updating country details for scope table
  if (isScopeTableSelected && columnName === SCOPE1) {
    obj[COUNTRY_NAME] = value[COUNTRY_NAME];
    obj[COUNTRY_CODE] = getCountryCode(
      value[COUNTRY_NAME],
      countryNameAndCodeAmericas
    );
  } else if (isScopeTableSelected && columnName === COUNTRY_NAME) {
    obj[COUNTRY_CODE] = getCountryCode(
      value[COUNTRY_NAME],
      countryNameAndCodeAmericas
    );
  }
};

const setCurrCellValue = (
  isScopeTableSelected: any,
  columnName: any,
  SCOPE1: any,
  QTY1: any,
  actualValue: string | number | any[]
) => {
  // if scope is changed for an existing record, convert scope value from string to number
  if (
    isScopeTableSelected &&
    columnName === SCOPE1 &&
    typeof actualValue === 'string'
  ) {
    return (actualValue = Number(actualValue.slice(0, 1)));
  } else if (columnName === QTY1) {
    return (actualValue = Number(actualValue));
  }
  return actualValue;
};

const addFirstRecord = (data: {
  columnName: any;
  COMMENTS1: any;
  isScopeTableSelected: any;
  options: any;
  value: any;
  countryNameAndCodeAmericas: any;
  SCOPE1: any;
  newDirtyData: any;
  publishData: any;
}) => {
  const {
    columnName,
    COMMENTS1,
    isScopeTableSelected,
    options,
    value,
    countryNameAndCodeAmericas,
    SCOPE1,
    newDirtyData,
    publishData,
  } = data;
  if (!areStringsEqual(columnName, COMMENTS1)) {
    // update country code and country name for changes in scope tables
    if (isScopeTableSelected) {
      options.newDirtyDataObj = insertCountryDetailsToDirtyData(
        options.newDirtyDataObj,
        value,
        columnName,
        countryNameAndCodeAmericas,
        SCOPE1
      );
    }
    //  A First Entry is done when an editable field is changed, not when COMMENTS col is changed first
    newDirtyData.push(options.newDirtyDataObj);
    publishData.push(value);
  }
  return [newDirtyData, publishData];
};

const addNewRecord = (
  data: {
    columnName?: any;
    COMMENTS1?: any;
    isScopeTableSelected?: any;
    options?: any;
    value?: any;
    countryNameAndCodeAmericas?: any;
    SCOPE1?: any;
    newDirtyData?: any;
    publishData?: any;
  },
  isUpdated: boolean
) => {
  const {
    columnName,
    COMMENTS1,
    isScopeTableSelected,
    newDirtyData,
    publishData,
    value,
    countryNameAndCodeAmericas,
    SCOPE1,
  } = data;
  if (!isUpdated) {
    if (columnName !== COMMENTS1) {
      // update country code and country name for changes in scope tables
      if (isScopeTableSelected) {
        data.options.newDirtyDataObj = insertCountryDetailsToDirtyData(
          data.options.newDirtyDataObj,
          value,
          columnName,
          countryNameAndCodeAmericas,
          SCOPE1
        );
      }
      //  A new Entry is done when an editable field is changed, not when COMMENTS col is changed first
      newDirtyData.push(data.options.newDirtyDataObj);
      publishData.push(value);
    }
  }
  return [newDirtyData, publishData];
};

const maintainDirtyData = (
  colData: { columnName: any; value: any; countryNameAndCodeAmericas: any },
  colNames: {
    COMMENTS1: any;
    ID1: any;
    QTY1: any;
    SCOPE1: any;
    DEAL_QUANTITY1: any;
  },
  booleanVars: { isEditError: any; isScopeTableSelected: any },
  stateVars: {
    setIsQtyExceedThreeWholeDigits: any;
    dispatch: any;
    masterData: any;
    state: any;
  }
) => {
  const { columnName, value, countryNameAndCodeAmericas } = colData;
  const { COMMENTS1, ID1, QTY1, SCOPE1, DEAL_QUANTITY1 } = colNames;
  const { isEditError, isScopeTableSelected } = booleanVars;
  const { setIsQtyExceedThreeWholeDigits, dispatch, state } = stateVars;
  const newDirtyData = [...state.dirtyData];
  const publishData = [...state.publishRows];
  // in case data is already in master Data but not in dirty data.
  // It happens when a dirty data is reverted to actual with COMMENTS being different
  const newDirtyDataObj = setDirtyDataObj(
    stateVars,
    ID1,
    COMMENTS1,
    value,
    columnName
  );
  if (isEditError) {
    // during validation error, masterErroredRows is the master data
    stateVars.masterData = [...state.masterErroredRows];
  } else {
    stateVars.masterData = [...state.masterPaginationData];
  }
  const options = {
    newDirtyData,
    ID1,
    SCOPE1,
    COMMENTS1,
    QTY1,
    value,
    stateVars,
    columnName,
    isScopeTableSelected,
    publishData,
    countryNameAndCodeAmericas,
    newDirtyDataObj,
  };
  const [updatedNewDirtyData, updatedPublishData] = setDirtyDataAndPublishData(
    options
  );

  dispatch({ type: 'setDirtyData', payload: updatedNewDirtyData });
  dispatch({ type: 'setPublishRows', payload: updatedPublishData });
  qtyCheckForThreeWholeDigits(
    columnName,
    QTY1,
    DEAL_QUANTITY1,
    newDirtyData,
    setIsQtyExceedThreeWholeDigits
  );
};

const qtyCheckForThreeWholeDigits = (
  columnName: string | number,
  QTY1: any,
  DEAL_QUANTITY1: any,
  newDirtyData: string | any[],
  setIsQtyExceedThreeWholeDigits: (arg0: boolean) => void
) => {
  // soft check for whole number having more than 3 characters
  let qtyLength = 0;
  let doesAnyQtyExceedThreeWholeDigits = false;
  if (
    areStringsEqual(columnName, QTY1) ||
    areStringsEqual(columnName, DEAL_QUANTITY1)
  ) {
    for (
      let i = 0;
      i < newDirtyData.length && newDirtyData[i].hasOwnProperty(columnName);
      i++
    ) {
      qtyLength = newDirtyData[i][columnName].toString().split('.')[0].length;
      if (qtyLength > THREE) {
        doesAnyQtyExceedThreeWholeDigits = true;
        break;
      }
    }
    if (doesAnyQtyExceedThreeWholeDigits) {
      setIsQtyExceedThreeWholeDigits(true);
    } else {
      setIsQtyExceedThreeWholeDigits(false);
    }
  }
};

const isCommentsError = (masterComment: any, currentComment: any) => {
  if (masterComment === '' && masterComment != currentComment) {
    // if master comment is already empty
    return false;
  } else if (masterComment !== currentComment && currentComment !== '') {
    return false;
  } else {
    return true;
  }
};

const maintainErrorData = (
  value: any,
  state: {
    erroredRows: any;
    masterErroredRows: any;
    masterPaginationData: any;
    editableCol: string | any[];
  },
  isEditError: any,
  isScopeTableSelected: any,
  colNames: {
    COMMENTS1: any;
    QTY1: any;
    DEAL_QUANTITY1: any;
    ID1: any;
    SCOPE1: any;
  },
  dispatch: any
) => {
  const { COMMENTS1, QTY1, DEAL_QUANTITY1, ID1, SCOPE1 } = colNames;
  // let masterErrData = [...masterErroredRows]
  let masterData = [];
  const errorRows = [...state.erroredRows];
  // let masterPageData = [...masterPaginationData]
  let currentRow = value;
  let isCurrentRowErrored = false;
  let isCurrentRowExistsInErrorData = false;
  let currentRowIndexInErrorData = -1;
  let isCommentsChanged = false;

  const [
    isCommentsEmpty,
    isQtyNotvalid,
    isDealQtyNotvalid,
  ] = getCommentsQtyDealQtyValidity(
    currentRow,
    COMMENTS1,
    QTY1,
    DEAL_QUANTITY1,
    value
  );
  if (isEditError) {
    masterData = [...state.masterErroredRows];
  } else {
    masterData = [...state.masterPaginationData];
  }

  // get the index of master data for current row
  const masterDataRow = getMasterDataIndex(masterData, currentRow, ID1);
  // check if comment is changed
  isCommentsChanged = currentRow[COMMENTS1] !== masterDataRow[COMMENTS1];
  // check if country name is empty when IN_SCOPE
  const isCountryEmptyWhenInScope = checkCountryEmptyWhenInScope(
    isScopeTableSelected,
    currentRow,
    SCOPE1
  );

  currentRow = makeCurrentRowScopeToNumeric(
    isScopeTableSelected,
    currentRow,
    SCOPE1
  );
  const isAnyEditableColChangedExceptComments = isColChangedExceptComments(
    state,
    QTY1,
    currentRow,
    COMMENTS1,
    masterDataRow
  );
  isCurrentRowErrored = checkIfCurrentRowErrored(
    isAnyEditableColChangedExceptComments,
    isCommentsChanged,
    isCommentsEmpty,
    isQtyNotvalid,
    isDealQtyNotvalid,
    isCountryEmptyWhenInScope
  );
  for (let i = 0; i < errorRows.length; i++) {
    if (errorRows[i][ID1] === currentRow[ID1]) {
      currentRowIndexInErrorData = i;
      isCurrentRowExistsInErrorData = true;
      break;
    }
  }
  // if its an errored row and ID already present in Error data then, replace the old row with current incoming Row
  updateExistingRowData(
    isCurrentRowErrored,
    isCurrentRowExistsInErrorData,
    errorRows,
    currentRowIndexInErrorData,
    currentRow,
    dispatch
  );

  // if its an errored row and ID is not in Error data, push this as new row
  addNewErroredRowData(
    isCurrentRowErrored,
    isCurrentRowExistsInErrorData,
    errorRows,
    currentRow,
    masterDataRow,
    dispatch,
    state
  );

  // if its NOT an errored row and ID already present in Error data, remove the row from erroredRow
  removeExistingRowIfNotErrored(
    isCurrentRowErrored,
    isCurrentRowExistsInErrorData,
    errorRows,
    currentRowIndexInErrorData,
    dispatch
  );
};

const checkIfCurrentRowErrored = (
  isAnyEditableColChangedExceptComments: boolean,
  isCommentsChanged: boolean,
  isCommentsEmpty: boolean,
  isQtyNotvalid: boolean,
  isDealQtyNotvalid: boolean,
  isCountryEmptyWhenInScope: boolean
) => {
  const areQtyDealQtyCountryGood =
    isQtyNotvalid || isDealQtyNotvalid || isCountryEmptyWhenInScope;
  return (
    (isAnyEditableColChangedExceptComments &&
      (!isCommentsChanged || isCommentsEmpty)) ||
    areQtyDealQtyCountryGood
  );
};

const isColChangedExceptComments = (
  state: {
    erroredRows?: any;
    masterErroredRows?: any;
    masterPaginationData?: any;
    editableCol: any;
  },
  QTY1: any,
  currentRow: { [x: string]: any },
  COMMENTS1: string,
  masterDataRow: { [x: string]: any }
) => {
  for (const tableData of state.editableCol) {
    const col = tableData;
    if (col === QTY1) {
      currentRow[col] = Number(currentRow[col]);
    }
    // check if editable columns are changed other than comments
    if (
      col.toLowerCase() !== COMMENTS1.toLowerCase() &&
      currentRow[col] !== masterDataRow[col]
    ) {
      return true;
    }
  }
  return false;
};

const makeCurrentRowScopeToNumeric = (
  isScopeTableSelected: any,
  currentRow: { [x: string]: string | number },
  SCOPE1: string
) => {
  // convert scope value to number else it will always error out for being different from master scope value, which is a number
  if (isScopeTableSelected && typeof currentRow[SCOPE1] === 'string') {
    currentRow[SCOPE1] = currentRow[SCOPE1] === IN_SCOPE ? 1 : 0;
  }
  return currentRow;
};

const checkCountryEmptyWhenInScope = (
  isScopeTableSelected: any,
  currentRow: { [x: string]: string | number },
  SCOPE1: string
) => {
  if (
    isScopeTableSelected &&
    (currentRow[SCOPE1] === IN_SCOPE || currentRow[SCOPE1] === 1)
  ) {
    return currentRow[COUNTRY_NAME] === '';
  }
  return false;
};

const getMasterDataIndex = (
  masterData: string | any[],
  currentRow: { [x: string]: any },
  ID1: string | number
) => {
  for (const masterDataObj of masterData) {
    if (masterDataObj[ID1] === currentRow[ID1]) {
      return masterDataObj;
    }
  }
};

const getCommentsQtyDealQtyValidity = (
  currentRow: any,
  COMMENTS1: string,
  QTY1: string | number,
  DEAL_QUANTITY1: string,
  value: { [x: string]: string | number }
) => {
  return [
    Object.hasOwn(currentRow, COMMENTS1) && value[COMMENTS1] === '',
    Object.hasOwn(currentRow, QTY1) && !isQtyValid(value[QTY1]),
    Object.hasOwn(currentRow, DEAL_QUANTITY1) &&
      !isQtyValid(value[DEAL_QUANTITY1]),
  ];
};

const updateExistingRowData = (
  isCurrentRowErrored: boolean,
  isCurrentRowExistsInErrorData: boolean,
  errorRows: any[],
  currentRowIndexInErrorData: number,
  currentRow: any,
  dispatch: Dispatch<Action>
) => {
  if (isCurrentRowErrored && isCurrentRowExistsInErrorData) {
    errorRows[currentRowIndexInErrorData] = currentRow;
    dispatch({ type: 'setErroredRows', payload: errorRows });
  }
};

const addNewErroredRowData = (
  isCurrentRowErrored: boolean,
  isCurrentRowExistsInErrorData: boolean,
  errorRows: any[],
  currentRow: any,
  masterDataRow: any,
  dispatch: Dispatch<Action>,
  state: {
    erroredRows?: any;
    masterErroredRows: any;
    masterPaginationData?: any;
    editableCol?: string | any[];
  }
) => {
  if (isCurrentRowErrored && !isCurrentRowExistsInErrorData) {
    errorRows.push(currentRow);
    dispatch({ type: 'setErroredRows', payload: errorRows });
    //  entry of same data to master error data
    state.masterErroredRows.push(masterDataRow);
    dispatch({
      type: 'setMasterErroredRows',
      payload: state.masterErroredRows,
    });
  }
};

const removeExistingRowIfNotErrored = (
  isCurrentRowErrored: boolean,
  isCurrentRowExistsInErrorData: boolean,
  errorRows: any[],
  currentRowIndexInErrorData: number,
  dispatch: Dispatch<Action>
) => {
  if (!isCurrentRowErrored && isCurrentRowExistsInErrorData) {
    errorRows.splice(currentRowIndexInErrorData, 1);
    dispatch({ type: 'setErroredRows', payload: errorRows });
  }
};

// insertCountryDetailsToDirtyData function is to update dirty data with country name and country code automatically.
// the function works only for new entry in dirty data, not to update existing record in dirty data.
const insertCountryDetailsToDirtyData = (
  currDirtyRow: any,
  row: any,
  column: any,
  countryNameAndCodeAmericas: any,
  SCOPE1: string | number
) => {
  const updatedDirtyData = currDirtyRow;
  // if Country name is changed, update dirty data with Country Code
  if (areStringsEqual(column, COUNTRY_NAME)) {
    updatedDirtyData[COUNTRY_CODE] = getCountryCode(
      row[COUNTRY_NAME],
      countryNameAndCodeAmericas
    );
  }

  // if scope is changed to 0, update dirty data with Country Name and Country Code as empty values
  if (
    areStringsEqual(column, SCOPE1) &&
    (row[SCOPE1] === 0 || row[SCOPE1] === OUT_OF_SCOPE)
  ) {
    updatedDirtyData[COUNTRY_CODE] = getCountryCode(
      '',
      countryNameAndCodeAmericas
    );
    updatedDirtyData[COUNTRY_NAME] = '';
  }

  // if scope is changed to 1, update dirty data with Country Name and Country Code values
  if (
    areStringsEqual(column, SCOPE1) &&
    (row[SCOPE1] === 1 || row[SCOPE1] === IN_SCOPE)
  ) {
    updatedDirtyData[COUNTRY_CODE] = getCountryCode(
      row[COUNTRY_NAME],
      countryNameAndCodeAmericas
    );
    updatedDirtyData[COUNTRY_NAME] = row[COUNTRY_NAME];
  }
  return updatedDirtyData;
};

const getCountryCode = (
  countryName: string,
  countryNameAndCodeAmericas: any
) => {
  let countryCode = '';
  for (const key of countryNameAndCodeAmericas) {
    if (key.COUNTRY_NAME === countryName) {
      countryCode = key.COUNTRY_CODE;
      break;
    }
  }
  return countryCode;
};

// handleEditData is called whenever a data is changed
const handleEditData = (
  colData: { e: any; val?: any; index: any; row: any },
  booleanVar: { isEditError: any; isScopeTableSelected: any },
  colNames: string[],
  stateVars: {
    state: any;
    setIsDirtyData: any;
    dispatch: any;
    setUpdatedVal: any;
    setOriginalVal: any;
    setUpdatedCol: any;
    countryNameAndCodeAmericas: any;
    setIsQtyExceedThreeWholeDigits: any;
    errorTableData: any;
  }
) => {
  const { e, val, index, row } = colData;
  const { isEditError, isScopeTableSelected } = booleanVar;
  const [ID1, QTY1, DEAL_QUANTITY1, , , , COMMENTS1, SCOPE1] = colNames;
  const {
    state,
    setIsDirtyData,
    dispatch,
    setUpdatedVal,
    setOriginalVal,
    setUpdatedCol,
    countryNameAndCodeAmericas,
    setIsQtyExceedThreeWholeDigits,
    errorTableData,
  } = stateVars;
  const columnName = val;
  setIsDirtyData(true);
  //  currPageData is the data being shown currently on the page. during validation error, the current data is errored data
  const { currPageData, masterData } = getData(
    isEditError,
    errorTableData,
    state
  );
  const info = {
    e,
    row,
    columnName,
    QTY1,
    DEAL_QUANTITY1,
    setUpdatedCol,
    masterData,
    ID1,
    setOriginalVal,
    setUpdatedVal,
  };
  handleEditForQtyAndDealQty(info);
  const updatedPageData = currPageData.map((value: any, ind: any) => {
    if (ind === index) {
      value[columnName] = e.target ? e.target.value : e;
      const colData = { columnName, value, countryNameAndCodeAmericas };
      const colNames = { COMMENTS1, ID1, QTY1, SCOPE1, DEAL_QUANTITY1 };
      const booleanVars = { isEditError, isScopeTableSelected };
      const stateVars = {
        setIsQtyExceedThreeWholeDigits,
        dispatch,
        masterData,
        state,
      };
      // maintain dirty data
      maintainDirtyData(colData, colNames, booleanVars, stateVars);

      // maintain error data
      maintainErrorData(
        value,
        state,
        isEditError,
        isScopeTableSelected,
        { COMMENTS1, QTY1, DEAL_QUANTITY1, ID1, SCOPE1 },
        dispatch
      );
    }
    return value;
  });
  dispatch({ type: 'setPaginationData', payload: updatedPageData });
};

const handleEditForQtyAndDealQty = (data: {
  e: any;
  row: any;
  columnName: any;
  QTY1: any;
  DEAL_QUANTITY1: any;
  setUpdatedCol: any;
  masterData: any;
  ID1: any;
  setOriginalVal: any;
  setUpdatedVal: any;
}) => {
  const {
    e,
    row,
    columnName,
    QTY1,
    DEAL_QUANTITY1,
    setUpdatedCol,
    masterData,
    ID1,
    setOriginalVal,
    setUpdatedVal,
  } = data;
  if (
    areStringsEqual(columnName, QTY1) ||
    areStringsEqual(columnName, DEAL_QUANTITY1)
  ) {
    let masterVal: any;
    setUpdatedCol(columnName);
    for (const masterDataObj of masterData) {
      if (masterDataObj[ID1] === row[ID1]) {
        masterVal = masterDataObj[columnName];
      }
    }
    setOriginalVal(masterVal);
    const newVal = e.target ? e.target.value : e;
    if (masterVal === newVal) {
      setUpdatedVal(NOT_UPDATED);
    } else {
      setUpdatedVal(newVal);
    }
  }
};

const getData = (
  isEditError: any,
  errorTableData: any,
  state: {
    masterErroredRows: any;
    paginationData: any;
    masterPaginationData: any;
  }
) => {
  let currPageData;
  let masterData;

  if (isEditError) {
    currPageData = [...errorTableData];
    masterData = [...state.masterErroredRows];
  } else {
    currPageData = [...state.paginationData];
    masterData = [...state.masterPaginationData];
  }
  return { currPageData, masterData };
};

// check if corresponding row has any changes for allowed editable columns
const isCommentsColDirty = (
  row: any,
  state: any,
  isEditError: any,
  ID1: string | number,
  COMMENTS1: string | number
) => {
  const dirtydata = [...state.dirtyData];
  const masterComments = getMasterComments(
    isEditError,
    state,
    ID1,
    row,
    COMMENTS1
  );
  // iterate the dirty data and check if current row ID is present in it and if present
  //  then if the comments are not updated, then return true. Coz comment is mandatory for any changed row
  let isDirty = false;
  for (const dirtData of dirtydata) {
    if (
      dirtData[ID1] === row[ID1] &&
      isCommentsError(masterComments, row[COMMENTS1])
    ) {
      isDirty = true;
      break;
    }
    isDirty = false;
  }
  return isDirty;
};

const getMasterComments = (
  isEditError: boolean,
  state: { dirtyData?: any; masterErroredRows: any; masterPaginationData: any },
  ID1: string | number,
  row: { [x: string]: any },
  COMMENTS1: string | number
) => {
  if (isEditError) {
    // get the comments column of the row
    for (const erroredData of state.masterErroredRows) {
      if (erroredData[ID1] === row[ID1]) {
        return erroredData[COMMENTS1];
      }
    }
  } else if (!isEditError) {
    // get the comments column of the row
    for (const paginationData of state.masterPaginationData) {
      if (paginationData[ID1] === row[ID1]) {
        return paginationData[COMMENTS1];
      }
    }
  }
};

const isScopeTableCountryNameEmptyWhenInScope = (
  row: any,
  SCOPE1: string | number
) => {
  let isDirty = false;
  if (
    (row[SCOPE1] === IN_SCOPE || row[SCOPE1] === 1) &&
    row[COUNTRY_NAME] === ''
  ) {
    isDirty = true;
  } else {
    isDirty = false;
  }
  return isDirty;
};

//  check if the particular box is dirty(different from master data)
const isBoxDirty = (
  row: any,
  value: any,
  val: any,
  isReadyToPublish: any,
  state: { masterErroredRows: any; masterPaginationData: any },
  booleanVars: { QTY1: any; DEAL_QUANTITY1: any; ID1: any },
  isEditError: any
) => {
  const { QTY1, DEAL_QUANTITY1, ID1 } = booleanVars;
  const masterData = isReadyToPublish
    ? [...state.masterErroredRows]
    : [...state.masterPaginationData];

  const columnName = val;

  if (
    (areStringsEqual(val, QTY1) || areStringsEqual(val, DEAL_QUANTITY1)) &&
    !isQtyValid(value)
  ) {
    return false;
  }

  const dataToCheck = isEditError ? state.masterErroredRows : masterData;

  for (const checkData of dataToCheck) {
    if (
      checkData[ID1] === row[ID1] &&
      columnName.toLowerCase().includes('scope') &&
      Number(checkData[columnName]) === Number(value.charAt(0))
    ) {
      return false;
    }
    if (checkData[ID1] === row[ID1] && checkData[columnName] === value) {
      return false;
    }
  }

  return true;
};

const customOptionsFunc = (
  row: any,
  columnName: any,
  colNames: { MARKET1: any; NCF_CATEGORY1: any; ID1: any },
  state: {
    marketOptions: any;
    ncfCategoryOptions: any;
    masterErroredRows: any;
    masterPaginationData: any;
  },
  booleanVars: { isAsiaJapanTableSelected: any; isEditError: any },
  options: { ncfCatOptionsForAsiaJapanTable: any; countryOptionsAmericas: any }
) => {
  let customOptions = [];
  const { isAsiaJapanTableSelected, isEditError } = booleanVars;
  const { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas } = options;
  const { MARKET1, NCF_CATEGORY1, ID1 } = colNames;

  if (columnName === MARKET1) {
    customOptions = JSON.parse(JSON.stringify(state.marketOptions));
  } else if (columnName === NCF_CATEGORY1) {
    if (isAsiaJapanTableSelected) {
      customOptions = JSON.parse(
        JSON.stringify(ncfCatOptionsForAsiaJapanTable)
      );
    } else {
      customOptions = JSON.parse(JSON.stringify(state.ncfCategoryOptions));
    }
  } else if (columnName === COUNTRY_NAME) {
    customOptions = JSON.parse(JSON.stringify(countryOptionsAmericas));
  }

  let masterData = [];
  let masterColValue = '';
  if (isEditError) {
    masterData = [...state.masterErroredRows];
  } else {
    masterData = [...state.masterPaginationData];
  }

  for (const masterDataObj of masterData) {
    if (masterDataObj[ID1] === row[ID1]) {
      masterColValue = masterDataObj[columnName];
    }
  }

  for (const optionData of customOptions) {
    if (
      optionData['label']?.toString().toUpperCase() ===
      masterColValue?.toUpperCase()
    ) {
      optionData['className'] = tableStyles.masterOption;
    }
  }

  return customOptions;
};

const updateColorHelper = (
  columnName: any,
  row: any,
  isEditError: any,
  state: { masterErroredRows: any; masterPaginationData: any },
  COMMENTS1: any,
  setStateFuncs: {
    setUpdatedCol: any;
    setOriginalVal: any;
    setUpdatedVal: any;
  },
  ID1: string | number
) => {
  const { setUpdatedCol, setOriginalVal, setUpdatedVal } = setStateFuncs;
  let masterData: any[] = [];
  let masterVal: any;
  if (isEditError) {
    masterData = [...state.masterErroredRows];
  } else {
    masterData = [...state.masterPaginationData];
  }
  //  for showing helpers to user
  if (!areStringsEqual(columnName, COMMENTS1)) {
    setUpdatedCol(columnName);
    for (const masterDataObj of masterData) {
      if (masterDataObj[ID1] === row[ID1]) {
        masterVal = masterDataObj[columnName];
      }
    }
    setOriginalVal(masterVal);
    if (masterVal === row[columnName]) {
      setUpdatedVal(NOT_UPDATED);
    } else {
      setUpdatedVal(row[columnName]);
    }
  }
};

const setCountryCodeValue = (
  row: any,
  SCOPE1: string | number,
  countryNameAndCodeAmericas: { COUNTRY_NAME: string; COUNTRY_CODE: string }[]
) => {
  const countryName = row[COUNTRY_NAME];
  let countryCode = '';
  const scope = row[SCOPE1];

  if (scope === OUT_OF_SCOPE) {
    countryCode = countryNameAndCodeAmericas[0].COUNTRY_CODE;
  } else {
    for (const ind of countryNameAndCodeAmericas) {
      if (ind.COUNTRY_NAME === countryName) {
        countryCode = ind.COUNTRY_CODE;
      }
    }
  }

  return countryCode;
};

const clearHelper = (
  setUpdatedCol: (arg0: string) => void,
  setOriginalVal: (arg0: string) => void,
  setUpdatedVal: (arg0: string) => void
) => {
  setUpdatedCol('');
  setOriginalVal('');
  setUpdatedVal('');
};

const updateCols = (
  colData: { cols: any; colObj: any; colNames: any },
  booleanOptions: {
    isEditable: any;
    isReadyToPublish: any;
    isEditError: any;
    isAsiaJapanTableSelected: any;
    isScopeTableSelected: any;
  },
  setFuncs: {
    setUpdatedCol: any;
    setOriginalVal: any;
    setUpdatedVal: any;
    setIsQtyExceedThreeWholeDigits: any;
    setIsDirtyData: any;
  },
  optionsData: {
    countryNameAndCodeAmericas: any;
    scopeOptions: any;
    ncfCatOptionsForAsiaJapanTable: any;
    countryOptionsAmericas: any;
  },
  actions: { state: any; dispatch: any },
  errorTableData: string[]
) => {
  const { cols, colObj, colNames } = colData;
  const {
    isEditable,
    isReadyToPublish,
    isEditError,
    isAsiaJapanTableSelected,
    isScopeTableSelected,
  } = booleanOptions;
  const {
    setUpdatedCol,
    setOriginalVal,
    setUpdatedVal,
    setIsQtyExceedThreeWholeDigits,
    setIsDirtyData,
  } = setFuncs;
  const {
    countryNameAndCodeAmericas,
    scopeOptions,
    ncfCatOptionsForAsiaJapanTable,
    countryOptionsAmericas,
  } = optionsData;
  const { state, dispatch } = actions;

  const setStateFuncs = { setUpdatedCol, setOriginalVal, setUpdatedVal };
  const booleanVar = { isEditError, isScopeTableSelected };
  const stateVars = {
    state,
    setIsDirtyData,
    dispatch,
    setUpdatedVal,
    setOriginalVal,
    setUpdatedCol,
    countryNameAndCodeAmericas,
    setIsQtyExceedThreeWholeDigits,
    errorTableData,
  };

  const [
    ID1,
    QTY1,
    DEAL_QUANTITY1,
    MARKET1,
    NCF_CATEGORY1,
    BUYSELL1,
    COMMENTS1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
  ] = colNames;

  const createInputComponent = (props: {
    Component: any;
    val: any;
    row: any;
    value: any;
    index: any;
    disabled: any;
    ariaLabel: any;
    options: any;
    type?: any;
    invalid?: any;
  }) => {
    const {
      Component,
      val,
      row,
      value,
      disabled,
      ariaLabel,
      options,
      type,
      invalid,
      index,
    } = props;
    return (
      <Component
        onMouseEnter={() =>
          updateColorHelper(
            val,
            row,
            isEditError,
            state,
            COMMENTS1,
            setStateFuncs,
            ID1
          )
        }
        onMouseLeave={() =>
          clearHelper(setUpdatedCol, setOriginalVal, setUpdatedVal)
        }
        value={value}
        style={
          isBoxDirty(
            row,
            value,
            val,
            isReadyToPublish,
            state,
            { QTY1, DEAL_QUANTITY1, ID1 },
            isEditError
          )
            ? styles.colChanged
            : styles.nothing
        }
        disabled={disabled}
        size="small"
        aria-label={ariaLabel}
        allowClear={false}
        options={options}
        onChange={(e: any) =>
          handleEditData(
            { e, val, index, row },
            booleanVar,
            colNames,
            stateVars
          )
        }
        type={type}
        invalid={invalid}
      />
    );
  };

  const columnsConfig = [
    {
      condition: (val: string) => areStringsEqual(val, MARKET1),
      render: (val: any) => (
        value: any,
        row: any,
        index: any
      ): ReactElement => {
        return createInputComponent({
          Component: Select,
          val,
          row,
          value,
          index,
          disabled: isReadyToPublish,
          ariaLabel: 'Select',
          options: customOptionsFunc(
            row,
            val,
            { MARKET1, NCF_CATEGORY1, ID1 },
            state,
            { isAsiaJapanTableSelected, isEditError },
            { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas }
          ),
        });
      },
    },
    {
      condition: (val: string) => areStringsEqual(val, NCF_CATEGORY1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: Select,
          val,
          row,
          value,
          index,
          disabled: isReadyToPublish,
          ariaLabel: 'Select',
          options: customOptionsFunc(
            row,
            val,
            { MARKET1, NCF_CATEGORY1, ID1 },
            state,
            { isAsiaJapanTableSelected, isEditError },
            { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas }
          ),
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, BUYSELL1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: Select,
          val,
          row,
          value,
          index,
          disabled: isReadyToPublish,
          ariaLabel: 'Select',
          options: state.buySellOptions,
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, QTY1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: TextInput,
          val,
          row,
          value,
          index,
          disabled: isReadyToPublish,
          type: 'number',
          ariaLabel: null,
          options: null,
          invalid: !isQtyValid(value),
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, DEAL_QUANTITY1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: TextInput,
          val,
          row,
          value,
          index,
          disabled: isReadyToPublish,
          type: 'number',
          ariaLabel: null,
          options: null,
          invalid: !isQtyValid(value),
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, VOLUME1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: TextInput,
          val,
          row,
          value,
          index,
          disabled: isReadyToPublish,
          type: 'number',
          ariaLabel: null,
          options: null,
          invalid: !isQtyValid(value),
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, COUNTRY_NAME),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: Select,
          val,
          row,
          value,
          index,
          disabled:
            isReadyToPublish ||
            row[SCOPE1] === OUT_OF_SCOPE ||
            row[SCOPE1] === 0,
          ariaLabel: 'Select',
          options: customOptionsFunc(
            row,
            val,
            { MARKET1, NCF_CATEGORY1, ID1 },
            state,
            { isAsiaJapanTableSelected, isEditError },
            { ncfCatOptionsForAsiaJapanTable, countryOptionsAmericas }
          ),
          invalid: isScopeTableCountryNameEmptyWhenInScope(row, SCOPE1),
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, COUNTRY_CODE),
      render: (val: any) => (_value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: TextInput,
          val,
          row,
          value: setCountryCodeValue(row, SCOPE1, countryNameAndCodeAmericas),
          index,
          disabled: true,
          ariaLabel: null,
          options: null,
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, SCOPE1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: Select,
          val,
          row,
          value:
            value === 0 || value === OUT_OF_SCOPE ? OUT_OF_SCOPE : IN_SCOPE,
          index,
          disabled: isReadyToPublish,
          ariaLabel: 'Select',
          options: scopeOptions,
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, NCF_SCOPE1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: Select,
          val,
          row,
          value:
            value === 0 || value === OUT_OF_SCOPE || value === '0'
              ? OUT_OF_SCOPE
              : IN_SCOPE,
          index,
          disabled: isReadyToPublish,
          ariaLabel: 'Select',
          options: scopeOptions,
        }),
    },
    {
      condition: (val: string) => areStringsEqual(val, COMMENTS1),
      render: (val: any) => (value: any, row: any, index: any): ReactElement =>
        createInputComponent({
          Component: TextArea,
          val,
          row,
          value,
          index,
          disabled: isReadyToPublish,
          ariaLabel: null,
          options: null,
          invalid: isCommentsColDirty(row, state, isEditError, ID1, COMMENTS1),
        }),
    },
  ];

  cols.map((val: any) => {
    if (
      (isEditable || isReadyToPublish) &&
      state.editableCol.indexOf(val) > -1
    ) {
      columnsConfig.forEach(config => {
        if (config.condition(val)) {
          colObj.push({
            dataIndex: val,
            key: val,
            title: <div>{decorateColName(val)}</div>,
            width: 100,
            className: tableStyles.reportPublishTableCols,
            render: config.render(val),
          });
        }
      });
    } else if (
      (BUYSELL1 && areStringsEqual(val, BUYSELL1)) ||
      val.toLowerCase() === 'region'
    ) {
      colObj.push({
        dataIndex: val,
        key: val,
        width: 100,
        title: <div>{decorateColName(val)}</div>,
        className: tableStyles.nonEditableCols,
        render: (value: any): ReactElement => <>{value.toUpperCase()}</>,
      });
    } else {
      colObj.push({
        dataIndex: val,
        key: val,
        title: <div>{decorateColName(val)}</div>,
        width: 50,
        className: tableStyles.nonEditableCols,
      });
    }
  });
  return colObj;
};

const styles = {
  colChanged: {
    border: '2px solid #8ef4b7',
  },
  nothing: {},
  countryCode: {
    color: 'black',
  },
  countryCodeChanged: {
    border: '2px solid #8ef4b7',
    color: 'black',
  },
};
