import { Action, IAnalyticsHomeData } from '../../types/types';

const AnalyticsHomeActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: IAnalyticsHomeData, payload: any) => IAnalyticsHomeData
> = {
  setNcfByCountry: (state, payload) => ({
    ...state,
    ncfByCountry: payload,
  }),
  setNcfQuarterwise: (state, payload) => ({
    ...state,
    ncfQuarterwise: payload,
  }),
  setNcfMonthWise: (state, payload) => ({
    ...state,
    ncfMonthWise: payload,
  }),
  setCounterpartyData: (state, payload) => ({
    ...state,
    counterpartyData: payload,
  }),
  setCptyFilterOptions: (state, payload) => ({
    ...state,
    cptyFilterOptions: payload,
  }),
  setCptyFilteredValues: (state, payload) => ({
    ...state,
    cptyFilteredValues: payload,
  }),
  setFilterOptions: (state, payload) => ({
    ...state,
    filterOptions: payload,
  }),
  setFilteredValues: (state, payload) => ({
    ...state,
    filteredValues: payload,
  }),
  setRegionVolumeDetailsTableData: (state, payload) => ({
    ...state,
    regionVolumeDetailsTableData: payload,
    isLoading: false,
  }),
  setIsLoading: (state, payload) => ({
    ...state,
    isLoading: payload,
  }),
  setVolumeByRegionChartData: (state, payload) => ({
    ...state,
    volumeByRegionChartData: {
      ...state.volumeByRegionChartData,
      ...payload,
    },
  }),
  setVolumeByRegionTableData: (state, payload) => ({
    ...state,
    volumeByRegionTableData: {
      ...state.volumeByRegionTableData,
      ...payload,
    },
  }),
};
export const AnalyticsHomeReducers = (
  state: IAnalyticsHomeData,
  action: Action
) => {
  if (Object.hasOwn(AnalyticsHomeActionHandlers, action.type)) {
    const handler = AnalyticsHomeActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
