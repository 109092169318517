import React, { useEffect, useContext } from 'react';
import { Divider, Grid } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import { PpaInsightsFilterSection } from './PPAInsightFilterSection/PpaInsightsFilterSection';
import { AppContext } from '../../../Context/AppContext';
import { getAndSetFilterOptions } from './PpaInsightUtils';
import { PpaLoadingModal } from './PPALoadingModal/PpaLoadingModal';
import { PPAInsightDataView } from './PPAInsightDataView/PPAInsightDataView';
import { PPAInsightServerError } from './PPAInsightServerError/PPAInsightServerError';

export const PpaInsights = () => {
  const { dispatch: appDataDispatch } = useContext(AppContext).appData;
  const { dispatch: ppaInsightDispatch } = useContext(AppContext).ppaInsight;
  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
    getAndSetFilterOptions(ppaInsightDispatch);
  }, []);
  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
  }, []);

  return (
    <div
      style={{
        height: '100%',
        background: '#F5F5F5',
        overflowY: 'scroll',
        padding: '20px',
      }}
      data-testid="ppa-insights-home"
    >
      <PpaLoadingModal />
      <PPAInsightServerError />
      <Grid
        flow="column"
        columnGap="2px"
        alignItems="start"
        columns={'14% 5% 81%'}
      >
        <Grid.Cell>
          <PpaInsightsFilterSection />
        </Grid.Cell>
        <Grid.Cell>
          <Divider
            direction="vertical"
            size="medium"
            style={{ marginRight: '-20px' }}
          />
        </Grid.Cell>
        <Grid.Cell>
          <PPAInsightDataView />
        </Grid.Cell>
      </Grid>
    </div>
  );
};
