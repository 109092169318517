import {
  Flexbox,
  FormField,
  Grid,
  Select,
  Sizes,
} from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, useState } from 'react';
import { ONE } from '../../../api/constants';

const year2023 = 2023;
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const currentYear = new Date().getFullYear();

const yearOptions = Array.from(
  { length: currentYear - year2023 + 1 },
  (_, index) => (year2023 + index).toString()
);

export const TimeLineOptions = ({
  setStartYear,
  setStartMonth,
  setEndYear,
  setEndMonth,
  startYear,
  startMonth,
  endYear,
  endMonth,
}: {
  setStartYear: Dispatch<string>;
  setStartMonth: Dispatch<string>;
  setEndYear: Dispatch<string>;
  setEndMonth: Dispatch<string>;
  startYear: string;
  startMonth: string;
  endYear: string;
  endMonth: string;
}) => {
  const [startMonthOptions, setStartMonthOptions] = useState<string[]>(months);
  const [endYearOptions, setEndYearOptions] = useState<string[]>(yearOptions);
  const [endMonthOptions, setEndMonthOptions] = useState<string[]>(months);

  const handleStartYearChange = (year: string) => {
    setStartYear(year);
    setStartMonth('');
    setEndYear('');
    setEndMonth('');
    const startYearInt = parseInt(year, 10);
    // Filter out years before the selected startYear
    const filteredYearOptions = yearOptions.filter(
      y => parseInt(y, 10) >= startYearInt
    );
    setEndYearOptions(filteredYearOptions);
    if (parseInt(year, 10) > parseInt(endYear, 10)) {
      setEndYear(year);
    }
    // Update startMonthOptions based on the selected startYear
    const currentMonthIndex = new Date().getMonth() + 1;
    // if startYear is current year, then startMonthOptions must have months till last month.
    const updatedMonthOptions =
      startYearInt === currentYear
        ? months.slice(0, currentMonthIndex - ONE)
        : months;
    setStartMonthOptions(updatedMonthOptions);
  };

  const handleStartMonthChange = (month: string) => {
    setStartMonth(month);
    setEndYear('');
    setEndMonth('');
  };

  const handleEndYearChange = (year: string) => {
    const currentYear = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();
    setEndYear(year);
    setEndMonth('');
    const endYearInt = parseInt(year, 10);
    const startMonthIndex = months.indexOf(startMonth);
    let updatedMonthOptions = months;
    // if startYear and endYear are equal and its current year, then end Month should start
    // from the startMonth but the month options should end till currentMonth running.
    if (startYear === year && endYearInt === currentYear) {
      updatedMonthOptions = months.slice(startMonthIndex, currentMonthIndex);
    } // if startYear and endYear are equal but not current year, then endMonth should
    // start from the startMonth selected.else if (startYear === endYear && endYearInt !== currentYear) {
    else if (startYear === year && endYearInt !== currentYear) {
      updatedMonthOptions = months.slice(startMonthIndex);
    } else if (startYear !== year) {
      updatedMonthOptions =
        endYearInt === currentYear
          ? months.slice(0, currentMonthIndex)
          : months;
    }
    setEndMonthOptions(updatedMonthOptions);
  };

  const handleEndMonthChange = (month: string) => {
    setEndMonth(month);
  };

  return (
    <div style={{ width: '30%' }}>
      <Flexbox gap="30px">
        <Grid
          gap="30px"
          flow={'row'}
          style={{ border: '1px solid #ccc', padding: '10px' }}
        >
          <FormField id="startyear" label="Start Year">
            <Select
              value={startYear}
              onChange={handleStartYearChange}
              size={Sizes.Small}
            >
              {yearOptions.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField id="startMonth" label="Start Month">
            <Select
              value={startMonth}
              onChange={handleStartMonthChange}
              disabled={startYear === ''}
              size={Sizes.Small}
            >
              {startMonthOptions.map(month => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </Select>
          </FormField>
        </Grid>
        <Grid
          gap="30px"
          flow={'row'}
          style={{ border: '1px solid #ccc', padding: '10px' }}
        >
          <FormField id="endyear" label="End Year">
            <Select
              value={endYear}
              onChange={handleEndYearChange}
              disabled={startMonth === ''}
              size={Sizes.Small}
            >
              {endYearOptions.map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField id="endMonth" label="End Month">
            <Select
              value={endMonth}
              onChange={handleEndMonthChange}
              disabled={endYear === ''}
              size={Sizes.Small}
            >
              {endMonthOptions.map(month => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </Select>
          </FormField>
        </Grid>
      </Flexbox>
    </div>
  );
};
