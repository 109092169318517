import React, { useEffect, useContext, useState } from 'react';
import {
  ChartContainer,
  TableHeading,
  ScrollableDiv,
} from './RegionVolumeDetailsTable.style';
import { getSnapshotRegionVolumeDetailsTableData } from '../../../../../../api/services';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { VolumeByRegionDetailsTableData } from '../../../../../../types/IAnalyticsSnapshotReportType';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../../Loader/Loader';

const RegionVolumeDetailsTable: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const region = queryParams.get('region');
  const parameterName = queryParams.get('parameterName');
  const { state: analyticsState, dispatch: snapshotDispatch } = useContext(
    AppContext
  ).analyticsSnapshotReport;

  const mapData: VolumeByRegionDetailsTableData[] =
    analyticsState.volumeByRegionDetailsTableData;
  const sum = mapData
    ?.reduce(
      (acc: number, item: VolumeByRegionDetailsTableData) => acc + item.QTY,
      0
    )
    ?.toFixed(2);
  const tableFormattedData = [...mapData].map(el => {
    return {
      ...el,
      QTY: Math.round(Number(el.QTY) * 100) / 100,
    };
  });

  const columnNames = [
    'YEAR',
    'QUARTER',
    'MONTH',
    'COMMODITY',
    'TRANSACTION_TYPE',
    'ORIGINAL_TRANSACTION_TYPE',
    'NCF_CATEGORY',
    'CPTY',
    'CPTY_DESC',
    'QTY',
  ];
  const columns = columnNames.map(key => ({
    header: key.replace(/_/g, ' '),
    accessorKey: key,
  }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let response;
      try {
        response = await getSnapshotRegionVolumeDetailsTableData({
          ...analyticsState.analyticsSnapshotReportFilteredValues,
          region: [region],
          parameterName,
        });
        // Use the snapshotDispatch function to call setNcfQuarterwise
        snapshotDispatch({
          type: 'setRegionVolumeDetailsTableData',
          payload: response.data,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // Log error once toast feature is implemented
        snapshotDispatch({
          type: 'setIsError',
          payload: {
            isError: true,
            message: 'Failed to fetch data. Please try again.',
          },
        });
        snapshotDispatch({
          type: 'setIsLoading',
          payload: false,
        });
      }
    };

    fetchData();
  }, [
    snapshotDispatch,
    analyticsState.analyticsSnapshotReportFilteredValues,
    region,
    parameterName,
  ]); // Add snapshotDispatch to the dependency array

  if (isLoading) {
    return <Loader loader={true} message={''} />;
  }
  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent="space-between">
        <TableHeading>Detail View of {parameterName}</TableHeading>
      </Flexbox>
      <ScrollableDiv>
        <BaseTable
          columns={columns}
          data={tableFormattedData}
          size="large"
          data-testid="base-table"
          style={{ width: '100%' }}
        />
        Total {sum}
      </ScrollableDiv>
    </ChartContainer>
  );
};

export default RegionVolumeDetailsTable;
