import React, { useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../Context/AppContext';
import { YEAR_2023 } from '../../../../api/constants';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';

export const PpaInsightsFilterSection = () => {
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } = useContext(
    AppContext
  ).ppaInsight;
  const {
    years,
    regions,
    renewableCategories,
    tenures,
    contractTypes,
    quarters,
  } = ppaInsightState.filterOptions;
  const [yearSelected, setYearSelected] = useState(YEAR_2023);
  const [regionsSelected, setRegionsSelected] = useState<string[]>([]);
  const [quartersSelected, setQuartersSelected] = useState<string[]>([]);
  const [renewableCategorySelected, setRenewableCategorySelected] = useState(
    ''
  );
  const [tenureSelected, setTenureSelected] = useState('');
  const [contractTypeSelected, setContractTypeSelected] = useState('');

  const handleClearFilters = () => {
    setYearSelected(YEAR_2023);
    setRegionsSelected([]);
    setQuartersSelected([]);
    setRenewableCategorySelected('');
    setTenureSelected('');
    setContractTypeSelected('');
    ppaInsightDispatch({
      type: 'setFilteredValues',
      payload: {
        year: YEAR_2023,
        region: '',
        quarter: '',
        renewableCategory: '',
        tenure: '',
        contractType: '',
      },
    });
  };

  interface IppaPayload {
    year: number;
    renewableCategory: string;
    tenure: string;
    contractType: string;
    region?: string[];
    quarter?: string[];
  }

  const handleApplyFilters = () => {
    const payload: Partial<IppaPayload> = {
      year: yearSelected,
      renewableCategory: renewableCategorySelected,
      tenure: tenureSelected,
      contractType: contractTypeSelected,
    };
    if (
      regionsSelected.length > 0 &&
      !arraysHaveSameValues(regionsSelected, regions)
    ) {
      payload.region = regionsSelected;
    }
    if (
      quartersSelected.length > 0 &&
      !arraysHaveSameValues(quartersSelected, quarters)
    ) {
      payload.quarter = quartersSelected;
    }
    ppaInsightDispatch({ type: 'setFilteredValues', payload });
  };

  const arraysHaveSameValues = (arr1: string | any[], arr2: string | any[]) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = [...arr1].sort((a, b) => a.localeCompare(b));
    const sortedArr2 = [...arr2].sort((a, b) => a.localeCompare(b));

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  };

  return (
    <div data-testid="filter" style={{ padding: '20px' }}>
      <Flexbox flexDirection="column">
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
          }}
        >
          Filters
        </div>
        <SelectFormField
          id="year"
          label="Year"
          testId="Year"
          value={yearSelected}
          onChange={setYearSelected}
          options={years}
        />
        <SelectFormField
          id="quarter"
          label="Quarter"
          testId="Quarter"
          value={quartersSelected}
          onChange={setQuartersSelected}
          options={quarters}
          mode="multiple"
        />
        <SelectFormField
          id="region"
          label="Region"
          testId="Region"
          value={regionsSelected}
          onChange={setRegionsSelected}
          options={regions}
          mode="multiple"
        />
        <SelectFormField
          id="renewableCategory"
          label="Renewable Category"
          testId="Renewable Category"
          value={renewableCategorySelected}
          onChange={setRenewableCategorySelected}
          options={renewableCategories}
        />
        <SelectFormField
          id="tenure"
          label="Tenure"
          testId="Tenure"
          value={tenureSelected}
          onChange={setTenureSelected}
          options={tenures}
        />
        <SelectFormField
          id="contractType"
          label="Contract Type"
          testId="Contract Type"
          value={contractTypeSelected}
          onChange={setContractTypeSelected}
          options={contractTypes}
        />
        <Button
          className="getDataBtn"
          data-testid="Apply Filters"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          className="getDataBtn"
          variant="outlined"
          style={{ marginTop: '10px' }}
          data-testid="Clear Filters"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
