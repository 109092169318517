import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { counterPartyFilter } from '../../../../api/services';
import { AppContext } from '../../../../Context/AppContext';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';

export const TopCtpyFilterSection = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } = useContext(
    AppContext
  ).analyticsHome;
  const filterData = analyticsState.cptyFilterOptions;

  const [yearSelected, setYearSelected] = useState('2024');
  const [quarterSelected, setQuarterSelected] = useState('');
  const [regionSelected, setRegionSelected] = useState('');
  const [transactionTypeSelected, setTransactionTypeSelected] = useState('');
  const [unitNameSelected, setUnitNameSelected] = useState('TWH');
  const [commoditySelected, setCommoditySelected] = useState('');
  const [ncfScopeSelected, setNcfScopeSelected] = useState(''); // New state for ncfScopes

  useEffect(() => {
    const fetchData = async () => {
      const response = await counterPartyFilter();
      analyticsDispatch({
        type: 'setCptyFilterOptions',
        payload: response.data,
      });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {
    const payload = {
      year: yearSelected ? [yearSelected.toString()] : [],
      quarter: quarterSelected || [],
      region: regionSelected || [],
      transactionType: transactionTypeSelected || [],
      uom: unitNameSelected || '',
      commodity: commoditySelected ? [commoditySelected] : [],
      ncfScope: ncfScopeSelected || [], // Include ncfScope in payload
    };

    analyticsDispatch({ type: 'setCptyFilteredValues', payload });
  };

  const handleClearFilters = () => {
    setYearSelected('2024');
    setQuarterSelected('');
    setRegionSelected('');
    setTransactionTypeSelected('');
    setUnitNameSelected('TWH');
    setCommoditySelected('');
    setNcfScopeSelected(''); //

    const payload = {
      year: ['2024'],
      quarter: [],
      region: [],
      transactionType: [],
      commodity: [],
      ncfScope: [],
      uom: 'TWH',
    };

    analyticsDispatch({ type: 'setCptyFilteredValues', payload });
  };

  return (
    <div data-testid="filter" style={{ padding: '20px' }}>
      <Flexbox flexDirection="column">
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        <SelectFormField
          id="year"
          label="Year"
          testId="Year"
          value={yearSelected}
          onChange={setYearSelected}
          options={filterData.year}
        />
        <SelectFormField
          id="quarter"
          label="Quarter"
          testId="Quarter"
          value={quarterSelected}
          onChange={setQuarterSelected}
          options={filterData.quarter}
          mode="multiple"
        />
        <SelectFormField
          id="region"
          label="Region"
          testId="Region"
          value={regionSelected}
          onChange={setRegionSelected}
          options={filterData.region}
          mode="multiple"
        />
        <SelectFormField
          id="unitName"
          label="Unit Name"
          testId="Unit Name"
          value={unitNameSelected}
          onChange={setUnitNameSelected}
          options={filterData.unitName}
        />
        <SelectFormField
          id="commodity"
          label="Commodity"
          testId="Commodity"
          value={commoditySelected}
          onChange={setCommoditySelected}
          options={filterData.commodity.map(
            (el: { TRADE_NAME: string; TRADE_CODE: string }) => {
              return {
                label: el.TRADE_NAME,
                value: el.TRADE_CODE,
              };
            }
          )}
        />
        <SelectFormField
          id="transactionType"
          label="TransactionType"
          testId="TransactionType"
          value={transactionTypeSelected}
          onChange={setTransactionTypeSelected}
          options={filterData.transactionType.map((el: string) => {
            return {
              label: el,
              value: el,
            };
          })}
          mode="multiple"
        />
        <SelectFormField
          id="ncfScope"
          label="NCF Scope"
          testId="NCF Scope"
          value={ncfScopeSelected}
          onChange={setNcfScopeSelected}
          options={filterData.ncfScope}
          mode="multiple"
        />
        <Button
          className="getDataBtn"
          data-testid="Apply Filters"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          className="getDataBtn"
          variant="outlined"
          style={{ marginTop: '10px' }}
          data-testid="Clear Filters"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
