import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { filterData as fetchFilterData } from '../../../../api/services';
import { AppContext } from '../../../../Context/AppContext';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';

export const PptFilterSection = () => {
  const { state: analyticsState, dispatch: analyticsDispatch } = useContext(
    AppContext
  ).analyticsHome;

  const { dispatch: pptDispatch } = useContext(AppContext).pptReports;

  let filterData = analyticsState.filterOptions;

  if (filterData.year) {
    filterData = {
      ...filterData,
      year: filterData.year.map((el: { toString: () => string }) =>
        el.toString()
      ),
    };
  }
  // Define local state for each filter field
  const [yearSelected, setYearSelected] = useState('2024');
  const [quarterSelected, setQuarterSelected] = useState('');
  const [monthSelected, setMonthSelected] = useState('');
  const [regionSelected, setRegionSelected] = useState<string[]>([]);
  const [countrySelected, setCountrySelected] = useState('');
  const [unitNameSelected, setUnitNameSelected] = useState('TWH');
  const [countryOptions, setCountryOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [reportStatusSelected, setReportStatusSelected] = useState<string[]>(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFilterData();
      analyticsDispatch({ type: 'setFilterOptions', payload: response.data });
      // Set default values after data has been fetched
      if (response.data.region.length > 0) {
        setYearSelected(response.data.year[0].toString());

        setRegionSelected(['AMERICAS']);

        // Set countries based on the "AMERICAS" region
        const region = 'AMERICAS';
        const countryOptionsData =
          response.data.regionCountryMapping[region]?.map(
            (el: { COUNTRY_NAME: string; COUNTRY_CODE: string }) => ({
              label: el.COUNTRY_NAME,
              value: el.COUNTRY_CODE,
            })
          ) || [];
        setCountryOptions(countryOptionsData);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {
    const payload = {
      year: yearSelected ? [yearSelected] : [],
      quarter: quarterSelected || [],
      month: monthSelected || [],
      region: ['AMERICAS'],
      countryCode: countrySelected || [],
      commodity: ['PW', 'NG'],
      uom: unitNameSelected,
      // ncfType: ncfTypeSelected,
      // transactionType: [transactionTypeSelected],
      reportStatus: reportStatusSelected, // Include report status in the payload
    };

    pptDispatch({ type: 'setPptFilteredValues', payload });
  };

  const handleClearFilters = () => {
    // Reset local state
    setYearSelected('2024');
    setQuarterSelected('');
    setMonthSelected('');
    setCountrySelected('');
    setUnitNameSelected('TWH');
    setReportStatusSelected([]); // Reset report status

    // Create a payload with the initial state for each field
    const payload = {
      year: ['2024'],
      quarter: [],
      month: [],
      region: ['AMERICAS'],
      countryCode: [],
      commodity: [],
      uom: 'TWH',
      transactionType: ['NCF'],
      reportStatus: [], // Include report status in the initial payload
    };

    // Dispatch the action to update the state in your context
    pptDispatch({ type: 'setPptFilteredValues', payload });
  };

  const handleRegionChange = (e: string[]) => {
    setCountrySelected('');
    setRegionSelected(e);
    let cntryptions: React.SetStateAction<{
      label: string;
      value: string;
    }[]> = [];
    if (Array.isArray(e)) {
      cntryptions = [
        ...new Set(
          e.flatMap(region =>
            filterData.regionCountryMapping[region]?.map(el => {
              return {
                label: el.COUNTRY_NAME,
                value: el.COUNTRY_CODE,
              };
            })
          )
        ),
      ];
    }
    if (typeof e === 'string' && e !== '') {
      cntryptions = filterData.regionCountryMapping[e].map(el => {
        return {
          label: el.COUNTRY_NAME,
          value: el.COUNTRY_CODE,
        };
      });
    }
    setCountryOptions(cntryptions);
  };

  return (
    <div data-testid="filter" style={{ padding: '20px' }}>
      <Flexbox flexDirection="column">
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        <SelectFormField
          id="year"
          label="Year"
          testId="Year"
          value={yearSelected}
          onChange={setYearSelected}
          options={filterData.year.map((el: { toString: () => string }) =>
            el.toString()
          )}
          mode="single"
        />

        <SelectFormField
          id="quarter"
          label="Quarter"
          testId="Quarter"
          value={quarterSelected}
          onChange={setQuarterSelected}
          options={filterData.quarter}
          mode="multiple"
        />

        <SelectFormField
          id="region"
          label="Region"
          testId="Region"
          value={regionSelected}
          onChange={(e: string[]) => handleRegionChange(e)}
          options={['AMERICAS']}
          disabled={true}
        />
        <SelectFormField
          id="country"
          label="Country"
          testId="Country"
          value={countrySelected}
          onChange={setCountrySelected}
          options={countryOptions}
          mode="multiple"
        />
        <SelectFormField
          id="unitName"
          label="Unit Name"
          testId="Unit Name"
          value={unitNameSelected}
          onChange={setUnitNameSelected}
          options={filterData.unitName}
        />

        <SelectFormField
          id="reportStatus"
          label="Report Status"
          testId="reportStatus"
          value={reportStatusSelected} // This state should hold the selected report statuses
          onChange={setReportStatusSelected} // This function updates the state with selected report statuses
          options={[
            {
              label: 'Submitted for Approval',
              value: 'SUBMITTED_FOR_APPROVAL',
            },
            {
              label: 'Re-Submitted for Approval',
              value: 'RE_SUBMITTED_FOR_APPROVAL',
            },
            { label: 'Approved', value: 'APPROVED' },
          ]}
          mode="multiple" // Allows multiple selections
        />

        <Button
          className="getDataBtn"
          data-testid="Apply Filters"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          className="getDataBtn"
          variant="outlined"
          style={{ marginTop: '10px' }}
          data-testid="Clear Filters"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
