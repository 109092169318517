import React, { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '../../../../Context/AppContext';
import { BiaTable } from './BiaTable';
import { fetchUncatCptyData } from './BiaUtils';
import { BiaTableContainer } from './BiaReports.style';

export const UncategorisedCptyView = () => {
  const { state, dispatch } = useContext(AppContext).biaReports;
  const isBiaRegionEuropeAmericas = useMemo(() => {
    return (
      state.biaFilterData.biaRegion === 'europe' ||
      state.biaFilterData.biaRegion === 'americas'
    );
  }, [state.biaFilterData.biaRegion]);
  useEffect(() => {
    fetchUncatCptyData(isBiaRegionEuropeAmericas, state, dispatch);
  }, [dispatch, state.biaFilterData]);
  const description = 'List of counterparties that have not been categorised.';
  return (
    <BiaTableContainer>
      <BiaTable
        description={description}
        isLoading={state.isUncatCptyDataLoading}
        loadingText="Uncategorized Counterparty"
        biaData={
          isBiaRegionEuropeAmericas
            ? state.biaUncatCptyEuropeAmericasData
            : state.biaUncatCptyAsiaAustraliaData
        }
      />
    </BiaTableContainer>
  );
};
