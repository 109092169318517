/* eslint-disable no-unused-vars */
import {
  RadioButton,
  Select,
  Option,
  SingleDatePicker,
} from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import {
  BUY_SLASH_SELL,
  CHANGE_ATTRIBUTE,
  CHANGE_TO,
  COUNTERPARTY_SHORT_NAME,
  END_DATE,
  I_ALIGNE_TABLE,
  MARKET,
  RULE_CONFIG_ID,
  START_DATE,
  STATUS,
  STORAGE,
  TRADE_TYPE,
  EIGHTY,
  THOUSAND,
  THREE,
  CHANGE_FROM,
} from '../../../api/constants';
import tableEditOptions from '../../../api/TableEditOptions';
import debounce from 'lodash/debounce';
import {
  fetchCptySearchResult,
  fetchMarketSearchResult,
} from '../../../api/services';
import dayjs, { Dayjs } from 'dayjs';
import {
  areStringsEqual,
  decorateColName,
  formatDate,
} from '../../Utils/utils';
import '../tableHeadingStyle.css';
import {
  RadioButtonSelected,
  Cross,
} from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import {
  IEditFeatureData,
  ITableColumn,
  TConfigRow,
} from '../../../types/types';

const changeAttributeMasterValue = { value: '', label: '' };

type TOptions = { value: string; label: string };
export const configRuleTableCols = (
  cols: string[],
  isAllFieldsValidated: boolean,
  setIsAllFieldsValidated: (value: boolean) => void,
  isAddNewRecord: boolean,
  newCptyShortName: string,
  setNewCptyShortName: (name: string) => void,
  configRulePaginationData: string[],
  setConfigRulePaginationData: Dispatch<SetStateAction<string[]>>,
  setIsAddNewRecord: (value: boolean) => void,
  isSearchingCpty: boolean,
  setIsSearchingCpty: (value: boolean) => void,
  cptyOptions: string[],
  setCptyOptions: (value: []) => void,
  tradeTypeOptions: TOptions[],
  newTradeType: string,
  setNewTradeType: (value: string) => void,
  newMarket: string,
  setNewMarket: (value: string) => void,
  newStorage: string,
  setNewStorage: (value: string) => void,
  storageOptions: TOptions[],
  setNewStartDate: (value: string) => void,
  newStartDate: string,
  setNewEndDate: (value: string) => void,
  newChangeAttribute: string,
  setNewChangeAttribute: (value: string) => void,
  newChangeFrom: string,
  setNewChangeFrom: (value: string) => void,
  newChangeTo: string,
  setNewChangeTo: (value: string) => void,
  buySellOption: TOptions[],
  marketSearchOptions: string[],
  setMarketSearchOptions: (value: []) => void,
  isSearchingMarket: boolean,
  setIsSearchingMarket: (value: boolean) => void,
  setIsShowMessage: (value: boolean) => void,
  setIsValAndSaveBtnClickedWithoutErrors: (value: boolean) => void,
  setIsValidationError: (value: boolean) => void,
  showRadioButton: boolean,
  radioChecked: number,
  setRadioChecked: (value: number) => void,
  state: IEditFeatureData
) => {
  const colObj: ITableColumn[] = [];
  const d = new Date();
  const threeYearBack = d.getFullYear() - THREE;
  d.setFullYear(threeYearBack);
  // start date can be upto 3 years back, only 1st of every month can be selected and future dates disabled
  const disabledFutureDatesForStartDate = (current: Dayjs) => {
    const maxDate = dayjs()
      .subtract(THREE, 'year')
      .startOf('month');
    return (
      current &&
      (!current.startOf('month').isSame(current, 'date') ||
        current.isBefore(maxDate, 'date') ||
        current.isAfter(dayjs().endOf('month')))
    );
  };
  // end date can be any date after the start date and future dates are enabled to choose.
  const disabledFutureAndPrevDatesForEndDate = (current: Dayjs) => {
    return current ? current.isBefore(newStartDate, 'day') : false;
  };

  const handleCancelNewRecord = () => {
    const data = [...configRulePaginationData];
    data.shift();
    setConfigRulePaginationData(data);
    setIsAddNewRecord(false);
    setIsValAndSaveBtnClickedWithoutErrors(false);
    setNewCptyShortName('');
    setNewTradeType('');
    setNewMarket('');
    setNewChangeAttribute('');
    setNewChangeFrom('');
    setNewChangeTo('');
    setIsAllFieldsValidated(false);
    setIsValidationError(false);
  };

  const getStatusVal = (status: string) => {
    // Earlier backend was sending 0,1,-1. But on the day of go live it was changed to direct message. Hence returning the status as it is.
    if (status === '0' || status === null) {
      return 'Rule Initiated';
    }
    if (status === '1') {
      return 'Rule applied successfully';
    }
    if (status === '-1') {
      return 'Rule unsuccessful';
    } else {
      return status;
    }
  };

  const getTradeVal = (trade: string) => {
    if (trade === 'PW') {
      return 'Power';
    } else if (trade === 'NG') {
      return 'Natural Gas';
    } else {
      return trade;
    }
  };

  const handleOnRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioChecked(Number(e.target.value));
  };

  const defaultRenderColumns = (
    colObjFunction: ITableColumn[],
    val: string
  ) => {
    let width = 100;
    if (
      areStringsEqual(val, RULE_CONFIG_ID) ||
      areStringsEqual(val, TRADE_TYPE) ||
      areStringsEqual(val, MARKET) ||
      areStringsEqual(val, STATUS)
    ) {
      width = EIGHTY;
    }
    if (showRadioButton && colObjFunction.length === 0) {
      colObjFunction.push({
        key: val,
        width: 60,
        className: 'reportPublishTableCols',
        title: (
          <div>
            <RadioButtonSelected width={20} />
          </div>
        ),
        render: (_value: string, row: TConfigRow): ReactElement => (
          <RadioButton
            name="T1"
            checked={radioChecked === row[RULE_CONFIG_ID]}
            onChange={handleOnRadioChange}
            value={row[RULE_CONFIG_ID]}
          />
        ),
      });
    }
    colObjFunction.push({
      dataIndex: val,
      key: val,
      width,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string): ReactElement => {
        let element;
        if (areStringsEqual(val, STATUS)) {
          element = <>{getStatusVal(value)}</>;
        } else if (areStringsEqual(val, TRADE_TYPE)) {
          element = <>{getTradeVal(value)}</>;
        } else {
          element = <>{value}</>;
        }
        return element;
      },
    });
  };

  const onCptySearch = debounce(async (query: string) => {
    const options = {
      tableName: I_ALIGNE_TABLE,
      keyword: query.toUpperCase(),
    };
    if (query.length > 1) {
      try {
        setIsSearchingCpty(true);
        const cptyDescSearch = await fetchCptySearchResult(options);
        const cptySearchResult = cptyDescSearch.data;
        setCptyOptions(cptySearchResult);
      } catch (e) {
        //error statement
      }
      setIsSearchingCpty(false);
    }
  }, THOUSAND);

  const resetMarketSearchResult = (
    marketSearchResult: string[],
    columnName: string
  ) => {
    //remove existing market value from the option list
    if (columnName !== undefined && areStringsEqual(columnName, CHANGE_TO)) {
      marketSearchResult.splice(marketSearchResult.indexOf(newChangeFrom), 1);
    }
  };

  const onMarketSearch = debounce(async (query: string, columnName: string) => {
    const options = {
      tableName: I_ALIGNE_TABLE,
      keyword: query.toUpperCase(),
    };
    if (query.length > 1) {
      try {
        setIsSearchingMarket(true);
        const marketSearch = await fetchMarketSearchResult(options);
        const marketSearchResult = marketSearch.data;
        resetMarketSearchResult(marketSearchResult, columnName);
        setMarketSearchOptions(marketSearchResult);
      } catch (e) {
        //error statement
      }
      setIsSearchingMarket(false);
    }
  }, THOUSAND);

  const handleChangeAttribute = async (e: string) => {
    setNewChangeAttribute(e);
    setNewChangeFrom('');
    setNewChangeTo('');
  };

  const setChangeFromOptions = () => {
    const currMarketVal = newMarket;
    let finalOptions: TOptions[] = [];
    if (areStringsEqual(newChangeAttribute, BUY_SLASH_SELL)) {
      finalOptions = [...buySellOption];
    }
    if (newChangeAttribute === MARKET) {
      finalOptions = [{ value: currMarketVal, label: currMarketVal }];
    }
    return finalOptions;
  };

  // setChangeToOptions function is called when BUY_SLASH_SELL is selected under Change Attribute Column
  const setChangeToOptions = () => {
    const currentBuySellOption = [...buySellOption];
    let option;
    if (newChangeFrom === buySellOption[0].value) {
      option = currentBuySellOption.slice(1);
    }
    if (newChangeFrom === buySellOption[1].value) {
      option = currentBuySellOption.slice(0, 1);
    }
    return option;
  };

  const setChangeFromValueOnEdit = () => {
    if (newChangeAttribute === MARKET) {
      setNewChangeFrom(setChangeFromOptions()[0].value);
      return setChangeFromOptions()[0].value; // set default market value to the value of Market column
    } else {
      setNewChangeFrom(newChangeFrom);
      return newChangeFrom;
    }
  };

  const setCellForLastModifiedDateInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <>{formatDate(new Date())}</>
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForRuleConfigIdInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      width: 90,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Cross onClick={handleCancelNewRecord} style={{ height: 30 }} />
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForCptyShortNameInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      width: 180,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            size="medium"
            allowClear={false}
            placeholder="Enter min 2 characters"
            value={newCptyShortName}
            onSearch={onCptySearch}
            id="counterPartyId"
            disabled={isAllFieldsValidated}
            loading={isSearchingCpty}
            onChange={(e: string) => {
              setNewCptyShortName(e);
            }}
            autoClearSearchValue={false}
            onMouseEnter={() => setIsShowMessage(true)}
            onMouseLeave={() => setIsShowMessage(false)}
          >
            {cptyOptions.map((cptyOptionval: string) => {
              return (
                <Option key={cptyOptionval} value={cptyOptionval}>
                  {cptyOptionval}
                </Option>
              );
            })}
          </Select>
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForTradeTypeInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      width: 150,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            value={newTradeType}
            size="medium"
            aria-label="Select"
            allowClear={false}
            options={tradeTypeOptions}
            disabled={isAllFieldsValidated}
            onChange={(e: string) => setNewTradeType(e)}
          />
        ) : (
          <>{getTradeVal(value)}</>
        );
      },
    });
  };

  const setCellForMarketInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      width: 160,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            size="medium"
            allowClear={false}
            placeholder="Enter min 2 characters"
            value={newMarket}
            onSearch={(query: string) => onMarketSearch(query, val)}
            loading={isSearchingMarket}
            disabled={isAllFieldsValidated}
            onMouseEnter={() => setIsShowMessage(true)}
            onMouseLeave={() => setIsShowMessage(false)}
            onChange={(e: string) => {
              setNewMarket(e);
              setNewChangeFrom('');
              setNewChangeTo('');
              setNewChangeAttribute('');
            }}
            autoClearSearchValue={false}
          >
            {marketSearchOptions.map((marketOptionVal: string) => {
              return (
                <Option key={marketOptionVal} value={marketOptionVal}>
                  {marketOptionVal}
                </Option>
              );
            })}
          </Select>
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForStorageInNewRecord = (valStorage: string) => {
    colObj.push({
      dataIndex: valStorage,
      key: valStorage,
      width: 150,
      title: <div>{decorateColName(valStorage)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            value={newStorage}
            size="medium"
            aria-label="Select"
            allowClear={false}
            options={storageOptions}
            disabled={isAllFieldsValidated}
            onChange={(e: string) => setNewStorage(e)}
          />
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForStartDateInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      width: 150,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <SingleDatePicker
            placeholder="DD MMM YYYY"
            disabledDate={disabledFutureDatesForStartDate}
            disabled={isAllFieldsValidated}
            onChange={(e: any) => {
              const dateStr = formatDate(e.$d);
              const parts = dateStr.split('/');
              const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
              setNewStartDate(formattedDate);
            }}
            elevation={true}
          />
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForEndDateInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      width: 150,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <SingleDatePicker
            placeholder="DD MMM YYYY"
            disabledDate={disabledFutureAndPrevDatesForEndDate}
            disabled={isAllFieldsValidated}
            onChange={(e: any) => setNewEndDate(formatDate(e.$d))}
            elevation={true}
          />
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForChangeAttributeInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            value={newChangeAttribute}
            defaultValue={
              tableEditOptions.configRuleChangeAttributeOptions[0].value
            }
            size="medium"
            aria-label="Select"
            allowClear={false}
            options={tableEditOptions.configRuleChangeAttributeOptions}
            onChange={(e: string) => handleChangeAttribute(e)}
            disabled={newMarket === '' || isAllFieldsValidated} //enable the field after user picks up a value for Market column
          />
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForChangeFrom = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,

      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <Select
            value={setChangeFromValueOnEdit()}
            size="medium"
            aria-label="Select"
            allowClear={false}
            options={setChangeFromOptions()}
            onChange={(e: string) => {
              setNewChangeFrom(e);
              setNewChangeTo('');
            }}
            disabled={
              newChangeAttribute === '' ||
              newChangeAttribute === MARKET ||
              isAllFieldsValidated
            }
          />
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellForChangeToInNewRecord = (val: string) => {
    if (newChangeAttribute === MARKET) {
      // implement searching for market
      colObj.push({
        dataIndex: val,
        key: val,
        width: 130,
        title: <div>{decorateColName(val)}</div>,
        className: 'reportPublishTableCols',
        render: (value: string, row: TConfigRow): ReactElement => {
          return row[RULE_CONFIG_ID] === -1 ? (
            <Select
              value={newChangeTo}
              size="small"
              aria-label="Select"
              allowClear={false}
              onSearch={(query: string) => onMarketSearch(query, val)}
              loading={isSearchingMarket}
              onMouseEnter={() => setIsShowMessage(true)}
              onMouseLeave={() => setIsShowMessage(false)}
              onChange={(e: string) => setNewChangeTo(e)}
              disabled={
                isAllFieldsValidated ||
                (areStringsEqual(newChangeAttribute, BUY_SLASH_SELL) &&
                  Object.values(buySellOption).includes(
                    changeAttributeMasterValue
                  ))
              }
            >
              {marketSearchOptions.map((marketOptionVal: string) => {
                return (
                  <Option key={marketOptionVal} value={marketOptionVal}>
                    {marketOptionVal}
                  </Option>
                );
              })}
            </Select>
          ) : (
            <>{value}</>
          );
        },
      });
    } else {
      colObj.push({
        dataIndex: val,
        key: val,
        title: <div>{decorateColName(val)}</div>,
        className: 'reportPublishTableCols',
        render: (value: string, row: TConfigRow): ReactElement => {
          return row[RULE_CONFIG_ID] === -1 ? (
            <Select
              value={newChangeTo}
              size="medium"
              aria-label="Select"
              allowClear={false}
              options={setChangeToOptions()}
              onChange={(e: string) => setNewChangeTo(e)}
              disabled={newChangeFrom === '' || isAllFieldsValidated}
            />
          ) : (
            <>{value}</>
          );
        },
      });
    }
  };

  const setCellForCreatedByOrUpdatedByInNewRecord = (val: string) => {
    colObj.push({
      dataIndex: val,
      key: val,
      title: <div>{decorateColName(val)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? <>{state.mail}</> : <>{value}</>;
      },
    });
  };

  const setCellForInsertedDateInNewRecord = (cellVal: string) => {
    colObj.push({
      dataIndex: cellVal,
      key: cellVal,
      title: <div>{decorateColName(cellVal)}</div>,
      className: 'reportPublishTableCols',
      render: (value: string, row: TConfigRow): ReactElement => {
        return row[RULE_CONFIG_ID] === -1 ? (
          <>{formatDate(new Date())}</>
        ) : (
          <>{value}</>
        );
      },
    });
  };

  const setCellsForNewRecord = (val: string) => {
    const isColRuleConfigId = areStringsEqual(val, RULE_CONFIG_ID);
    const isColCptyShortName = areStringsEqual(val, COUNTERPARTY_SHORT_NAME);
    const isColTradeType = areStringsEqual(val, TRADE_TYPE);
    const isColMarket = areStringsEqual(val, MARKET);
    const isColStorage = areStringsEqual(val, STORAGE);
    const isColStartDate = areStringsEqual(val, START_DATE);
    const isColEndDate = areStringsEqual(val, END_DATE);
    const isColChangeAttribute = areStringsEqual(val, CHANGE_ATTRIBUTE);
    const isColChangeFrom = areStringsEqual(val, CHANGE_FROM);
    const isColChangeTo = areStringsEqual(val, CHANGE_TO);
    const isColCreatedByOrUpdatedBy =
      areStringsEqual(val, 'CREATED_BY') || areStringsEqual(val, 'UPDATED_BY');
    const isColInsertedDate = areStringsEqual(val, 'INSERTED_DATE');
    const isColLastModifiedDate = areStringsEqual(val, 'LAST_MODIFIED_DATE');
    switch (true) {
      case isColRuleConfigId:
        setCellForRuleConfigIdInNewRecord(val);
        break;
      case isColCptyShortName:
        setCellForCptyShortNameInNewRecord(val);
        break;
      case isColTradeType:
        setCellForTradeTypeInNewRecord(val);
        break;
      case isColMarket:
        setCellForMarketInNewRecord(val);
        break;
      case isColStorage:
        setCellForStorageInNewRecord(val);
        break;
      case isColStartDate:
        setCellForStartDateInNewRecord(val);
        break;
      case isColEndDate:
        setCellForEndDateInNewRecord(val);
        break;
      case isColChangeAttribute:
        setCellForChangeAttributeInNewRecord(val);
        break;
      case isColChangeFrom:
        setCellForChangeFrom(val);
        break;
      case isColChangeTo:
        setCellForChangeToInNewRecord(val);
        break;
      case isColCreatedByOrUpdatedBy:
        setCellForCreatedByOrUpdatedByInNewRecord(val);
        break;
      case isColInsertedDate:
        setCellForInsertedDateInNewRecord(val);
        break;
      case isColLastModifiedDate:
        setCellForLastModifiedDateInNewRecord(val);
        break;
      default:
        defaultRenderColumns(colObj, val);
        break;
    }
  };

  cols.forEach((val: string) => {
    if (isAddNewRecord) {
      setCellsForNewRecord(val);
    } else {
      defaultRenderColumns(colObj, val);
    }
  });
  return colObj;
};
