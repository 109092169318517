import React, { useEffect, useState, useContext } from 'react';

import {
  ChartContainer,
  TableHeading,
  NcfToggle,
  ScrollableDiv,
  ChartFooterText,
} from './RegionVolumeChart.style';
import {
  generateSeries,
  getCommodityName,
  fetchDataBasedOnView,
  generateColumns,
  WHOLESALE_CHURN_PARAMETER_NAME,
  OUT_OF_SCOPE_VOLUME_PARAMETER_NAME,
} from './RegionVolumeChartUtils';
import Chart from '../../../Chart/Chart';
import {
  BaseTable,
  Flexbox,
  Grid,
  Button,
} from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { SingleRegionVolumeTableData } from '../../../../../../types/types';
import ChartToggle from '../../../../../ReUsableComponents/PPAToggle/PPAToggle';
import Loader from '../../../../../Loader/Loader';
import { Link } from 'react-router-dom';
interface VolumeChartProps {
  region: string;
}

const VolumeChart: React.FC<VolumeChartProps> = ({ region }) => {
  const [view, setView] = useState('Chart');
  const [selectedParameter, setSelectedParameter] = useState('');
  const { state: analyticsState, dispatch: analyticsDispatch } = useContext(
    AppContext
  ).analyticsHome;
  const { state: ciTradeAndVolumeState } = useContext(AppContext).analyticsHome;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetchDataBasedOnView(
          view,
          analyticsState,
          region
        );
        if (response && view === 'Chart') {
          analyticsDispatch({
            type: 'setVolumeByRegionChartData',
            payload: {
              [region]: response.data,
            },
          });
        } else {
          analyticsDispatch({
            type: 'setVolumeByRegionTableData',
            payload: {
              [region]: response?.data,
            },
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // Log error once toast feature is implemented
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, analyticsDispatch, analyticsState.filteredValues]);

  const seriesData = generateSeries(
    ciTradeAndVolumeState.volumeByRegionChartData[region],
    selectedParameter
  );

  const tabledata: SingleRegionVolumeTableData[] =
    ciTradeAndVolumeState.volumeByRegionTableData[region];

  const onSelectWaterfallBar = (parameter: string) => {
    setSelectedParameter(parameter);
  };

  const options = {
    chart: {
      type: 'waterfall',
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        text: `Quantity(${analyticsState?.filteredValues?.uom})`,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: false,
    },
    series: seriesData,
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click: (event: { point: { name: string } }) => {
            const parameter = event.point.name;
            if (selectedParameter === parameter) {
              onSelectWaterfallBar('');
            } else {
              onSelectWaterfallBar(parameter);
            }
          },
        },
        lineWidth: 0,
      },
    },
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader loader={true} message={''} />;
    } else if (view === 'Table') {
      return (
        <ScrollableDiv>
          <BaseTable
            columns={generateColumns(
              ciTradeAndVolumeState.volumeByRegionChartData[region]
            )}
            data={tabledata}
            size="large"
            data-testid="base-table"
          />
        </ScrollableDiv>
      );
    } else {
      const dbParameterName =
        selectedParameter === WHOLESALE_CHURN_PARAMETER_NAME
          ? OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
          : selectedParameter;
      return (
        <Grid gap="10px">
          <Grid.Cell>
            <Chart options={options} footer={'Source'} />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox
              justifyContent="space-between"
              alignItems="center"
              style={{ minHeight: '30px' }}
            >
              <ChartFooterText>
                Select a parameter to drill through
              </ChartFooterText>
              <Button
                disabled={!dbParameterName}
                variant="outlined"
                size="small"
              >
                <Link
                  to={`/region-volume-details?region=${region}&parameterName=${dbParameterName}`}
                  style={{ textDecoration: 'none' }}
                >
                  View Details
                </Link>
              </Button>
            </Flexbox>
          </Grid.Cell>
        </Grid>
      );
    }
  };

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent="space-between">
        <TableHeading>
          {getCommodityName(analyticsState?.filteredValues?.commodity[0])}{' '}
          Volume For {region}
        </TableHeading>
        <NcfToggle>
          <ChartToggle setView={setView} />
        </NcfToggle>
      </Flexbox>
      {renderContent()}
    </ChartContainer>
  );
};

export default VolumeChart;
