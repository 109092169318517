import React, { useContext, useEffect, useState } from 'react'; // Step 1: Import useState
import { Grid, Flexbox, Divider } from '@sede-x/shell-ds-react-framework';
import 'leaflet/dist/leaflet.css';
import { PptFilterSection } from './PptFilterSection';
import { AppContext } from '../../../../Context/AppContext';
import NcfReportingHomeHeader from '../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';
import PptReportingBarChart from './PptReportingCharts/PptReportingBarChart/PptReportingBarChart';
import { SnapshotFilterSection } from './PptReportingCharts/PptReportingSnpShtFltr/PptReportingSnpShtFltr';
import { PptInScopeNcfCatgChart } from './PptReportingCharts/PptReportingPieChart/PptInScopeNcfCatg';
import { PptInScopeVsOutScope } from './PptReportingCharts/PptReportingPieChart/PptInScopeVsOutScope';
import {
  pptNcfCategory,
  pptNcfVolume,
  pptReports,
} from '../../../../api/services';
import Loader from '../../../Loader/Loader';

export const PptReportingHome = () => {
  const { dispatch: appDataDispatch } = useContext(AppContext).appData;
  const { state: pptState, dispatch: pptDispatch } = useContext(
    AppContext
  ).pptReports;
  const [isLoading, setIsLoading] = useState(true); // Step 3: Initialize isLoading state

  useEffect(() => {
    appDataDispatch({ type: 'setCurrentPage', payload: '' });
  }, [appDataDispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Step 4: Set isLoading to true at the start
      try {
        const volumeResponse = await pptNcfVolume(pptState.PptfilteredValues);
        const categoryResponse = await pptNcfCategory(
          pptState.PptfilteredValues
        );
        const reportsResponse = await pptReports(pptState.PptfilteredValues);

        pptDispatch({ type: 'setPptNcfVolumes', payload: volumeResponse.data });
        pptDispatch({
          type: 'setPptNcfCategories',
          payload: categoryResponse.data,
        });
        pptDispatch({
          type: 'setPptReportNames',
          payload: reportsResponse.data,
        });
      } catch (error) {
        // Log error once toast feature is implemented
      } finally {
        setIsLoading(false); // Step 4: Set isLoading to false after fetching data
      }
    };

    fetchData();
  }, [pptDispatch, pptState.PptfilteredValues]);

  return (
    <div
      style={{ height: '100%', background: '#F5F5F5' }}
      data-testid="ppt-reporting"
    >
      {isLoading && <Loader loader={true} message={'Loading...'} />}

      <Flexbox
        gap="24px 24px"
        style={{ marginLeft: '15px', justifyContent: 'start' }}
      >
        <PptFilterSection />
        <Grid gap="10px">
          <Grid.Cell>
            <Flexbox justifyContent="space-between">
              <NcfReportingHomeHeader
                title="PPT Reporting"
                description="Detailed PPT Report"
              />
            </Flexbox>
          </Grid.Cell>
          <Divider size="medium" direction="horizontal" />
          <Grid.Cell>
            <SnapshotFilterSection />
          </Grid.Cell>
          <Grid.Cell>
            <PptReportingBarChart />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap="10px">
              <PptInScopeVsOutScope tradeName="Natural Gas" />
              <PptInScopeVsOutScope tradeName="Power" />
            </Flexbox>
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap="10px">
              <PptInScopeNcfCatgChart tradeName="Natural Gas" />
              <PptInScopeNcfCatgChart tradeName="Power" />
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </Flexbox>
    </div>
  );
};
