import React, { useContext } from 'react';
import '../style.module.css';
import './CustomGlobalHeader.css';
import { GlobalHeader } from '@sede-x/glass-design-library';
import { AppContext } from '../../Context/AppContext';
import {
  CONFIG_RULE_MENU,
  MASTER_DATA_RECORDS,
  NCF_SNAPSHOT_MENU,
  PPA_MENU,
  SECTORAL_MENU,
} from '../../api/constants';
import appInsights from './../../appInsights';
import Feedback from '../Feedback/Feedback';
import { IMenuItem } from '../../types/types';
import Logout from '../Logout/Logout';
import { Menu, ButtonLink } from '@sede-x/shell-ds-react-framework';
import { Link } from 'react-router-dom';

export const CustomGlobalHeader = ({ logout }: { logout: () => void }) => {
  const { state } = useContext(AppContext).appData;
  //console statement to display state and props
  let routerMenuItems: IMenuItem[] = [
    {
      key: '0',
      label: MASTER_DATA_RECORDS,
      children: [
        {
          key: '0-1',
          label: 'View/Edit Report',
          path: '/viewEditReport',
        },
        {
          key: '0-2',
          label: 'View Audit Report',
          path: '/viewauditreport',
        },
        {
          key: '0-3',
          label: CONFIG_RULE_MENU,
          path: '/configurerules',
        },
      ],
    },
    {
      key: '1',
      label: SECTORAL_MENU,
      path: '/sectoral',
    },
    {
      key: '2',
      label: NCF_SNAPSHOT_MENU,
      path: '/publishreport',
    },
    {
      key: '3',
      label: PPA_MENU,
      path: '/ppa-insights',
    },
    {
      key: '4',
      label: 'Power BI',
      path: '/powerBi',
    },
  ];

  //hide config rule menu
  if (!state.showConfigRulePage) {
    const masterDataRecord = routerMenuItems.find(
      item => item.label === MASTER_DATA_RECORDS
    );
    if (masterDataRecord?.children) {
      const index = masterDataRecord?.children.findIndex(
        item => item.label === CONFIG_RULE_MENU
      );
      if (index !== -1) {
        masterDataRecord?.children.splice(index, 1);
      }
    }
  }

  //hide secotral page
  if (!state.showSectoralpage) {
    const index = routerMenuItems.findIndex(
      item => item.label === SECTORAL_MENU
    );
    if (index !== -1) {
      routerMenuItems.splice(index, 1);
    }
  }

  if (state.isPpaUser) {
    routerMenuItems = routerMenuItems.filter(item => item.label === PPA_MENU);
  }
  const handleLinkClick = () => {
    // eslint-disable-next-line no-undef
    window.open(process.env.REACT_APP_POWER_BI_URL, '_blank');
  };

  const TabMenu = () => (
    <Menu
      data-testid="menu_items"
      className="tabMenu"
      mode={'horizontal'}
      items={routerMenuItems}
      itemRender={({ path, children }) =>
        path === '/powerBi' ? (
          <ButtonLink onClick={handleLinkClick}>{children}</ButtonLink>
        ) : (
          <Link to={path}>{children}</Link>
        )
      }
    />
  );

  const logoutHandler = () => {
    appInsights.trackEvent({
      name: 'User logged out',
      properties: {
        email: state.mail,
        username: state.username,
        role: state.role,
      },
    });
    localStorage.removeItem('isAlreadyLoggedInStorage');
    logout();
  };

  return (
    <div className="header-global" data-testid="custom_global_header">
      <GlobalHeader
        hideGlassHub
        avatar={{
          title: state.username.toUpperCase(),
          description: state.mail,
          dropdownOverlay: <Logout logoutHandler={logoutHandler} />,
        }}
        subtitle="Tanso"
        title="NCF Data Dashboard"
        centerComponent={<TabMenu />}
        rightComponent={<Feedback />}
        data-testid="global_header"
      />
    </div>
  );
};
