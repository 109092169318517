import {
  Alert,
  Button,
  Flexbox,
  FormField,
  Grid,
  Link,
  Modal,
  Option,
  Pagination,
  Select,
  DeprecatedTable,
  Sentiments,
  ButtonLink,
} from '@sede-x/shell-ds-react-framework';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../Context/AppContext';
import '../../style.module.css';
import debounce from 'lodash/debounce';
import {
  addConfigRule,
  fetchCommodities,
  fetchConfigRuleReport,
  fetchCptySearchResult,
  fetchDealType,
  fetchStorage,
} from '../../../api/services';
import Loader from '../../Loader/Loader';
import filterOptions from '../../../api/filterOptions';
import { configRuleTableCols } from '../../TableData/ConfigRuleTableCols/configRuleTableCols';
import mockOptions from '../../../api/TableEditOptions';
import {
  CONFIGURE_PAGE_DESCRIPTION_1,
  CONFIGURE_PAGE_DESCRIPTION_2,
  I_ALIGNE_TABLE,
  THOUSAND,
  THOUSAND_FIVE_HUNDRED,
} from '../../../api/constants';
import { InternalServerErrorPage } from '../InternalServerErrorPage/InternalServerErrorPage';
import { useNavigate } from 'react-router-dom';
import { showSpinner, hideSpinner } from '../../Utils/utils';
import { ConfirmDeleteRule } from './ConfirmDeleteRule';
import { IConfigRuleNewRecord, RuleConfigDataI } from '../../../types/types';
import { deleteConfigRule } from '../../../api/services';
import ArrowCw from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components/ArrowCw';

const Container = styled.div`
  width: auto;
  height: auto;
`;

const regionOptions = filterOptions.regionForSnapshotAndConfigureRuleOptions;

export const ConfigureRulesPage = () => {
  const { state, dispatch } = useContext(AppContext).appData;
  // table name is fixed to be I Align Table
  const tableName = I_ALIGNE_TABLE;

  const [totalCount, setTotalCount] = useState(0);
  const [configRulePaginationData, setConfigRulePaginationData] = useState<
    any[]
  >([]);
  const [isAddNewRecord, setIsAddNewRecord] = useState(false);
  const [checkedID, setCheckedID] = useState(-1);

  const [newCptyShortName, setNewCptyShortName] = useState('');
  const [newTradeType, setNewTradeType] = useState('');
  const [newMarket, setNewMarket] = useState('');
  const [newStorage, setNewStorage] = useState('');
  const [newStartDate, setNewStartDate] = useState('');
  const [newEndDate, setNewEndDate] = useState('');
  const [newChangeAttribute, setNewChangeAttribute] = useState('');
  const [newChangeFrom, setNewChangeFrom] = useState('');
  const [newChangeTo, setNewChangeTo] = useState('');
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isServErrOnEditClick, setIisServErrOnEditClick] = useState(false);
  const [isPrevAndNewMarketSame, setIsPrevAndNewMarketSame] = useState(false);
  const [isAllFieldsValidated, setIsAllFieldsValidated] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [
    isValAndSaveBtnClickedWithoutErrors,
    setIsValAndSaveBtnClickedWithoutErrors,
  ] = useState(false);
  const [isServiceError, setIsServiceError] = useState(false);
  const [isSearchingCpty, setIsSearchingCpty] = useState(false);
  const [isSearchingMarket, setIsSearchingMarket] = useState(false);
  const [cptyOptions, setCptyOptions] = useState<string[]>([]);
  const [storageOptions, setStorageOptions] = useState<
    { value: string; label: string }[]
  >([{ value: 'SELECT', label: 'SELECT' }]);
  const [buySellOption, setBuySellOption] = useState<
    { value: string; label: string }[]
  >([{ value: 'SELECT', label: 'SELECT' }]);
  const [tradeTypeOptions, setTradeTypeOptions] = useState<
    { value: string; label: string }[]
  >([{ value: 'SELECT', label: 'SELECT' }]);

  const [marketSearchOptions, setMarketSearchOptions] = useState<string[]>([]);

  const [newRecord, setNewRecord] = useState<IConfigRuleNewRecord>({
    region: '',
    tableName: '',
    counterpartyShortName: '',
    tradeType: '',
    market: '',
    storage: '',
    startDate: '',
    endDate: '',
    changeAttribute: '',
    changeFrom: '',
    changeTo: '',
    createdBy: '',
    updatedBy: '',
  });

  const [isPublishFailure, setIsPublishFailure] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const [showDeleteRuleFilters, setShowDeleteRuleFilters] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showRadioButton, setShowRadioButton] = useState(false);
  const [radioChecked, setRadioChecked] = useState(-1);
  const [cptyForDelete, setCptyForDelete] = useState('');
  const [tradeTypeForDelete, setTradeTypeForDelete] = useState('');
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: 'Configure Rules Page' });
    updateConfigRulePaginationData(1);
    fetchAllTradeTypes();
  }, []);

  const fetchAllTradeTypes = async () => {
    const tradeTypeOptns = [];
    const tradeTypesResp = await fetchCommodities(tableName);
    const tradeTypeOptionsArray = tradeTypesResp.data;
    for (const tradeOption of tradeTypeOptionsArray) {
      tradeTypeOptns.push({
        value: setTradeTypeVal(tradeOption),
        label: setTradeTypeVal(tradeOption),
      });
    }
    setTradeTypeOptions(tradeTypeOptns);
  };

  let cols: string[] = [];
  if (configRulePaginationData.length > 0) {
    cols = Object.keys(configRulePaginationData[0]);
  }

  const updateConfigRulePaginationData = async (
    current: number,
    tradeTypeForDel?: string,
    cptyDescForDelete?: string
  ): Promise<void> => {
    //console statement - updating table
    showSpinner(dispatch);
    setLoadingMessage(
      'Sit tight while we are fetching configuration rules for you.'
    );
    let delTradeType = '';
    if (tradeTypeForDel) {
      delTradeType = tradeTypeForDel;
    } else if (tradeTypeForDelete) {
      delTradeType = tradeTypeForDelete.toUpperCase() === 'POWER' ? 'PW' : 'NG';
    }
    const delCptyDesc = cptyDescForDelete ?? cptyForDelete ?? '';
    const options: RuleConfigDataI = {
      pageNumber: current,
      pageSize: 5,
      tradeType: delTradeType,
      cptyShortName: delCptyDesc,
    };
    setPaginationNumber(current);
    try {
      const viewConfigRuleData = await fetchConfigRuleReport(options);
      setConfigRulePaginationData(viewConfigRuleData.data.data);
      setTotalCount(viewConfigRuleData.data.count);
      hideSpinner(dispatch);
    } catch (e) {
      hideSpinner(dispatch);
      setIsServiceError(true);
    }
  };

  const setTradeTypeVal = (tradeVal: string) => {
    if (tradeVal.toUpperCase() === 'PW') {
      return 'Power';
    } else if (tradeVal.toUpperCase() === 'NG') {
      return 'Natural Gas';
    } else {
      return tradeVal;
    }
  };

  const handleAddNewRecord = async () => {
    const data = [...configRulePaginationData];
    setIsAddNewRecord(true);
    showSpinner(dispatch);
    setLoadingMessage('Let us get the options for you to add new rule.');
    // api calls to retrieve drop down values for counter party short name, trade type, market, storage.
    try {
      const storageOption = [];
      const buySellOptions = [];

      const storageResp = await fetchStorage(tableName);
      const buySellResp = await fetchDealType(tableName);

      const storageOptns = storageResp.data;
      const buySellOptns = buySellResp.data;

      for (const storageOptionData of storageOptns) {
        storageOption.push({
          value: storageOptionData,
          label: storageOptionData,
        });
      }
      for (const buySellData of buySellOptns) {
        buySellOptions.push({
          value: buySellData.toUpperCase(),
          label: buySellData.toUpperCase(),
        });
      }

      setStorageOptions(storageOption);
      setBuySellOption(buySellOptions);
    } catch {
      //error statement
    }
    hideSpinner(dispatch);
    const newRow = mockOptions.ruleConfigNewRecordObject;
    data.unshift(newRow);
    setConfigRulePaginationData(data);
  };

  const handleDeleteRule = () => {
    setShowDeleteRuleFilters(true);
    setShowRadioButton(true);
  };

  const handleValAndSaveBtnClick = () => {
    const isAnyFieldEmpty = [
      newCptyShortName,
      newTradeType,
      newMarket,
      newStorage,
      newStartDate,
      newEndDate,
      newChangeAttribute,
      newChangeFrom,
      newChangeTo,
    ].some(val => val === '');
    const isAddNewRecordInvalid = isAddNewRecord && isAnyFieldEmpty;
    const isBothMarketEqual = newMarket === newChangeTo;

    if (isAddNewRecordInvalid || isBothMarketEqual) {
      setIsValidationError(true);
      setIsValAndSaveBtnClickedWithoutErrors(false);
      setIsAllFieldsValidated(false);
      if (isBothMarketEqual) {
        setIsPrevAndNewMarketSame(true);
      } else {
        setIsPrevAndNewMarketSame(false);
      }
    } else {
      setIsValidationError(false);
      setIsValAndSaveBtnClickedWithoutErrors(true);
      const finalNewTradeType =
        newTradeType.toLowerCase() === 'power' ? 'PW' : 'NG';
      const newData: IConfigRuleNewRecord = {
        region: 'EU',
        tableName: I_ALIGNE_TABLE,
        counterpartyShortName: newCptyShortName,
        tradeType: finalNewTradeType,
        market: newMarket,
        storage: newStorage,
        startDate: newStartDate,
        endDate: newEndDate,
        changeAttribute: newChangeAttribute,
        changeFrom: newChangeFrom,
        changeTo: newChangeTo,
        createdBy: state.mail,
        updatedBy: state.mail,
      };
      setNewRecord(newData);
      setIsAllFieldsValidated(true);
    }
  };

  const resetStates = () => {
    // set all states to default values
    setNewCptyShortName('');
    setNewTradeType('');
    setNewMarket('');
    setNewStorage('');
    setNewChangeAttribute('');
    setNewChangeFrom('');
    setNewChangeTo('');
    setCheckedID(-1);
    setMarketSearchOptions([]);
    setCptyOptions([]);
    setIsValidationError(false);
    setIsValAndSaveBtnClickedWithoutErrors(false);
  };

  const handlePublishDataBtnClick = async () => {
    showSpinner(dispatch);
    setLoadingMessage('It takes little while to save a new data.');
    try {
      await addConfigRule(newRecord);
      setIsAddNewRecord(false);
      setIsPublished(true);
      resetStates();
      updateConfigRulePaginationData(1);
    } catch {
      setIsPublishFailure(true);
    }
    hideSpinner(dispatch);
    setIsAllFieldsValidated(false);
  };

  const setValAndSaveDisabled = () => {
    if (isAddNewRecord) {
      return isValAndSaveBtnClickedWithoutErrors;
    } else {
      return true;
    }
  };

  const setPublishBtnDisabled = () => {
    if (isAddNewRecord && isValAndSaveBtnClickedWithoutErrors) {
      return false;
    } else if (checkedID > 0 && isValAndSaveBtnClickedWithoutErrors) {
      return false;
    } else {
      return true;
    }
  };

  const innerOnClose = () => {
    if (isPublishFailure) {
      setIsPublishFailure(false);
    }
    if (isPublished) {
      setIsPublished(false);
    }
    if (isServErrOnEditClick) {
      setIisServErrOnEditClick(false);
    }
  };

  const isShowPagination = () => {
    return configRulePaginationData.length > 0 && !isAddNewRecord;
  };

  const handleApplyFilter = () => {
    updateConfigRulePaginationData(1);
    setRadioChecked(-1);
  };

  const onCptySearch = debounce(async (query: any) => {
    const options = {
      tableName: I_ALIGNE_TABLE,
      keyword: query.toUpperCase(),
    };
    if (query.length > 1) {
      try {
        setIsSearchingCpty(true);
        const cptyDescSearch = await fetchCptySearchResult(options);
        const cptySearchResult = cptyDescSearch.data;
        setCptyOptions(cptySearchResult);
      } catch (e) {
        //error statement
      }
      setIsSearchingCpty(false);
    }
  }, THOUSAND);

  const handleCancelDeleteFlow = () => {
    setShowDeleteRuleFilters(false);
    setShowRadioButton(false);
    setTradeTypeForDelete('');
    setCptyForDelete('');
    setRadioChecked(-1);
    updateConfigRulePaginationData(1, '', '');
  };

  const handleFinalDelete = async () => {
    showSpinner(dispatch);
    setLoadingMessage('Deleting Rule');
    try {
      await deleteConfigRule(radioChecked);
      setIsDeleteSuccess(true);
      setTimeout(() => {
        setIsDeleteSuccess(false);
      }, THOUSAND_FIVE_HUNDRED);
      handleCancelDeleteFlow();
      setShowConfirmDeleteModal(false);
      updateConfigRulePaginationData(1);
    } catch (e) {
      //console error
    }
    hideSpinner(dispatch);
  };

  return (
    <div data-testid="configure-rule-page">
      <Grid
        gap="15px"
        areas={['description', 'actions', 'deleteFilters', 'message', 'table']}
      >
        <Grid.Cell area="description">
          <p>{CONFIGURE_PAGE_DESCRIPTION_1}</p>
          <p>{CONFIGURE_PAGE_DESCRIPTION_2}</p>
          <br />
          <p>
            Should changes be required to the data on row wise, then the{' '}
            <Link>
              <ButtonLink onClick={() => navigate('/viewEditReport')}>
                Data Edit
              </ButtonLink>
            </Link>{' '}
            feature in Tanso can be used.
          </p>
        </Grid.Cell>
        <Modal
          title="Rule successfully deleted."
          open={isDeleteSuccess}
          onClose={() => setIsDeleteSuccess(false)}
          showFooter={false}
        ></Modal>
        {!isServiceError && (
          <Grid.Cell area="actions">
            <Flexbox justifyContent="flex-end" gap="20px 20px">
              <Flexbox gap="10px 10px" style={styles.firstContent}>
                <ConfirmDeleteRule
                  showConfirmDeleteModal={showConfirmDeleteModal}
                  setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                  handleFinalDelete={handleFinalDelete}
                />
                <FormField label="Region" style={{ width: 200 }}>
                  <Select
                    size="large"
                    allowClear={false}
                    options={regionOptions}
                    defaultValue={regionOptions[0].value}
                    disabled={true}
                  />
                </FormField>
                <Button
                  variant="outlined"
                  style={styles.refreshBtn}
                  iconOnly={true}
                  size="large"
                  icon={<ArrowCw />}
                  disabled={isAddNewRecord}
                  onClick={() =>
                    updateConfigRulePaginationData(paginationNumber)
                  }
                />
                {state.canUserManipulateData && (
                  <Button
                    variant="outlined"
                    size="large"
                    style={styles.addNewRecordBtn}
                    onClick={handleAddNewRecord}
                    disabled={isAddNewRecord || showDeleteRuleFilters}
                  >
                    Add New Rule
                  </Button>
                )}
                {state.canUserManipulateData && (
                  <Button
                    variant="outlined"
                    size="large"
                    style={styles.addNewRecordBtn}
                    onClick={handleDeleteRule}
                    disabled={isAddNewRecord || showDeleteRuleFilters}
                  >
                    Delete Rule
                  </Button>
                )}
              </Flexbox>
              {state.canUserManipulateData && !showDeleteRuleFilters && (
                <Button
                  style={styles.valAndSaveBtn}
                  onClick={handleValAndSaveBtnClick}
                  disabled={setValAndSaveDisabled()}
                >
                  Validate Changes
                </Button>
              )}
              {state.canUserManipulateData && !showDeleteRuleFilters && (
                <Button
                  style={styles.publishDataBtn}
                  disabled={setPublishBtnDisabled()}
                  onClick={handlePublishDataBtnClick}
                >
                  Publish New/Modified Data
                </Button>
              )}
              {showDeleteRuleFilters && (
                <Button
                  style={styles.publishDataBtn}
                  onClick={() => setShowConfirmDeleteModal(true)}
                  disabled={radioChecked === -1}
                >
                  Confirm Delete
                </Button>
              )}
            </Flexbox>
          </Grid.Cell>
        )}
        {showDeleteRuleFilters && (
          <Grid.Cell area="deleteFilters">
            <Flexbox gap="10px 10px" style={styles.firstContent}>
              <FormField label="Counter Party" style={{ width: 200 }}>
                <Select
                  size="large"
                  allowClear={false}
                  placeholder="Enter min 2 characters"
                  value={cptyForDelete}
                  onSearch={onCptySearch}
                  loading={isSearchingCpty}
                  onChange={(e: any) => {
                    setCptyForDelete(e);
                  }}
                  autoClearSearchValue={false}
                  onMouseEnter={() => setIsShowMessage(true)}
                  onMouseLeave={() => setIsShowMessage(false)}
                >
                  {cptyOptions.map((val: any) => {
                    return (
                      <Option key={val} value={val}>
                        {val}
                      </Option>
                    );
                  })}
                </Select>
              </FormField>
              <FormField label="Trade Type" style={{ width: 200 }}>
                <Select
                  value={tradeTypeForDelete}
                  size="large"
                  aria-label="Select"
                  allowClear={false}
                  options={tradeTypeOptions}
                  onChange={(e: any) => setTradeTypeForDelete(e)}
                />
              </FormField>
              <Button
                variant="outlined"
                size="large"
                style={styles.addNewRecordBtn}
                onClick={handleApplyFilter}
              >
                Apply Filters
              </Button>
              <Button
                variant="outlined"
                size="large"
                style={styles.addNewRecordBtn}
                onClick={handleCancelDeleteFlow}
              >
                Cancel Delete
              </Button>
            </Flexbox>
          </Grid.Cell>
        )}
        <Grid.Cell area="message">
          <Alert
            sentiment={Sentiments.Information}
            style={{ visibility: isShowMessage ? 'visible' : 'hidden' }}
          >
            Enter min 2 characters to initiate searching for fields COUNTER
            PARTY SHORT NAME and MARKET
          </Alert>
          {isValidationError && (
            <Alert sentiment={Sentiments.Negative}>
              {isPrevAndNewMarketSame
                ? 'Change From and To for Market values can not be identical.'
                : 'All fields are mandatory.'}
            </Alert>
          )}
        </Grid.Cell>
        <Grid.Cell area="table">
          <Modal
            title="Rule successfully published."
            open={isPublished}
            onClose={() => innerOnClose()}
          ></Modal>
          <Modal
            title="We are facing trouble fetching options for editing. Please retry."
            open={isServErrOnEditClick}
            onClose={() => innerOnClose()}
          ></Modal>
          <Modal
            title="Unable to publish your changes. Please try again after some time."
            open={isPublishFailure}
            onClose={() => innerOnClose()}
          ></Modal>
          <div>
            <Flexbox gap="24px 24px" justifyContent="center">
              <Grid rowGap="24px">
                <Container>
                  {state.spinner && <Loader message={loadingMessage} />}
                  {isServiceError && <InternalServerErrorPage />}
                  {!isServiceError && (
                    <DeprecatedTable
                      data-testid="sds-table"
                      size="small"
                      data={configRulePaginationData}
                      rowKey={(record: { [x: string]: any }) =>
                        record['RULE_CONFIG_ID']
                      }
                      columns={configRuleTableCols(
                        cols,
                        isAllFieldsValidated,
                        setIsAllFieldsValidated,
                        isAddNewRecord,
                        newCptyShortName,
                        setNewCptyShortName,
                        configRulePaginationData,
                        setConfigRulePaginationData,
                        setIsAddNewRecord,
                        isSearchingCpty,
                        setIsSearchingCpty,
                        cptyOptions,
                        setCptyOptions,
                        tradeTypeOptions,
                        newTradeType,
                        setNewTradeType,
                        newMarket,
                        setNewMarket,
                        newStorage,
                        setNewStorage,
                        storageOptions,
                        setNewStartDate,
                        newStartDate,
                        setNewEndDate,
                        newChangeAttribute,
                        setNewChangeAttribute,
                        newChangeFrom,
                        setNewChangeFrom,
                        newChangeTo,
                        setNewChangeTo,
                        buySellOption,
                        marketSearchOptions,
                        setMarketSearchOptions,
                        isSearchingMarket,
                        setIsSearchingMarket,
                        setIsShowMessage,
                        setIsValAndSaveBtnClickedWithoutErrors,
                        setIsValidationError,
                        showRadioButton,
                        radioChecked,
                        setRadioChecked,
                        state
                      )}
                      tableLayout="fixed"
                      wrapperStyle={{ border: '2px solid rgba(0,0,0,0.15)' }}
                    />
                  )}
                </Container>
                {isShowPagination() && (
                  <Pagination
                    data-testid="pagination"
                    total={totalCount}
                    pageSize={5}
                    onChange={(pageNumber: number) =>
                      updateConfigRulePaginationData(pageNumber)
                    }
                  />
                )}
              </Grid>
            </Flexbox>
          </div>
        </Grid.Cell>
      </Grid>
    </div>
  );
};

const styles = {
  firstContent: {
    marginRight: 'auto',
    marginTop: '12px',
  },
  refreshBtn: {
    margin: '24px 5px',
  },
  addNewRecordBtn: {
    margin: '24px 5px',
    height: '48px',
  },
  editRecordBtn: {
    margin: '24px 5px',
    height: '48px',
  },
  valAndSaveBtn: {
    margin: '34px 5px',
    height: '50px',
  },
  publishDataBtn: {
    margin: '34px 5px',
    height: '50px',
  },
};
