import { BaseTable } from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import { ColumnDef, getPaginationRowModel } from '@tanstack/react-table';

export interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

export const BiaReportTable = ({ biaData }: { biaData: any }) => {
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 4,
  });
  let columns:
    | { header: string; accessorKey: string }[]
    | ColumnDef<unknown, string>[] = [];
  if (biaData.length > 0) {
    const firstRow = biaData[0];
    const keys = Object.keys(firstRow);
    columns = keys.map(key => ({
      header: key.toUpperCase().replace(/_/g, ' '),
      accessorKey: key,
      cell: info => {
        const value = info.getValue();
        // Check if the header matches the condition and apply color based on the value
        if (info.column.id === '(1)-(2)') {
          const numericValue = Number(value);
          return (
            <div style={{ color: numericValue === 0 ? '#74C365' : 'red' }}>
              {value}
            </div>
          );
        }
        // For other cells, just return the value
        return value;
      },
    }));
  }
  const handlePaginationChange = async (
    newPaginationState: React.SetStateAction<PaginationState>
  ) => {
    setPaginationState(newPaginationState);
  };

  const { pageSize, pageIndex } = paginationState;
  const tableOptions = {
    state: {
      pagination: { pageSize, pageIndex },
    },
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: handlePaginationChange,
  };
  return (
    <BaseTable
      columns={columns}
      data={biaData}
      size="large"
      width={'100%'}
      data-testid="base-table"
      tableOptions={tableOptions}
      style={{ border: '1px solid #C0C0C0' }}
    />
  );
};
