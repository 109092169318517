// ReusableFileUploader.jsx
import {
  FileUploader,
  Sizes,
  FileType,
  Alert,
  Sentiments,
  OnProgress,
  OnError,
} from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { INVALID } from '../../../api/constants';

interface ReusableFileUploaderProps {
  fileId: string;
  onDrop: (files: File[], onProgress: OnProgress, onError: OnError) => void;
  onUpload: (files: File[]) => void; // Change the type according to your onUpload function signature
  onRemoveFile: () => void;
  uploadState: string; // Change the type according to your uploadState type
  uploadFailureMessage: string; // Change the type according to your uploadFailureMessage type
}

const ReusableFileUploader: React.FC<ReusableFileUploaderProps> = ({
  fileId,
  onDrop,
  onUpload,
  onRemoveFile,
  uploadState,
  uploadFailureMessage,
}) => {
  const messages = uploadFailureMessage
    .split('.')
    .map(message => message.trim());
  return (
    <>
      <FileUploader
        id={fileId}
        size={Sizes.Small}
        fileType={FileType.Single}
        acceptedFileTypes={['.csv', '.xlsx', '.xls']}
        dropFilesAction={(files, onProgress, onError) => {
          onDrop(files, onProgress, onError);
          onUpload(files);
        }}
        removeFileAction={() => onRemoveFile()}
        style={{ marginLeft: '50px', width: '90%', height: '200px' }}
      />
      {uploadState === INVALID && (
        <Alert
          sentiment={Sentiments.Negative}
          style={{ marginLeft: '50px', width: '90%', marginTop: '20px' }}
          elevation
        >
          {uploadState} :{' '}
          {messages.length === 1 ? (
            uploadFailureMessage // Display as is if single statement
          ) : (
            <ul>
              {messages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          )}
        </Alert>
      )}
    </>
  );
};

export default ReusableFileUploader;
