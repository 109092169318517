import React, { useContext, useEffect, useState } from 'react';
import {
  Flexbox,
  Button,
  Grid,
  DeprecatedTable,
  Pagination,
  Modal,
  Variants,
  Divider,
} from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { NoTableData } from '../../TableData/NoTableData/NoTableData';
import { AppContext } from '../../../Context/AppContext';
import { editAndAuditReportTableCols } from '../../TableData/EditAndAuditReportTableCols/editAndAuditReportTableCols';
import {
  QTY,
  DEAL_QUANTITY,
  MARKET,
  NCF_CATEGORY,
  BUYSELL,
  COMMENTS,
  ID,
  SCOPE,
  ENDUR_SEAM_REGION_SCOPE_TABLE,
  NUCLEUS_SEAM_REGION_SCOPE_TABLE,
  COUNTRY_NAME,
  VOLUME,
  NCF_SCOPE,
  ENDURAU_STATIC_MAP_NCF,
} from '../../../api/constants';
import Loader from '../../Loader/Loader';
import '../../style.module.css';
import {
  innerOnClose,
  handleEdit,
  handleValAndSave,
  handlePublish,
  handleGetNewRecord,
  handleCancelNewRecord,
  EmptyComponent,
  handleConfirmNewRecord,
  handleOnChange,
  getColumnNames,
} from './ViewEditReportFunctions';
import { PageDescription } from './VEReportComponents/PageDescription';
import { InfoModals } from './VEReportComponents/InfoModals';
import { DiffView } from './VEReportComponents/DiffView';
import { TableAlert } from './VEReportComponents/TableAlerts';
import FilterSection from '../../FilterSection/FilterSection';
import appInsights from './../../../appInsights';

const Container = styled.div`
  width: auto;
  height: auto;
`;

const ViewEditReportPage = () => {
  //current mode
  const [isEdit, setIsEdit] = useState(false);
  const [isEditError, setIsEditError] = useState(false);
  const [isReadyToPublish, setIsReadyToPublish] = useState(false);
  const [isDirtyData, setIsDirtyData] = useState(true);
  const [isServiceFailure, setIsServiceFailure] = useState(false);
  const [isPublishTableFailure, setIsPublishTableFailure] = useState(false);
  const [errorTableData, setErrorTableData] = useState<string[]>([]);
  //current button
  const [isShowEditBtn, setIsShowEditBtn] = useState(true);
  const [isShowPublishBtn, setIsShowPublishBtn] = useState(false);
  const [looseChanges, setLooseChanges] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [
    isQtyExceedThreeWholeDigits,
    setIsQtyExceedThreeWholeDigits,
  ] = useState(false);
  const [updatedCol, setUpdatedCol] = useState('');
  const [originalVal, setOriginalVal] = useState('');
  const [updatedVal, setUpdatedVal] = useState('');
  const [
    isShowDeploymentToPowerBiMessage,
    setIsShowDeploymentToPowerBiMessage,
  ] = useState(false);
  const [
    regionOptionsForAmericasSeamRegionTable,
    setRegionOptionsForAmericasSeamRegionTable,
  ] = useState<{ label: string; value: string }[]>([]);
  const [
    ncfCatOptionsForAsiaJapanTable,
    setNcfCatOptionsForAsiaJapanTable,
  ] = useState<{ label: string; value: string }[]>([]);

  const { state, dispatch } = useContext(AppContext).appData;

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: 'View/Edit Report Page' });
  }, []);

  useEffect(() => {
    const isAlreadyLoggedInStorage = 'isAlreadyLoggedInStorage';
    const value: string | null = localStorage.getItem(isAlreadyLoggedInStorage);
    if (!value || value !== 'true') {
      appInsights.trackEvent({
        name: 'User logged in',
        properties: {
          email: state?.mail,
          username: state?.username,
          role: state?.role,
        },
      });
      localStorage.setItem(isAlreadyLoggedInStorage, 'true');
    }
  }, []);

  //Assigning Column Names
  let cols: string[] = [];
  if (state.paginationData.length > 0) {
    cols = Object.keys(state.paginationData[0]);
  }

  const isScopeTableSelected = [
    ENDUR_SEAM_REGION_SCOPE_TABLE,
    NUCLEUS_SEAM_REGION_SCOPE_TABLE,
    ENDURAU_STATIC_MAP_NCF,
  ].includes(state.tableSelected);

  const columns = [
    ID,
    QTY,
    DEAL_QUANTITY,
    MARKET,
    NCF_CATEGORY,
    BUYSELL,
    COMMENTS,
    SCOPE,
    COUNTRY_NAME,
    VOLUME,
    NCF_SCOPE,
  ];
  const colNames = getColumnNames(columns, cols);
  const {
    ID1,
    QTY1,
    DEAL_QUANTITY1,
    MARKET1,
    NCF_CATEGORY1,
    BUYSELL1,
    COMMENTS1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
  } = colNames;
  const currColumns = [
    ID1,
    QTY1,
    DEAL_QUANTITY1,
    MARKET1,
    NCF_CATEGORY1,
    BUYSELL1,
    COMMENTS1,
    SCOPE1,
    VOLUME1,
    NCF_SCOPE1,
  ];
  return (
    <div data-testid="viewEditReportpage">
      <Grid gap="24px" areas={['description', 'filter', 'table']}>
        <PageDescription />
        <Grid.Cell area="filter">
          <FilterSection view={'report'} />
          <Divider
            size="medium"
            direction="horizontal"
            color="medium"
            style={{ marginTop: '10px' }}
          />
        </Grid.Cell>
        <Grid.Cell area="table">
          <InfoModals
            isPublished={isPublished}
            isPublishTableFailure={isPublishTableFailure}
            isServiceFailure={isServiceFailure}
            setIsServiceFailure={setIsServiceFailure}
            setIsPublishTableFailure={setIsPublishTableFailure}
          />

          {!state.showReport && <NoTableData />}

          {state.showReport && (
            <div>
              <Flexbox
                data-testid="edit-buttons"
                gap="20px 20px"
                style={{ marginBottom: '5px' }}
                justifyContent="flex-end"
              >
                {state.canUserManipulateData && (
                  <DiffView
                    updatedCol={updatedCol}
                    originalVal={originalVal}
                    updatedVal={updatedVal}
                  />
                )}

                {state.canUserManipulateData && (
                  <Button
                    className="editReportPageActionBtns"
                    disabled={state.paginationData.length < 1 || !isShowEditBtn}
                    onClick={() => {
                      const handleEditOptions = {
                        setNcfCatOptionsForAsiaJapanTable,
                        setRegionOptionsForAmericasSeamRegionTable,
                        setIsEdit,
                        setIsShowEditBtn,
                        state,
                        dispatch,
                        setIsServiceFailure,
                        cols,
                      };
                      handleEdit(handleEditOptions);
                    }}
                  >
                    Edit Data
                  </Button>
                )}

                {state.canUserManipulateData && (
                  <Button
                    className="editReportPageActionBtns"
                    disabled={
                      isShowPublishBtn
                        ? isShowPublishBtn
                        : state.dirtyData.length === 0
                    }
                    onClick={() => {
                      const handleValAndSaveOptions = {
                        state,
                        isEdit,
                        isReadyToPublish,
                        ID1,
                        setIsDirtyData,
                        setErrorTableData,
                        isDirtyData,
                        COMMENTS1,
                        setIsReadyToPublish,
                        dispatch,
                        setIsEditError,
                        setIsEdit,
                        setIsShowPublishBtn,
                      };
                      handleValAndSave(handleValAndSaveOptions);
                    }}
                  >
                    Validate and Save
                  </Button>
                )}

                {state.canUserManipulateData && (
                  <Button
                    className="editReportPageActionBtns"
                    disabled={!isShowPublishBtn}
                    onClick={() => {
                      const handlePublishOptions = {
                        setIsShowDeploymentToPowerBiMessage,
                        setIsPublishTableFailure,
                        setIsPublished,
                        state,
                        ID1,
                        COMMENTS1,
                        MARKET1,
                        BUYSELL1,
                        QTY1,
                        DEAL_QUANTITY1,
                        NCF_CATEGORY1,
                        SCOPE1,
                        VOLUME1,
                        NCF_SCOPE1,
                        isScopeTableSelected,
                        dispatch,
                        setIsShowEditBtn,
                        setLooseChanges,
                        setOriginalVal,
                        setUpdatedCol,
                        setUpdatedVal,
                        setIsShowPublishBtn,
                        setIsReadyToPublish,
                        setIsEdit,
                        setIsEditError,
                        setIsDirtyData,
                      };
                      handlePublish(handlePublishOptions);
                    }}
                  >
                    Publish to Database
                  </Button>
                )}

                <Button
                  className="editReportPageActionBtns"
                  disabled={state.paginationData.length < 1}
                  onClick={() => {
                    const handleGetNewRecordOptions = {
                      state,
                      setLooseChanges,
                      setIsShowEditBtn,
                      setOriginalVal,
                      setUpdatedCol,
                      setUpdatedVal,
                      setIsShowPublishBtn,
                      setIsReadyToPublish,
                      dispatch,
                      setIsEdit,
                      setIsEditError,
                      setIsDirtyData,
                    };
                    handleGetNewRecord(handleGetNewRecordOptions);
                  }}
                >
                  Get New Record
                </Button>
              </Flexbox>

              <Flexbox
                gap="24px 24px"
                justifyContent="center"
                style={{ marginTop: '15px' }}
              >
                <Grid rowGap="24px">
                  <Modal
                    title="Unsaved / Unpublished Record"
                    open={looseChanges}
                    onClose={() => {
                      innerOnClose(
                        isPublishTableFailure,
                        setIsServiceFailure,
                        setIsPublishTableFailure
                      );
                    }}
                    actions={[
                      {
                        label: 'Cancel',
                        action: () => {
                          innerOnClose(
                            isPublishTableFailure,
                            setIsServiceFailure,
                            setIsPublishTableFailure
                          );
                          handleCancelNewRecord(setLooseChanges);
                        },
                        props: {
                          variant: Variants.Outlined,
                        },
                      },
                      {
                        label: 'Confirm',
                        action: () => {
                          innerOnClose(
                            isPublishTableFailure,
                            setIsServiceFailure,
                            setIsPublishTableFailure
                          );
                          const handleConfirNewRecordOptions = {
                            setIsShowEditBtn,
                            setLooseChanges,
                            setOriginalVal,
                            setUpdatedCol,
                            setUpdatedVal,
                            setIsShowPublishBtn,
                            setIsReadyToPublish,
                            dispatch,
                            setIsEdit,
                            setIsEditError,
                            setIsDirtyData,
                          };
                          handleConfirmNewRecord(handleConfirNewRecordOptions);
                        },
                      },
                    ]}
                  >
                    You have record those are either not saved or not published
                    yet. You will loose the changes if you proceed. Please click
                    OK to confirm or CANCEL to stay.
                  </Modal>

                  <TableAlert
                    isQtyExceedThreeWholeDigits={isQtyExceedThreeWholeDigits}
                    isDirtyData={isDirtyData}
                    isShowDeploymentToPowerBiMessage={
                      isShowDeploymentToPowerBiMessage
                    }
                  />
                  <Container>
                    {state.spinner ? (
                      <Loader />
                    ) : (
                      <DeprecatedTable
                        data-testid="viewEditTable"
                        size="small"
                        data={state.paginationData}
                        rowKey={(record: { id: any }) => record.id}
                        columns={editAndAuditReportTableCols(
                          cols,
                          state,
                          dispatch,
                          currColumns,
                          isEdit,
                          isReadyToPublish,
                          isEditError,
                          setIsDirtyData,
                          errorTableData,
                          setIsQtyExceedThreeWholeDigits,
                          setUpdatedCol,
                          setOriginalVal,
                          setUpdatedVal,
                          regionOptionsForAmericasSeamRegionTable,
                          ncfCatOptionsForAsiaJapanTable
                        )}
                        tableLayout="fixed"
                        emptyText={EmptyComponent}
                        wrapperStyle={{ border: '2px solid rgba(0,0,0,0.15)' }}
                      />
                    )}
                  </Container>

                  {!isEditError && !isReadyToPublish && (
                    <Pagination
                      total={state.recordCount}
                      pageSize={state.defaultPageSize}
                      onChange={(current: number) =>
                        handleOnChange(
                          current,
                          dispatch,
                          state,
                          isEdit,
                          isReadyToPublish,
                          ID1
                        )
                      }
                    />
                  )}
                </Grid>
              </Flexbox>
            </div>
          )}
        </Grid.Cell>
      </Grid>
    </div>
  );
};

export default ViewEditReportPage;
