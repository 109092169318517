import { Action, IPptData } from '../../types/types';

const PptActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: IPptData, payload: any) => IPptData
> = {
  setPptFilterOptions: (state, payload) => ({
    ...state,
    PptfilterOptions: payload,
  }),
  setPptFilteredValues: (state, payload) => ({
    ...state,
    PptfilteredValues: payload,
  }),
  setPptNcfCategories: (state, payload) => ({
    ...state,
    pptNcfCategories: payload,
  }),
  setPptNcfVolumes: (state, payload) => ({
    ...state,
    pptNcfVolumes: payload,
  }),
  // Add the new reducer action here
  setPptReportNames: (state, payload) => ({
    ...state,
    pptReportNames: payload, // Assuming the state has a property for storing report names
  }),
};

export const PptReportsReducers = (state: IPptData, action: Action) => {
  if (Object.hasOwn(PptActionHandlers, action.type)) {
    const handler = PptActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
