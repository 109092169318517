/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, ForwardedRef } from 'react';
import Highcharts, { SeriesLineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import exportingModule from 'highcharts/modules/exporting';
import ErrorMessage from './ErrorMessage';
import { ChartWrapper } from './Chart.style';
import useHasData from './useHasData';
import { GlassChartTheme } from '@sede-x/glass-design-library';
import HC_offlineExporting from 'highcharts/modules/offline-exporting';
import HighChartsMore from 'highcharts/highcharts-more';
import { ONE, TWO, FOUR } from '../../../../api/constants';

Boost(Highcharts);
exportingModule(Highcharts);
HC_offlineExporting(Highcharts);
HighChartsMore(Highcharts);

export type ChartProps = HighchartsReact.Props & {
  options: Highcharts.Options;
  className?: string;
  'data-testid'?: string;
  key?: string | number;
  chartKey?: string | number;
  source: string;
};

export function extractdateTime(date: Date) {
  const year = date
    .getFullYear()
    .toString()
    .padStart(FOUR, '0');
  const month = (date.getMonth() + ONE).toString().padStart(TWO, '0');
  const day = date
    .getDate()
    .toString()
    .padStart(TWO, '0');
  const hours = date
    .getHours()
    .toString()
    .padStart(TWO, '0');
  const minutes = date
    .getMinutes()
    .toString()
    .padStart(TWO, '0');
  const seconds = date
    .getSeconds()
    .toString()
    .padStart(TWO, '0');
  return { year, month, day, hours, minutes, seconds };
}

export function formatDateTimeDowloadFile(date: Date): string {
  const { year, month, day, hours, minutes, seconds } = extractdateTime(date);
  return `${year}-${month}-${day}_${hours}${minutes}${seconds}`;
}

export const makeNumberFromDigits = (digits: number[]) => {
  return parseInt(digits.join(''), 10);
};

const Chart = forwardRef(
  (
    {
      options: initialOptions,
      source,
      className = '',
      'data-testid': testId,
      key,
      chartKey,
      ...props
    }: ChartProps,
    ref: ForwardedRef<HighchartsReact.RefObject>
  ) => {
    const hasData = useHasData(initialOptions.series as SeriesLineOptions[]);
    const exportingOptions = {
      enabled: true,
      sourceWidth: 1200,
      sourceHeight: 500,
      filename: `${chartKey}_${formatDateTimeDowloadFile(new Date())}`,
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          symbol: 'menu',
          symbolStrokeWidth: 1,
          symbolSize: 16,
          symbolFill: '#343434',
          x: -makeNumberFromDigits([ONE, 0]),
          y: -makeNumberFromDigits([TWO]),
          menuItems: ['downloadJPEG', 'downloadPDF'],
        },
      },
    };

    const options: Highcharts.Options = {
      ...initialOptions,
      chart: {
        ...initialOptions.chart,
      },
      exporting: exportingOptions,
    };
    const keyObject = key ? { key } : {};
    return (
      <ChartWrapper
        style={{
          // height: '600px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        {/* Adjust the height as needed */}
        {hasData ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={GlassChartTheme({ options })}
            containerProps={{
              className: `chart-container ${className}`,
            }}
            ref={ref}
            {...props}
            {...keyObject}
            data-testid={testId}
          />
        ) : (
          <ErrorMessage message="No data found for chart" />
        )}
      </ChartWrapper>
    );
  }
);

Chart.displayName = 'Chart';
export default Chart;
