import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import React, { useEffect, useState } from 'react';
import {
  REPORT_FAILURE_MESSAGE,
  REPORT_IN_PROGRESS_MESSAGE,
  REPORT_SUCCESS_MESSAGE,
  SOM_MAIL_ID,
  FIVE_THOUSAND,
} from '../../../api/constants';

export const ReportStatusInfo = ({
  reportStatus,
}: {
  reportStatus: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (reportStatus !== '') {
      setIsVisible(true);
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, FIVE_THOUSAND);

      return () => clearTimeout(timeout);
    }
  }, [reportStatus]);

  const isReportSuccess = reportStatus?.toLocaleLowerCase() === 'success';
  const isReportFailure = reportStatus?.toLocaleLowerCase() === 'fail';
  const getMessage = () => {
    return isReportSuccess
      ? REPORT_SUCCESS_MESSAGE
      : isReportFailure
      ? `${REPORT_FAILURE_MESSAGE}${SOM_MAIL_ID}`
      : REPORT_IN_PROGRESS_MESSAGE;
  };
  return (
    reportStatus !== '' &&
    isVisible && (
      <Alert
        sentiment={
          isReportSuccess
            ? Sentiments.Positive
            : isReportFailure
            ? Sentiments.Negative
            : Sentiments.Information
        }
        style={{ marginBottom: '20px', width: 'auto', marginTop: '20px' }}
        elevation
      >
        {getMessage()}
      </Alert>
    )
  );
};
