import {
  Modal,
  Flexbox,
  Heading,
  Text,
} from '@sede-x/shell-ds-react-framework';
import React, { useContext } from 'react';
import { AppContext } from '../../../../Context/AppContext';

export const PpaLoadingModal = () => {
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } = useContext(
    AppContext
  ).ppaInsight;
  const handleOnClose = () =>
    ppaInsightDispatch({
      type: 'setIsLoading',
      payload: false,
    });
  return (
    <Modal
      loading
      onClose={() => {
        handleOnClose();
      }}
      actions={[
        {
          label: 'Confirm',
          action: () => {
            handleOnClose();
          },
        },
      ]}
      loadingBody={
        <Flexbox
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="8px"
        >
          <Heading level={3} bold>
            Loading PPA Insight data
          </Heading>
          <Text>Please do not close this page</Text>
        </Flexbox>
      }
      open={ppaInsightState.isLoading}
    ></Modal>
  );
};
