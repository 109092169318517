import { Modal } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { innerOnClose } from '../ViewEditReportFunctions';
export const InfoModals = (props: {
  isPublished: boolean;
  isPublishTableFailure: boolean;
  isServiceFailure: boolean;
  setIsServiceFailure: any;
  setIsPublishTableFailure: any;
}) => {
  const {
    isPublished,
    isPublishTableFailure,
    isServiceFailure,
    setIsServiceFailure,
    setIsPublishTableFailure,
  } = props;
  const onClose = () => {
    innerOnClose(
      isPublishTableFailure,
      setIsServiceFailure,
      setIsPublishTableFailure
    );
  };
  return (
    <>
      <Modal
        title="Successfully Published"
        open={isPublished}
        onClose={() => onClose}
        showFooter={false}
      ></Modal>

      <Modal
        title="Unable to publish your changes. Please try again after some time."
        open={isPublishTableFailure}
        onClose={() => {
          if (isPublishTableFailure) {
            setIsPublishTableFailure(false);
          }
        }}
        closable={true}
      ></Modal>

      <Modal
        title="We are sorry !! Server is currently unavailable. Please try after some time"
        open={isServiceFailure}
        onClose={() => onClose}
      ></Modal>
    </>
  );
};
