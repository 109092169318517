import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../Context/AppContext';
import { Country } from '../../../../types/types';
import { BiaReportLoader } from './BiaReportLoader/BiaReportLoader';
import { BiaSelectFormField } from './BiaSelectFormField/BiaSelectFormField';
import { fetchFilterInfo } from './BiaUtils';

export const BiaReportsFilterSection = () => {
  const { state, dispatch } = useContext(AppContext).biaReports;
  let filterData = state.biaFilterOptions;

  if (filterData.year) {
    filterData = {
      ...filterData,
      year: filterData.year.map(el => el.toString()),
    };
  }
  // Define local state for each filter field
  const [yearSelected, setYearSelected] = useState<string[]>([]);
  const [regionSelected, setRegionSelected] = useState<string>('');
  const [countrySelected, setCountrySelected] = useState<string[]>([]);
  const [commoditySelected, setCommoditySelected] = useState<string[]>([]);
  const [unitNameSelected, setUnitNameSelected] = useState('TWH');
  const [countryOptions, setCountryOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    fetchFilterInfo(dispatch, setYearSelected, handleRegionChange);
  }, []);

  if (!filterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {
    const currentYear = new Date().getFullYear();
    if (yearSelected.length === 0) {
      setYearSelected([currentYear.toString()]);
    }
    const payload = {
      biaRegion: regionSelected,
      biaYear: yearSelected || currentYear,
      biaCountry: countrySelected,
      biaCommodity: commoditySelected,
      biaUnitName: unitNameSelected || '',
    };
    dispatch({
      type: 'setBiaFilterData',
      payload: payload,
    });
  };

  const handleClearFilters = () => {
    const currentYear = new Date().getFullYear().toString();
    const defaultRegion = 'Europe';
    // Reset selections
    setCommoditySelected([]);
    setUnitNameSelected('TWH'); // This is common to both branches, so we set it outside
    // Reset local state
    setYearSelected([]);
    setCountrySelected([]);
    setRegionSelected('');

    const payload = {
      biaRegion: defaultRegion,
      biaYear: currentYear,
      biaCountry: [],
      biaCommodity: [],
      biaUnitName: 'TWH',
    };

    // Update local state for BIA report
    setYearSelected([currentYear]);
    setRegionSelected(defaultRegion);

    // Dispatch action with the constructed payload
    dispatch({
      type: 'setBiaFilterData',
      payload: payload,
    });
  };

  const handleRegionChange = (
    e: string,
    regionCountryMapping?: Record<string, Country[]>
  ) => {
    // For isBiaReportPage, e is expected to be a string.
    // Directly use e as it is.
    setRegionSelected(e);

    setCountrySelected([]);
    let cntryOptions: React.SetStateAction<{
      label: string;
      value: string;
    }[]> = [];
    const countryFieldOptions =
      regionCountryMapping ?? filterData.regionCountryMapping;

    if (countryFieldOptions[e]) {
      cntryOptions = countryFieldOptions[e].map(el => {
        return {
          label: el.COUNTRY_NAME,
          value: el.COUNTRY_NAME,
        };
      });
    } else {
      cntryOptions = [
        {
          label: '',
          value: '',
        },
      ];
    }
    setCountryOptions(cntryOptions);
  };

  const handleCountryChange = (e: string[]) => {
    // Set the selected country codes
    setCountrySelected(e);

    // Map each countryCode to its corresponding country.label
    const countries = e
      .map(countryCode => {
        const country = countryOptions.find(
          option => option.value === countryCode
        );
        return country ? country.label : null;
      })
      .filter((label): label is string => label !== null);
    setCountrySelected(countries);
  };

  return (
    <div data-testid="filter" style={{ padding: '20px' }}>
      <Flexbox flexDirection="column">
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        {state.isBiaFilterOptionsLoading ? (
          <div
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '4px',
            }}
          >
            <BiaReportLoader loadingText="filter options" />
          </div>
        ) : (
          <>
            <BiaSelectFormField
              id="year"
              label="Year"
              testId="Year"
              value={yearSelected}
              onChange={setYearSelected}
              options={filterData.year.map(el => el.toString())}
              mode="multiple"
            />

            <BiaSelectFormField
              id="region"
              label="Region"
              testId="Region"
              value={regionSelected}
              onChange={(e: string) => handleRegionChange(e)}
              options={filterData.region.map(el => ({
                label: el,
                value: el,
              }))}
              mode={'single'}
            />
            <BiaSelectFormField
              id="country"
              label="Country"
              testId="Country"
              value={countrySelected}
              onChange={(e: string[]) => handleCountryChange(e)}
              options={countryOptions}
              mode="multiple"
            />
            <BiaSelectFormField
              id="commodity"
              label="Commodity"
              testId="Commodity"
              value={commoditySelected}
              onChange={setCommoditySelected}
              mode="multiple"
              options={filterData.commodity.map(el => ({
                label: el.TRADE_NAME,
                value: el.TRADE_CODE,
              }))}
            />
            <BiaSelectFormField
              id="unitName"
              label="Unit Name"
              testId="Unit Name"
              value={unitNameSelected}
              onChange={setUnitNameSelected}
              options={filterData.unitName}
            />
            <Button
              className="getDataBtn"
              data-testid="Apply Filters"
              onClick={handleApplyFilters}
            >
              Apply Filters
            </Button>
            <Button
              className="getDataBtn"
              variant="outlined"
              style={{ marginTop: '10px' }}
              data-testid="Clear Filters"
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </>
        )}
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
