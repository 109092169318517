import { Card, Flexbox, Grid } from '@sede-x/shell-ds-react-framework';
import React, { useState } from 'react';
import { UpdateInfo } from './cardUtils';
import { ActionButton } from './ActionButtons';
import { ReportStatusInfo } from './ReportStatusInfo';
import { FileProcessingToast } from './FileProcessingToast';
import { DownloadInProgressToast } from './DownloadInProgressToast';
import { IN_PROGRESS } from '../../../api/constants';
import { TimeLineOptions } from './TimeLineOptions';

export const GenerateReportCard = (props: {
  step03ReportStatus: string;
  cardLog: any;
  setIsServerError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [runReportMessage] = useState('');
  const [startYear, setStartYear] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [endYear, setEndYear] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  return (
    <Card
      className="card"
      data-testid="card2"
      bodyPadding={true}
      header={{
        meta: 'Step 03',
        title: 'Produce Output',
        description: `An output file was successfully generated on ${props.cardLog.step03LastSuccessRunDate}.`,
      }}
      style={{
        borderRadius: '4px',
        background: 'var(--Background-Surface, #FFF)',

        /* Elevation/Level 2 */
        boxShadow:
          '0px 6px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <div
        style={{
          borderTop: '1px solid var(--Border-Subtle, rgba(28, 28, 28, 0.04))',
          paddingTop: '16px',
        }}
      >
        <TimeLineOptions
          startYear={startYear}
          startMonth={startMonth}
          endYear={endYear}
          endMonth={endMonth}
          setStartYear={setStartYear}
          setStartMonth={setStartMonth}
          setEndYear={setEndYear}
          setEndMonth={setEndMonth}
        />
        <FileProcessingToast runReportMessage={runReportMessage} />
        <ReportStatusInfo reportStatus={props.step03ReportStatus} />
        <DownloadInProgressToast isDownloadInProgress={isDownloadInProgress} />
        <Grid flow="column" justifyContent="space-between">
          <Grid.Cell>
            <UpdateInfo text={props.cardLog.step03LastRunDate} />
          </Grid.Cell>
          <Grid.Cell>
            <Flexbox gap="20px" justifyContent="center">
              <ActionButton
                content="Download Report"
                variants="filled"
                buttonKey="downloadReport"
                setIsServerError={props.setIsServerError}
                isDisabled={
                  props.step03ReportStatus?.toLowerCase() === IN_PROGRESS
                }
                setIsStep03DownlaodInProgress={setIsDownloadInProgress}
                downloadReportOptions={{
                  startYear,
                  startMonth,
                  endYear,
                  endMonth,
                }}
              />
            </Flexbox>
          </Grid.Cell>
        </Grid>
      </div>
    </Card>
  );
};
