import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { filterData as fetchFilterData } from '../../../../api/services';
import { AppContext } from '../../../../Context/AppContext';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';

export const PptFilterSection = () => {
  const { dispatch: analyticsDispatch } = useContext(
    AppContext
  ).analyticsHome;

  const { state: reconState, dispatch: reconDispatch } = useContext(AppContext).reconReports;

  const reconFilterData = reconState.ReconfilterOptions;

  // Define local state for each filter field
  const [yearSelected, setYearSelected] = useState('2023');
  const [monthSelected, setMonthSelected] = useState('');
  const [tradeTypeSelected, setTradeTypeSelected] = useState('');
  const [buySellSelected, setBuySellSelected] = useState('');
  const [ncfCategorySelected, setNcfCategorySelected] = useState('');
  const [ruleAppliedSelected, setRuleAppliedSelected] = useState('');
  const [unitSelected, setUnitSelected] = useState('TWH');

  useEffect(() => {
    if (reconState.ReconfilterOptions.TRADE_TYPE && reconState.ReconfilterOptions.BUY_SELL) {
      setTradeTypeSelected(reconState.ReconfilterOptions.TRADE_TYPE[0]);
      setBuySellSelected(reconState.ReconfilterOptions.BUY_SELL[0]);
    }
  }, [reconState.ReconfilterOptions]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFilterData();
      analyticsDispatch({ type: 'setFilterOptions', payload: response.data });
      // Set default values after data has been fetched
      if (response.data.region.length > 0) {
        setYearSelected(response.data.year[0].toString());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!reconFilterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {

    const payload = {
      ...reconState.ReconfilteredValues,
      year: yearSelected ? [yearSelected] : [],
      month: monthSelected || [],
      uom: unitSelected,
      transactionType: buySellSelected || [],
      tradeType: tradeTypeSelected || [],
      // ncfCategory: [ncfCategorySelected] || [],
      ruleApplied: ruleAppliedSelected || [],
    };
    reconDispatch({ type: 'setReconFilteredValues', payload });
  };

  const handleClearFilters = () => {
    // Reset local state
    setYearSelected('2024');
    setMonthSelected('');
    setTradeTypeSelected(reconState.ReconfilterOptions.TRADE_TYPE[0]);
    setBuySellSelected(reconState.ReconfilterOptions.BUY_SELL[0]);
    setNcfCategorySelected('');
    setRuleAppliedSelected('');
    setUnitSelected('TWH');



    // Create a payload with the initial state for each field
    const payload = {
      ...reconState.ReconfilteredValues,
      year: ['2024'],
      month: [],
      uom: 'TWH',
      transactionType: reconState.ReconfilterOptions.BUY_SELL[0] || [],
      tradeType: reconState.ReconfilterOptions.TRADE_TYPE[0] || [],
      // ncfCategory: [ncfCategorySelected] || [],
      ruleApplied: '',
    };

    // Dispatch the action to update the state in your context
    reconDispatch({ type: 'setReconFilteredValues', payload });
  };

  return (
    <div data-testid="filter" style={{ padding: '20px' }}>
      <Flexbox flexDirection="column">
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        {reconFilterData?.YEAR?.length > 0 && (
          <SelectFormField
            id="year"
            label="Year"
            testId="Year"
            value={yearSelected}
            onChange={setYearSelected}
            options={reconFilterData.YEAR.map((el) => el.toString())}
            mode="single"
          />
        )}
        {reconFilterData?.MONTH?.length > 0 && (
          <SelectFormField
            id="month"
            label="Month"
            testId="Month"
            value={monthSelected}
            onChange={setMonthSelected}
            options={reconFilterData.MONTH}
            mode="multiple"
          />
        )}
        {reconFilterData?.TRADE_TYPE?.length > 0 && (
          <SelectFormField
            id="tradeType"
            label="Trade Type"
            testId="Trade Type"
            value={tradeTypeSelected}
            onChange={setTradeTypeSelected}
            options={reconFilterData.TRADE_TYPE}
            mode="single"
          />
        )}
        {reconFilterData?.BUY_SELL?.length > 0 && (
          <SelectFormField
            id="buySell"
            label="Buy/Sell"
            testId="Buy/Sell"
            value={buySellSelected}
            onChange={setBuySellSelected}
            options={reconFilterData.BUY_SELL}
            mode="single"
          />
        )}
        {reconFilterData?.NCF_CATEGORY?.length > 0 && (
          <SelectFormField
            id="ncfCategory"
            label="NCF Category"
            testId="NCF Category"
            value={ncfCategorySelected}
            onChange={setNcfCategorySelected}
            options={reconFilterData.NCF_CATEGORY}
            mode="single"
          />
        )}
        {reconFilterData?.RULE_APPLIED?.length > 0 && (
          <SelectFormField
            id="ruleApplied"
            label="Rule Applied"
            testId="Rule Applied"
            value={ruleAppliedSelected}
            onChange={setRuleAppliedSelected}
            options={reconFilterData.RULE_APPLIED}
            mode="single"
          />
        )}
        {reconFilterData?.UNIT?.length > 0 && (
          <SelectFormField
            id="unit"
            label="Unit"
            testId="Unit"
            value={unitSelected}
            onChange={setUnitSelected}
            options={reconFilterData.UNIT}
            mode="single"
          />
        )}
        <Button
          className="getDataBtn"
          data-testid="Apply Filters"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          className="getDataBtn"
          variant="outlined"
          style={{ marginTop: '10px' }}
          data-testid="Clear Filters"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};