import React, { useContext, useState } from 'react';
import Chart from '../../../Chart/Chart';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import ChartToggle from '../../../../../ReUsableComponents/PPAToggle/PPAToggle';
import { NcfToggle } from '../../../AnalyticsHome/AnalyticsHomeCharts/PowerNcfVolumeChart/PowerNcfVolumeChart.style';
import {
  getNaturalGasVolumeData,
  getPowerVolumeData,
  columns,
  groupAndFormatData,
  tooltipFormatter,
} from './pptReportsUtils';
import { ChartContainer, TableHeading } from './PptReportingBarChart.style';
import { TooltipFormatterContextObject } from 'highcharts';

const PptReportingBarChart: React.FC = () => {
  const [view, setView] = useState('Chart');
  const { state: pptState } = useContext(AppContext).pptReports;

  const naturalGasVolumeData = getNaturalGasVolumeData(
    pptState.pptNcfVolumes.map(volume => ({
      ...volume,
      year: volume.year.toString(), // Convert year from number to string
    }))
  );

  const powerVolumeData = getPowerVolumeData(
    pptState.pptNcfVolumes.map(volume => ({
      ...volume,
      year: volume.year.toString(), // Convert year from number to string
    }))
  );

  const series = groupAndFormatData(pptState.pptNcfVolumes);

  const options = {
    chart: {
      type: 'column',
    },
    xAxis: {
      categories: ['Q1', 'Q2', 'Q3', 'Q4'],
      crosshair: true,
      title: {
        text: 'Pipeline Gas vs Power Volume Breakdown',
      },
    },
    yAxis: {
      title: {
        text: `Deal Quantity`,
      },
      minRange: 0.1,
    },
    tooltip: {
      shared: false,
      formatter(this: TooltipFormatterContextObject) {
        return tooltipFormatter(this);
      },
    },
    series,
  };

  return (
    <ChartContainer data-testid="map-container">
      <Flexbox
        style={{ width: '100%' }}
        gap="300px"
        justifyContent="space-between"
      >
        <TableHeading>Pipeline Gas vs Power Volume Breakdown</TableHeading>
        <NcfToggle>
          <ChartToggle setView={setView} />
        </NcfToggle>
      </Flexbox>
      {view === 'Table' ? (
        <Flexbox flexDirection="row" justifyContent="space-evenly" gap="20px">
          <div>
            <h2>Pipeline Gas Volume</h2>
            <BaseTable
              columns={columns}
              data={naturalGasVolumeData}
              size="large"
              data-testid="pipeline-gas-table"
            />
          </div>
          <div>
            <h2>Power Volume</h2>
            <BaseTable
              columns={columns}
              data={powerVolumeData} // Convert the record to an array
              size="large"
              data-testid="power-volume-table"
            />
          </div>
        </Flexbox>
      ) : (
        <Chart options={options} footer={'Source'} />
      )}
    </ChartContainer>
  );
};

export default PptReportingBarChart;
