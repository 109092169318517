import { Dispatch, SetStateAction } from 'react';
import {
  FIVE,
  THOUSAND_TWENTY_FOUR,
  THREE,
  INVALID,
} from '../../../api/constants';

export const handleOnUpload = (
  file: File[],
  fileName: string,
  setFuncs: {
    setGoldTierFile: Dispatch<SetStateAction<File | null>>;
    setGoldTierFileUploadSuccess: Dispatch<SetStateAction<boolean>>;
    setNaceFile: Dispatch<SetStateAction<File | null>>;
    setNaceFileUploadSuccess: Dispatch<SetStateAction<boolean>>;
    setGoldTierFileUploadState: Dispatch<SetStateAction<string>>;
    setGoldTierFileUploadFailureMessage: Dispatch<SetStateAction<string>>;
    setNaceFileUploadState: Dispatch<SetStateAction<string>>;
    setNaceFileUploadFailureMessage: Dispatch<SetStateAction<string>>;
  }
) => {
  const {
    setGoldTierFile,
    setGoldTierFileUploadSuccess,
    setNaceFile,
    setNaceFileUploadSuccess,
    setGoldTierFileUploadState,
    setGoldTierFileUploadFailureMessage,
    setNaceFileUploadState,
    setNaceFileUploadFailureMessage,
  } = setFuncs;
  const selectedFile = file?.[0];

  const isGoldTierFile = fileName === 'goldTierFile';
  const isNaceFile = fileName === 'naceFile';

  const fileValidationResult = checkFileType(
    selectedFile,
    isGoldTierFile,
    isNaceFile
  );
  const isFileInvalid = fileValidationResult.status === INVALID;

  if (!isFileInvalid) {
    if (isGoldTierFile) {
      setGoldTierFile(selectedFile);
      setGoldTierFileUploadSuccess(true);
    }
    if (isNaceFile) {
      setNaceFile(selectedFile);
      setNaceFileUploadSuccess(true);
    }
  } else {
    if (isGoldTierFile) {
      setGoldTierFileUploadState(fileValidationResult.status);
      setGoldTierFileUploadFailureMessage(fileValidationResult.errorMessage);
    }
    if (isNaceFile) {
      setNaceFileUploadState(fileValidationResult.status);
      setNaceFileUploadFailureMessage(fileValidationResult.errorMessage);
    }
  }
};

const checkFileType = (
  file: File,
  isGoldTierFile: boolean,
  isNaceFile: boolean
) => {
  let finalErrMessage = '';
  let status = 'success';
  // Check file size
  const maxSizeInBytes = FIVE * THOUSAND_TWENTY_FOUR * THOUSAND_TWENTY_FOUR; // 5 MB
  if (file.size > maxSizeInBytes) {
    status = INVALID;
    finalErrMessage = `${finalErrMessage}File size exceeds 5 MB limit. `;
  }

  // Check file extension
  const allowedExtensions = ['csv', 'xlsx', 'xls'];
  const fileExtension =
    file.name
      .split('.')
      .pop()
      ?.toLowerCase() ?? '';
  if (!allowedExtensions.includes(fileExtension)) {
    status = INVALID;
    finalErrMessage = `${finalErrMessage}Only Excel/CSV files are allowed. `;
  }

  // Check file name format
  let nameRegex: RegExp | undefined;
  let fileNameFormat = '';

  if (isGoldTierFile) {
    nameRegex = /^GoldTierMapping_\d{8}\.(csv|xlsx|xls)$/;
    fileNameFormat = 'GoldTierMapping_DDMMYYYY';
  } else if (isNaceFile) {
    nameRegex = /^NACEMapping_\d{8}\.(csv|xlsx|xls)$/;
    fileNameFormat = 'NACEMapping_DDMMYYYY';
  }

  if (!nameRegex?.test(file.name)) {
    status = INVALID;
    finalErrMessage = `${finalErrMessage}File format must be ${fileNameFormat} `;
  }

  // All checks passed, file is valid
  return { status, errorMessage: finalErrMessage };
};

export const removeDeleteLinkInFileUploader = (fileId: string) => {
  const fileElement = document.getElementById(fileId) as HTMLElement;
  const deleteFileLink = fileElement.childNodes[0].childNodes[
    THREE
  ] as HTMLButtonElement;
  if (deleteFileLink.textContent === 'Delete file') {
    deleteFileLink.click();
  }
};

export const getLogDateInFormat = (
  step01LogDate: string,
  step02LogDate: string,
  step03LastRunDate: string,
  step03LastSuccessRunDate: string
) => {
  const step01DateObj = new Date(step01LogDate);
  const step02DateObj = new Date(step02LogDate);
  const step03LastRunDateObj = new Date(step03LastRunDate);
  const step03LastSuccessRunDateObj = new Date(step03LastSuccessRunDate);

  const formatDateTime = (dateObj: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return dateObj.toLocaleString('en-GB', options);
  };

  return [
    formatDateTime(step01DateObj),
    formatDateTime(step02DateObj),
    formatDateTime(step03LastRunDateObj),
    formatDateTime(step03LastSuccessRunDateObj),
  ];
};
