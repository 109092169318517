import { FormField, Select, Option } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, SetStateAction } from 'react';

type OnChangeType =
  | Dispatch<SetStateAction<string>>
  | Dispatch<SetStateAction<number>>
  | Dispatch<SetStateAction<string[]>>
  | Dispatch<SetStateAction<number[]>>
  | ((e: string) => void) // Added this line to include functions that take a single string
  | ((e: string[]) => void); // This was already there, for functions that take an array of strings

interface IBiaSelectFormFieldProps {
  id: string;
  label: string;
  testId: string;
  value: string | number | Array<string | number>;
  onChange: OnChangeType;
  options:
    | Array<{
        label: string | number;
        value: string | number;
      }>
    | Array<string | number>;
  mode?: 'single' | 'multiple';
  disabled?: boolean;
}

export const BiaSelectFormField: React.FC<IBiaSelectFormFieldProps> = ({
  id,
  label,
  testId,
  value,
  onChange,
  options,
  mode = 'single',
  disabled = false,
}) => (
  <FormField id={id} label={label} data-testid={testId}>
    <Select
      size="medium"
      allowClear={false}
      aria-label="Select"
      value={value || undefined}
      onChange={onChange}
      mode={mode}
      disabled={disabled}
      style={{ maxWidth: '100%', flexWrap: 'wrap' }}
    >
      {options
        ? options.map(el => {
            const option =
              typeof el === 'string' || typeof el === 'number'
                ? { label: el, value: el }
                : el;
            return (
              option && (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              )
            );
          })
        : null}
    </Select>
  </FormField>
);
