import React, { useContext, useEffect } from 'react';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { reconReportTotalVolume } from '../../../../../../api/services';
import { ChartContainer } from './ReconTotalValuesTable.style';

export const columns = [
  {
    header: 'Data Type',
    accessorKey: 'dataType',
  },
  {
    header: 'Tanso Data',
    accessorKey: 'inScopeVolume',
  },
  {
    header: 'ETRM Data',
    accessorKey: 'outOfScopeVolume',
  },
  {
    header: 'Delta (Tanso - ETRM)',
    accessorKey: 'totalVolume',
  },
];

const PptReportingBarChart: React.FC = () => {
  const { state: reconState, dispatch:  reconDispatch} = useContext(AppContext).reconReports;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const totalVolumeResponse = await reconReportTotalVolume(reconState.ReconfilteredValues);
        reconDispatch({
          type: 'setReconReportDefaultTotalVolume',
          payload: totalVolumeResponse.data,
        });
  
      } catch (error) {
        // Log error once toast feature is implemented
      } 
    };
  
    fetchData();
  }, []);


  // Add hardcoded rows for Total Volume and Total Count
  const hardcodedRows = [
    {
      dataType: 'Total Volume',
      inScopeVolume: reconState.reconReportDefaultTotalVolume.TANSO.TOTAL_VOLUME,
      outOfScopeVolume: reconState.reconReportDefaultTotalVolume.ETRM.TOTAL_VOLUME,
      totalVolume: reconState.reconReportDefaultTotalVolume.DELTA.TOTAL_VOLUME,
    },
    {
      dataType: 'Total Count',
      inScopeVolume: reconState.reconReportDefaultTotalVolume.TANSO.TOTAL_COUNT,
      outOfScopeVolume: reconState.reconReportDefaultTotalVolume.ETRM.TOTAL_COUNT,
      totalVolume: reconState.reconReportDefaultTotalVolume.DELTA.TOTAL_COUNT,
    },
  ];

  return (
    <ChartContainer data-testid="map-container">
      <Flexbox
        style={{ width: '100%' }}
        gap="300px"
        justifyContent="space-between"
      >
        {/* <TableHeading>Pipeline Gas Volume Table</TableHeading> */}
      </Flexbox>
      {/* <Flexbox flexDirection="row" justifyContent="space-evenly" gap="20px"> */}
        <div>
          <BaseTable
            columns={columns}
            data={[...hardcodedRows]}
            size="large"
            data-testid="pipeline-gas-table"
            style={{ width: '100%' }}
          />
        </div>
      {/* </Flexbox> */}
    </ChartContainer>
  );
};

export default PptReportingBarChart;