import { AMERICAS } from '../../api/constants';
import { Country, IPptData } from '../../types/types';

export const PptReportsInitialState: IPptData = {
  PptfilterOptions: {
    year: [],
    quarter: [],
    month: [],
    region: [],
    regionCountryMapping: {} as Record<string, Country[]>,
    commodity: [],
    transactionType: [],
    unitName: [],
    reportStatus: [],
  },
  PptfilteredValues: {
    year: ['2024'],
    quarter: [],
    month: [],
    region: [AMERICAS],
    countryCode: [],
    commodity: [],
    uom: 'TWh',
    transactionType: ['NCF'],
    reportStatus: [],
  },
  pptNcfCategories: [
    {
      year: 2023,
      quantity: 0.8458809070000005,
      tradeName: 'Power',
      quarter: 'Q1',
      ncfScope: '1',
      ncfCategory: 'Utilities - end user sales/ generation',
    },
  ],
  pptNcfVolumes: [
    {
      year: 2023,
      quantity: 4.100878355999998,
      tradeName: 'Power',
      quarter: 'Q1',
      ncfScope: '0',
    },
  ],
  pptReportNames: [],
};

export type IPptInitialState = typeof PptReportsInitialState;
