import { Modal } from '@sede-x/shell-ds-react-framework';
import React, { useContext } from 'react';
import { AppContext } from '../../../../Context/AppContext';

export const PPAInsightServerError = () => {
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } = useContext(
    AppContext
  ).ppaInsight;
  const handleOnClose = () =>
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: false,
    });
  return (
    <Modal
      onClose={() => {
        handleOnClose();
      }}
      title={'PPA Insight Server Error'}
      description={
        'We are facing problem while fetching data. Please try after sometime.'
      }
      actions={[
        {
          label: 'Close',
          action: () => {
            handleOnClose();
          },
        },
      ]}
      open={ppaInsightState.isPpaServerError}
    ></Modal>
  );
};
