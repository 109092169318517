/* eslint-disable no-unused-vars */
import { Flexbox, Grid, Modal, Text } from '@sede-x/shell-ds-react-framework';
import React from 'react';

export const InfoModal = (props: {
  isOpen: boolean;
  message: string;
  setFuncs: {
    setIsFetchTableFailure: (value: boolean) => void;
    setIsServiceFailure: (value: boolean) => void;
    setIsFetchOptionFailure: (value: boolean) => void;
  };
}) => {
  const {
    setIsFetchTableFailure,
    setIsServiceFailure,
    setIsFetchOptionFailure,
  } = props.setFuncs;
  const innerOnClose = () => {
    setIsFetchTableFailure(false);
    setIsServiceFailure(false);
    setIsFetchOptionFailure(false);
  };

  return (
    <Modal
      showHeader={false}
      open={props.isOpen}
      onClose={() => {
        innerOnClose();
      }}
    >
      <Grid justifyContent="center">
        <Grid.Cell>
          <img src="../shell-logo.png" alt="secm" style={styles.img} />
        </Grid.Cell>
        <Grid.Cell>
          <Flexbox justifyContent="center">
            <Text size="large">Error</Text>
          </Flexbox>
        </Grid.Cell>
        <Grid.Cell>
          <Flexbox justifyContent="center">{props.message}</Flexbox>
        </Grid.Cell>
      </Grid>
    </Modal>
  );
};

const styles = {
  title: {
    margin: '40px',
  },
  img: {
    height: '60px',
    margin: '20px auto',
    display: 'block',
  },
};
