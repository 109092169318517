import React, { useEffect, useState, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AppContext } from '../../../../../../Context/AppContext';
import {
  MapContainer,
  Spacer,
  GeoChartContainer,
} from './TopCtpyBarChart.style';
import { topCounterParty } from '../../../../../../api/services';
import Loader from '../../../../../Loader/Loader';

const TopCtpyBarChart = () => {
  const { state, dispatch } = useContext(AppContext).analyticsHome;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await topCounterParty(state.cptyFilteredValues);
        dispatch({
          type: 'setTopCounterParty',
          payload: response.data,
        });
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
  }, [dispatch, state.cptyFilteredValues]);

  const mapData = data.map(
    (item: { CounterpartyName: string; Quantity: number }) => ({
      name: item?.CounterpartyName,
      y: item?.Quantity,
    })
  );

  const options = {
    chart: {
      type: 'bar',
      height: 800,
    },
    title: {
      text: 'Top 20 Counterparties Chart',
      align: 'left',
    },
    xAxis: {
      categories: mapData.map(item => item.name),
    },
    yAxis: {
      title: {
        text: `Quantity (${state?.cptyFilteredValues?.uom})`,
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      labelFormatter() {
        return `Year: ${state?.cptyFilteredValues?.year?.length &&
          state?.cptyFilteredValues?.year[0]}`;
      },
    },
    tooltip: {
      pointFormat: 'Quantity: {point.y}',
    },
    plotOptions: {
      series: {
        pointWidth: 20,
        pointPadding: 0.2, // Padding between each column or bar, defaults to 0.1
        groupPadding: 0.2, // Padding between each value groups, in x axis units, defaults to 0.2
      },
    },
    series: [
      {
        name: 'Counterparties',
        data: mapData,
        color: '#0097A9',
      },
    ],
  };

  return (
    <MapContainer data-testid="map-container">
      <Spacer>
        <GeoChartContainer data-testid="mock-highcharts">
          {isLoading ? (
            <Loader loader={true} message={''} />
          ) : mapData.length === 0 ? (
            <p>No data available for selected filters</p>
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              data-testid="mock-highcharts"
            />
          )}
        </GeoChartContainer>
      </Spacer>
    </MapContainer>
  );
};

export default TopCtpyBarChart;
