import { Action, ICiTradeAndVolumeData } from '../../types/types';

const CiTradeAndVolumeActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: ICiTradeAndVolumeData, payload: any) => ICiTradeAndVolumeData
> = {
  setIsError: (state, payload) => ({
    ...state,
    error: {
      ...state.error,
      isError: payload.isError,
      message: payload.message,
    },
  }),
  setCiTradeChartData: (state, payload) => ({
    ...state,
    ciTradeChartData: payload,
  }),
  setCiTradeTableData: (state, payload) => ({
    ...state,
    ciTradeTableData: payload,
  }),
  setCiTradeFilterOptions: (state, payload) => ({
    ...state,
    ciTradeFilterOptions: payload,
  }),
  setCiTradeFilteredValues: (state, payload) => ({
    ...state,
    ciTradeFilteredValues: payload,
  }),
};
export const CiTradeAndVolumeReducers = (
  state: ICiTradeAndVolumeData,
  action: Action
) => {
  if (Object.hasOwn(CiTradeAndVolumeActionHandlers, action.type)) {
    const handler = CiTradeAndVolumeActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
