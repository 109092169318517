import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../../Context/AppContext';
import { fetchBiaValidationCheckData } from './BiaUtils';
import { BiaTable } from './BiaTable';
import { BiaTableContainer } from './BiaReports.style';

export const DataValidationCheckView = () => {
  const { state, dispatch } = useContext(AppContext).biaReports;
  useEffect(() => {
    fetchBiaValidationCheckData(state, dispatch);
  }, [dispatch, state.biaFilterData]);
  const description =
    'Gross Physical Traded Volume vs Volume By NCF Category - Data Validation Check';
  return (
    <BiaTableContainer>
      <BiaTable
        description={description}
        isLoading={state.isValidationDataLoading}
        loadingText="validation check data"
        biaData={state.biaValidationCheckData}
      />
    </BiaTableContainer>
  );
};
