import {
  Button,
  Flexbox,
  Grid,
  Pecten,
  RadioButton,
  Sizes,
} from '@sede-x/shell-ds-react-framework';
import React, { ChangeEvent, useContext, useState } from 'react';
import {
  CustomText,
  SelectRoleContainer,
  SelectRoleDiv,
} from './SelectRolePage.style';
import {
  TANSO_REPORTING_TOOL,
  SELECT_ROLE_MESSAGE,
  CONTINUE,
} from '../../api/constants';
import { AppContext } from '../../Context/AppContext';
import { setUpRoleBasedAccess } from '../AppContent/AppContentFunctions';

export const SelectRolePage = ({ roles }: { roles: string[] }) => {
  const [isChecked, setIsChecked] = useState('');
  const { state, dispatch } = useContext(AppContext).appData;
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(e.target.value);
  };
  const onContinueClick = () => {
    const isProdEnv = process.env.REACT_APP_ENVIRONMENT === 'prod';
    setUpRoleBasedAccess(
      [isChecked],
      state.username,
      state.mail,
      dispatch,
      isProdEnv
    );
    dispatch({ type: 'setRole', payload: [isChecked] });
  };

  return (
    <SelectRoleContainer data-testid="select-role-container">
      <SelectRoleDiv>
        <Grid.Cell>
          <Grid justifyItems="center">
            <Grid.Cell>
              <Flexbox>
                <Pecten size={Sizes.Large} />
              </Flexbox>
            </Grid.Cell>
            <Grid.Cell>
              <CustomText>{TANSO_REPORTING_TOOL}</CustomText>
            </Grid.Cell>
            <Grid.Cell>{SELECT_ROLE_MESSAGE}</Grid.Cell>
            <Grid.Cell>
              <table style={{ borderCollapse: 'collapse' }}>
                <tbody>
                  {roles.map(role => (
                    <tr key={role} style={{ border: '1px solid #edece8' }}>
                      <td
                        style={{ padding: '5px', border: '1px solid #edece8' }}
                      >
                        <RadioButton
                          label={role}
                          value={role}
                          checked={isChecked === role}
                          onChange={handleOnChange}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid.Cell>
            <Grid.Cell>
              <Flexbox justifyContent="center">
                <Button
                  sentiment="primary"
                  size="medium"
                  onClick={onContinueClick}
                >
                  {CONTINUE}
                </Button>
              </Flexbox>
            </Grid.Cell>
          </Grid>
        </Grid.Cell>
      </SelectRoleDiv>
    </SelectRoleContainer>
  );
};
