import { Action, IBiaReports } from '../../types/types';

const BiaReportsActionHandlers: Record<
  string,
  // eslint-disable-next-line no-unused-vars
  (state: IBiaReports, payload: any) => IBiaReports
> = {
  setBiaValidationCheckData: (state, payload) => ({
    ...state,
    biaValidationCheckData: payload,
  }),
  setBiaUncatCptyEuropeAmericaData: (state, payload) => ({
    ...state,
    biaUncatCptyEuropeAmericasData: payload,
  }),
  setBiaUncatCptyAsiaAustraliaData: (state, payload) => ({
    ...state,
    biaUncatCptyAsiaAustraliaData: payload,
  }),
  setBiaInactiveCptyData: (state, payload) => ({
    ...state,
    biaInactiveCptyData: payload,
  }),
  setBiaGroupAffData: (state, payload) => ({
    ...state,
    biaGroupAffData: payload,
  }),
  setIsValidationDataLoading: (state, payload) => ({
    ...state,
    isValidationDataLoading: payload,
  }),
  setIsGroupAffDataLoading: (state, payload) => ({
    ...state,
    isGroupAffDataLoading: payload,
  }),
  setIsInactiveCptyDataLoading: (state, payload) => ({
    ...state,
    isInactiveCptyDataLoading: payload,
  }),
  setIsUncatCptyDataLoading: (state, payload) => ({
    ...state,
    isUncatCptyDataLoading: payload,
  }),
  setBiaFilterData: (state, payload) => ({
    ...state,
    biaFilterData: payload,
  }),
  setIsLoading: (state, payload) => ({
    ...state,
    isLoading: payload,
  }),
  setIsBiaFilterOptionsLoading: (state, payload) => ({
    ...state,
    isBiaFilterOptionsLoading: payload,
  }),
  setBiaFilterOptions: (state, payload) => ({
    ...state,
    biaFilterOptions: payload,
  }),
  setIsNcfServerError: (state, payload) => ({
    ...state,
    isNcfServerError: payload,
  }),
  setNcfServerErrorMessage: (state, payload) => ({
    ...state,
    ncfServerErrorMessage: payload,
  }),
};
export const BiaReportsReducers = (state: IBiaReports, action: Action) => {
  if (Object.hasOwn(BiaReportsActionHandlers, action.type)) {
    const handler = BiaReportsActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
