import React, { useEffect, useContext, useState } from 'react';
import { Flexbox, Button } from '@sede-x/shell-ds-react-framework';
import { filterData as fetchFilterData } from '../../../../api/services';
import { AppContext } from '../../../../Context/AppContext';
import { SelectFormField } from '../../../ReUsableComponents/SelectFormField/SelectFormField';
import { useLocation } from 'react-router-dom';

export const AnalyticsFilterSection = () => {
  const location = useLocation();
  const { state: analyticsState, dispatch: analyticsDispatch } = useContext(
    AppContext
  ).analyticsHome;
  let filterData = analyticsState.filterOptions;

  if (filterData.year) {
    filterData = {
      ...filterData,
      year: filterData.year.map((el: { toString: () => string }) =>
        el.toString()
      ),
    };
  }

  // Define local state for each filter field
  const [yearSelected, setYearSelected] = useState<string[]>([]);
  const [quarterSelected, setQuarterSelected] = useState<string[]>([]);
  const [monthSelected, setMonthSelected] = useState<string[]>([]);
  const [regionSelected, setRegionSelected] = useState<string[]>([]);
  const [countrySelected, setCountrySelected] = useState<string[]>([]);
  const [commoditySelected, setCommoditySelected] = useState<string[]>(['PW']);
  const [ncfTypeSelected, setNcfTypeSelected] = useState('CUMULATIVE');
  const [transactionTypeSelected, setTransactionTypeSelected] = useState('NCF');
  const [unitNameSelected, setUnitNameSelected] = useState('TWH');
  const [ncfScopeSelected, setNcfScopeSelected] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchFilterData();
      analyticsDispatch({ type: 'setFilterOptions', payload: response.data });
    };

    fetchData();
    //handleClearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      transactionType,
      commodity,
      countryCode,
      quarter,
      year,
      region,
      uom,
      ncfScope,
    } = analyticsState.filteredValues;
    setYearSelected(year);
    setQuarterSelected(quarter);
    setCountrySelected(countryCode);
    setRegionSelected(region);
    setCommoditySelected(commodity);
    setUnitNameSelected(uom);
    setTransactionTypeSelected(transactionType);
    setNcfScopeSelected(ncfScope);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsState.filteredValues]);

  if (!filterData) {
    return null; // or a loading spinner
  }

  const handleApplyFilters = () => {
    // Implement the logic to apply filters here...

    const payload = {
      year: yearSelected || [],
      quarter: quarterSelected || [],
      month: monthSelected || [],
      region: regionSelected,
      countryCode: countrySelected || [],
      commodity: Array.isArray(commoditySelected)
        ? commoditySelected
        : [commoditySelected],
      uom: unitNameSelected,
      ncfType: ncfTypeSelected,
      transactionType: transactionTypeSelected,
      ncfScope: ncfScopeSelected,
    };

    analyticsDispatch({ type: 'setFilteredValues', payload });
  };

  const handleClearFilters = () => {
    // Reset local state
    setYearSelected([]);
    setQuarterSelected([]);
    setMonthSelected([]);
    setCountrySelected([]);
    setRegionSelected([]);
    setCountrySelected([]);
    setCommoditySelected(['PW']);
    setNcfTypeSelected('CUMULATIVE');
    setUnitNameSelected('TWH');
    setTransactionTypeSelected('NCF');
    setNcfScopeSelected([]);

    // Create a payload with the initial state for each field
    const payload = {
      year: [],
      quarter: [],
      month: [],
      region: [],
      countryCode: [],
      commodity: ['PW'],
      uom: 'TWH',
      ncfType: 'CUMULATIVE',
      transactionType: transactionTypeSelected,
      ncfScope: [],
    };

    // Dispatch the action to update the state in your context
    analyticsDispatch({ type: 'setFilteredValues', payload });
  };

  const countryOptions = Array.isArray(regionSelected)
    ? [
        ...new Set(
          regionSelected.flatMap(region =>
            filterData.regionCountryMapping[region]?.map(el => {
              return {
                label: el.COUNTRY_NAME,
                value: el.COUNTRY_CODE,
              };
            })
          )
        ),
      ]
    : [];

  return (
    <div data-testid="filter" style={{ padding: '20px' }}>
      <Flexbox flexDirection="column">
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '17px',
            lineHeight: '26px',
            color: '#404040',
            padding: '10px 0',
            marginTop: '26px',
            width: '251px',
          }}
        >
          Filters
        </div>
        <SelectFormField
          id="year"
          label="Year"
          testId="Year"
          value={yearSelected}
          onChange={setYearSelected}
          options={filterData.year.map(el => el.toString())}
          mode="multiple"
        />
        {yearSelected.length === 1 && (
          <SelectFormField
            id="quarter"
            label="Quarter"
            testId="Quarter"
            value={quarterSelected}
            onChange={setQuarterSelected}
            options={filterData.quarter}
            mode="multiple"
          />
        )}
        <SelectFormField
          id="region"
          label="Region"
          testId="Region"
          value={regionSelected}
          onChange={setRegionSelected}
          options={filterData.region}
          mode="multiple"
        />
        <SelectFormField
          id="country"
          label="Country"
          testId="Country"
          value={countrySelected}
          onChange={setCountrySelected}
          options={countryOptions}
          mode="multiple"
        />
        <SelectFormField
          id="commodity"
          label="Commodity"
          testId="Commodity"
          value={commoditySelected}
          // onChange={(value: string | string[]) =>
          //   setCommoditySelected(Array.isArray(value) ? value : [value])
          // }
          onChange={setCommoditySelected}
          options={filterData.commodity.map(el => {
            return {
              label: el.TRADE_NAME,
              value: el.TRADE_CODE,
            };
          })}
        />
        <SelectFormField
          id="transactionType"
          label="Transaction Type"
          testId="Transaction Type"
          value={transactionTypeSelected}
          onChange={setTransactionTypeSelected}
          options={filterData.transactionType}
        />
        <SelectFormField
          id="unitName"
          label="Unit Name"
          testId="Unit Name"
          value={unitNameSelected}
          onChange={setUnitNameSelected}
          options={filterData.unitName}
        />
        {location.pathname === '/region-volume-details' && (
          <SelectFormField
            id="ncfScope"
            label="NCF Scope"
            testId="NCF Scope"
            value={ncfScopeSelected}
            onChange={setNcfScopeSelected}
            options={filterData.ncfScope}
            allowClear
            mode="multiple"
          />
        )}
        <Button
          className="getDataBtn"
          data-testid="Apply Filters"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          className="getDataBtn"
          variant="outlined"
          style={{ marginTop: '10px' }}
          data-testid="Clear Filters"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </Flexbox>
      <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.06)' }}></div>
    </div>
  );
};
