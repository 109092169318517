import React, { useContext, useEffect, useState } from 'react';
import {
  FormField,
  Select,
  Flexbox,
  Button,
  RangeDatePicker,
  Accordion,
  AccordionPanel,
  Option,
  Text,
  Sentiments,
} from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../Context/AppContext';
import filterOptions from '../../api/filterOptions';
import {
  fetchReport,
  fetchCommodities,
  fetchDealType,
  fetchCptySearchResult,
  fetchAuditReport,
  fetchCptyForAsiaRegion,
} from '../../api/services';
import Loader from '../Loader/Loader';
import './../style.module.css';
import debounce from 'lodash/debounce';
import { Dayjs } from 'dayjs';
import {
  I_ALIGNE_TABLE,
  ENDUR_I_GAS_TABLE,
  I_GROUP_TABLE,
  NUCLEUS_I_POWER_TABLE,
  ENDUR_SEAM_REGION_SCOPE_TABLE,
  NUCLEUS_SEAM_REGION_SCOPE_TABLE,
  ENDURSLMT_STATIC_MAP_NCF_TABLE,
  ENDURSLMT_I_POWER_TABLE,
  REGION_SHORT_NAMES,
  ASIA,
  SELECT,
  EUROPE,
  AMERICAS,
  THOUSAND,
  THREE,
  TWO,
  JAPAN,
  ASIA_JAPAN_SHORT_NAME,
  AUSTRALIA,
  ENDURAU_I_GAS,
  ENDURAU_STATIC_MAP_NCF,
} from '../../api/constants';
import {
  formatDate,
  getDefaultOptions,
  hideSpinner,
  showSpinner,
} from '../Utils/utils';
import { OptionI } from '../../types/types';
import { InfoModal } from './InfoModal';

let regionOptions = filterOptions.regionForDataEditOptions;
const commodityOptionGasTable = filterOptions.commodityOptionsforGasTable;
const commodityOptionsPowerTable = filterOptions.commodityOptionsforPowerTable;
const defaultSelect = filterOptions.defaultOption;
const countryOptions = filterOptions.countryOptionsForAsiaRegion;

export type Page = {
  offset: number;
  limit: number;
};

const d = new Date();
const threeYearBack = d.getFullYear() - THREE;
d.setFullYear(threeYearBack);

const disabledFutureDate = (current: Dayjs) =>
  current
    ? current.isAfter(new Date(), 'date') || current.isBefore(d, 'date')
    : false;
const disableAsiaDateRange = (current: Dayjs) => {
  return current ? current.isBefore(d, 'date') : false;
};

export const pageSize = 20;
export const debounceTimeout = 800;
const FilterSection = ({ view }: { view: string }) => {
  const { state, dispatch } = useContext(AppContext).appData;

  const [region, setRegion] = useState(SELECT);
  const [regionShortName, setRegionShortName] = useState('');
  const [country, setCountry] = useState(SELECT);
  const [tableName, setTableName] = useState(SELECT);
  const [commodity, setCommodity] = useState(SELECT);
  const [cptyDesc, setCptyDesc] = useState('');
  const [firstDate, setFirstDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [transactionType, setTransactionType] = useState(SELECT);

  const isViewReport = view === 'report';
  const isAuditReport = view === 'audit report';

  const [isFetchOptionFailure, setIsFetchOptionFailure] = useState(false);
  const [isFetchTableFailure, setIsFetchTableFailure] = useState(false);
  const [isServiceFailure, setIsServiceFailure] = useState(false);

  const [isDateValid, setIsDateValid] = useState(true);
  const [isCommodityValid, setIsCommodityValid] = useState(true);
  const [isTransTypeValid, setIsTransTypeValid] = useState(true);
  const [isCptyShortNameValid, setIsCptyShortNameValid] = useState(true);
  const [isRegionValid, setIsRegionValid] = useState(true);
  const [isTableNameValid, setIsTableNameValid] = useState(true);
  const [isCountryNameValid, setIsCountryNameValid] = useState(true);

  const loader = false;

  // Inside your component function or class
  const [tableNameOptions, setTableNameOptions] = useState(getDefaultOptions());
  const [commodityOptions, setCommodityOptions] = useState(getDefaultOptions());
  const [transactionTypeOptions, setTransactionTypeOptions] = useState(
    getDefaultOptions()
  );
  const [cptyOptions, setCptyOptions] = useState<string[]>([]);

  const [isSearchingCpty, setIsSearchingCpty] = useState(false);
  const [isOneRegionOption, setIsOneRegionOption] = useState(false);
  const [updatedRegionOptions, setUpdatedRegionOptions] = useState<
    { value: string; label: string }[]
  >(getDefaultOptions());

  useEffect(() => {
    // Filter the array to find a match for userRegion. This is specific to users with particular region
    const filteredOptions = regionOptions.filter(option =>
      state.userRegions.includes(option.label.toUpperCase())
    );

    // If filteredOptions is empty (no match found), return the entire regionOptions array
    regionOptions =
      filteredOptions.length > 0 ? filteredOptions : regionOptions;
    setUpdatedRegionOptions(regionOptions);
    if (regionOptions.length === 1) {
      setRegionAndTableName(regionOptions[0].value);
      setIsOneRegionOption(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRegionAsia = () => region.toUpperCase() === ASIA;
  const getAuditReportData = async () => {
    showSpinner(dispatch);
    try {
      //audit report page
      const auditOptions = {
        pageNumber: 1,
        pageSize: state.defaultPageSize,
        startDate: firstDate,
        endDate: lastDate,
        region: regionShortName,
      };
      dispatch({ type: 'setFilteredOptions', payload: auditOptions });
      const viewAuditReport = await fetchAuditReport(auditOptions);
      dispatch({
        type: 'setAuditPaginationData',
        payload: viewAuditReport.data.data,
      });
      dispatch({
        type: 'setRecordCount',
        payload: viewAuditReport.data.count,
      });
    } catch (e) {
      setIsFetchTableFailure(true);
    }
    hideSpinner(dispatch);
    dispatch({ type: 'setShowAuditReport', payload: true });
  };

  const checkCommodityValidity = () => {
    const commodityField = document.getElementById(
      'commodityId'
    ) as HTMLInputElement | null;
    const isCommodityDisabled =
      commodityField !== null ? commodityField.disabled : false;
    return (
      isCommodityDisabled || (!isCommodityDisabled && commodity !== SELECT)
    );
  };

  const checkCounterPartyValidity = () => {
    const counterPartyField = document.getElementById(
      'counterPartyId'
    ) as HTMLInputElement | null;
    const isCounterPartyDisabled =
      counterPartyField !== null ? counterPartyField.disabled : false;
    return (
      isCounterPartyDisabled || (!isCounterPartyDisabled && cptyDesc !== '')
    );
  };

  const checkTransTypeValidity = () => {
    const transTypeField = document.getElementById(
      'transactionTypeId'
    ) as HTMLInputElement | null;
    const isTransTypeDisabled =
      transTypeField !== null ? transTypeField.disabled : false;
    return (
      isTransTypeDisabled ||
      (!isTransTypeDisabled && transactionType !== SELECT)
    );
  };

  const checkDeliveryMonthValidity = () => {
    let isValidDate = true;
    let isTimeDurationDisabled = false;
    const timeDurationField = document.getElementById(
      'deliveryMonthId'
    ) as HTMLInputElement | null;
    if (timeDurationField !== null) {
      isTimeDurationDisabled = timeDurationField.disabled;
    }
    const isAnyDateEmpty =
      !isTimeDurationDisabled && (firstDate === '' || lastDate === '');
    if (isAnyDateEmpty) {
      setIsDateValid(false);
      isValidDate = false;
    }
    return isTimeDurationDisabled || (!isTimeDurationDisabled && isValidDate);
  };

  const getDataForViewAndEditReport = async () => {
    const viewOptions = {
      pageNumber: 1,
      pageSize: state.defaultPageSize,
      region: regionShortName,
      tableName: tableName === SELECT ? '' : tableName,
      commodity: commodity === SELECT ? '' : commodity,
      transactionType: transactionType === SELECT ? '' : transactionType,
      cptyDesc: cptyDesc === SELECT ? '' : cptyDesc,
      firstDate,
      lastDate,
    };
    dispatch({ type: 'setFilteredOptions', payload: viewOptions });
    makeApiToFetchViewEditReport(viewOptions);
    //clear the cpty value and options if the region is not Asia. Coz Asia customers want all the cpty to show and other customers needs to clear the searched cpty list.
    if (regionShortName !== ASIA_JAPAN_SHORT_NAME) {
      setCptyOptions([]);
    }
    setCptyDesc('');
  };

  const makeApiToFetchViewEditReport = async (viewOptions: {
    pageNumber: number;
    pageSize: number;
    region?: string;
    tableName: string;
    commodity: string;
    transactionType: string;
    cptyDesc: string;
    firstDate: string;
    lastDate: string;
  }) => {
    showSpinner(dispatch);
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const viewReport = await fetchReport('get', viewOptions)!;
      //disable GET DATA button if there is any record else keep it enabled
      if (viewReport.data.records.length > 0) {
        dispatch({ type: 'setIsGetData', payload: true });
      }
      dispatch({ type: 'setPaginationData', payload: viewReport.data.records });
      dispatch({
        type: 'setMasterPaginationData',
        payload: JSON.parse(JSON.stringify(viewReport.data.records)),
      });
      dispatch({ type: 'setRecordCount', payload: viewReport.data.count });
    } catch (e) {
      setIsFetchTableFailure(true);
    }
    hideSpinner(dispatch);
    dispatch({ type: 'setShowReport', payload: 'true' });
  };

  const checkCountryValidity = () => {
    return !isRegionAsia() || (isRegionAsia() && country !== SELECT);
  };

  const validateAllFields = () => {
    // check region validity
    const isRegionFieldValid = region !== SELECT;
    // check country validity
    const isCountryFieldValid = checkCountryValidity();
    // check Table Name validity
    const isTableNameFieldValid = tableName !== SELECT;
    // check Commodity Validity
    const isCommodityFieldValid = checkCommodityValidity();
    // check Delivery Month validity
    const isDelMonthFieldValid = checkDeliveryMonthValidity();
    //check Transaction Type validity
    const isTransTypeFieldValid = checkTransTypeValidity();
    // check counterparty short name validity
    const isCptyShortNameFieldValid = checkCounterPartyValidity();

    // make validation true or false for the fields
    setIsRegionValid(isRegionFieldValid);
    setIsCountryNameValid(isCountryFieldValid);
    setIsTableNameValid(isTableNameFieldValid);
    setIsCommodityValid(isCommodityFieldValid);
    setIsDateValid(isDelMonthFieldValid);
    setIsTransTypeValid(isTransTypeFieldValid);
    setIsCptyShortNameValid(isCptyShortNameFieldValid);

    // Store all the validation results in an array
    const dataAuditFieldValidations = [
      isRegionFieldValid,
      isCountryFieldValid,
      isDelMonthFieldValid,
    ];
    const dataEditFieldValidations = [
      isTableNameFieldValid,
      isCommodityFieldValid,
      isTransTypeFieldValid,
      isCptyShortNameFieldValid,
      ...dataAuditFieldValidations,
    ];

    const isDataEditValidationSuccess = dataEditFieldValidations.every(
      element => element === true
    );
    const isDataAuditValidationSuccess = dataAuditFieldValidations.every(
      element => element === true
    );

    return { isDataEditValidationSuccess, isDataAuditValidationSuccess };
  };

  const retrieveTableData = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch({ type: 'setTableSelected', payload: tableName });
    //setting cpty party field to error free before checking validity as it is a non-mandatory field when table name is I_Gas
    setIsCptyShortNameValid(true);
    const {
      isDataEditValidationSuccess,
      isDataAuditValidationSuccess,
    } = validateAllFields();
    if (isAuditReport && isDataAuditValidationSuccess) {
      getAuditReportData();
    } else if (!isAuditReport && isDataEditValidationSuccess) {
      getDataForViewAndEditReport();
    }
  };

  const onCalChange = (values: any) => {
    if (values !== null) {
      if (values[0] !== null) {
        setFirstDate(formatDate(values[0].toDate()));
      }
      if (values[1] !== null) {
        setLastDate(formatDate(values[1].toDate()));
      }
      if (values[0] !== null && values[1] !== null) {
        setIsDateValid(true);
      }
    } else {
      setFirstDate('');
      setLastDate('');
      setIsDateValid(false);
    }
  };

  const onSearch = debounce(async (query: string) => {
    const options = {
      tableName,
      keyword: query.toUpperCase(),
    };
    if (query.length > 1) {
      try {
        setIsSearchingCpty(true);
        const cptyDescSearch = await fetchCptySearchResult(options);
        const cptySearchResult = cptyDescSearch.data;
        setCptyOptions(cptySearchResult);
        setCptyDesc('');
      } catch (e) {
        //error statement
      }
      setIsSearchingCpty(false);
    }
  }, THOUSAND);

  const setRegionAndTableName = (regionName: string) => {
    const regionNameCaps = regionName.toUpperCase();

    if (regionName !== SELECT) {
      setIsRegionValid(true);
    }

    setRegion(regionName);
    dispatch({ type: 'setFilteredRegion', payload: regionNameCaps });

    // Map region names to their corresponding table options
    const regionToTablesMap: {
      [key: string]: { value: string; label: string }[];
    } = {
      [EUROPE]: filterOptions.tableNameOptionsEurope,
      [AMERICAS]: filterOptions.tableNameOptionsAmerica,
      [AUSTRALIA]: filterOptions.tableNameOptionsAustralia,
    };

    // Get tables for the selected region, or default to SELECT
    const tables = regionToTablesMap[regionNameCaps] || [
      { value: SELECT, label: SELECT },
    ];

    setTableNameOptions(tables);

    if (regionNameCaps) {
      const regionSrtName =
        REGION_SHORT_NAMES[regionNameCaps as keyof typeof REGION_SHORT_NAMES];
      setRegionShortName(regionSrtName);
    }
  };

  const handleRegionChange = (e: string) => {
    setCountry(SELECT);
    setTableName(SELECT);
    setTransactionType(SELECT);
    setCptyDesc('');
    setCommodity(SELECT);
    setRegionAndTableName(e);
  };

  const handleCountryChange = (e: string) => {
    if (e !== SELECT) {
      const japanTables = filterOptions.tableNameOptionsForJapan;
      // form filteredRegion for Asia
      const regionSrtName = `${regionShortName.substring(0, TWO)}-${
        REGION_SHORT_NAMES[e.toUpperCase() as keyof typeof REGION_SHORT_NAMES]
      }`;
      dispatch({
        type: 'setFilteredRegion',
        payload: regionSrtName.toUpperCase(),
      });
      setIsCountryNameValid(true);
      setTableNameOptions(japanTables);
      setCountry(e);
      setRegionShortName(regionSrtName);
      if (regionSrtName === ASIA_JAPAN_SHORT_NAME) {
        getCptyForAsia();
      }
    } else {
      setCountry(SELECT);
      setTableNameOptions(defaultSelect);
    }
  };

  const getCptyForAsia = async () => {
    showSpinner(dispatch);
    try {
      const asiaCptyResp = await fetchCptyForAsiaRegion(ASIA_JAPAN_SHORT_NAME);
      const asiaCptyOptns = asiaCptyResp.data;
      const asiaCptyOption = asiaCptyOptns.map(
        (optn: { CPTY: string }) => optn.CPTY
      );
      setCptyOptions(asiaCptyOption);
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        setIsServiceFailure(true);
      } else {
        setIsFetchOptionFailure(true);
      }
    }
    hideSpinner(dispatch);
  };

  const setUpFiltersForIalignAndIgroupTable = async (currTableName: string) => {
    showSpinner(dispatch);
    try {
      const cmdyOption: OptionI[] = getDefaultOptions();
      const commodities = await fetchCommodities(currTableName);
      const commodityOptns = commodities.data;
      if (commodityOptns.length === 0) {
        setIsServiceFailure(true);
      } else {
        for (const commodityOption of commodityOptns) {
          cmdyOption.push({
            value: commodityOption,
            label: commodityOption,
          });
        }
        setCommodityOptions(cmdyOption);
        setCommodity(cmdyOption[0].value);
      }
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        setIsServiceFailure(true);
      } else {
        setIsFetchOptionFailure(true);
      }
    }
    hideSpinner(dispatch);
  };

  const setUpFiltersForEndurIgasTable = () => {
    setCommodityOptions(commodityOptionGasTable);
    setCommodity(commodityOptionGasTable[0].value);
    //remove any error from counter party as its not available for I_Gas
    setIsCptyShortNameValid(true);
  };

  const setUpFiltersForNucleusIpowerTable = () => {
    setCommodityOptions(commodityOptionsPowerTable);
    setCommodity(commodityOptionsPowerTable[0].value);
  };

  const setUpFiltersForEndurSlmtIpowerTable = () => {
    setCommodityOptions(commodityOptionsPowerTable);
    setCommodity(commodityOptionsPowerTable[0].value);
  };

  const setUpFiltersForAustraliaTables = () => {
    setCommodityOptions(commodityOptionGasTable);
    setCommodity(commodityOptionGasTable[0].value);
  };

  const fetchOptionsForDealType = async (currTableName: string) => {
    if (
      isTableNameEitherEmptyOrIrrelevant(currTableName).isTableEmptyOrScopeTable
    ) {
      return;
    }
    showSpinner(dispatch);
    try {
      const dealType = await fetchDealType(currTableName);
      const dealOptns = dealType.data;
      if (dealOptns.length === 0) {
        setIsServiceFailure(true);
        hideSpinner(dispatch);
        return;
      }
      const dealOption = dealOptns.map((optn: string) => ({
        value: optn.toUpperCase(),
        label: optn.toUpperCase(),
      }));
      dealOption.unshift(defaultSelect[0]);
      setTransactionTypeOptions(dealOption);
      setTransactionType(dealOption[0].value);
      dispatch({ type: 'setBuySellOptions', payload: dealOption });
    } catch (error) {
      if (error.code === 'ERR_NETWORK') {
        setIsServiceFailure(true);
      } else {
        setIsFetchOptionFailure(true);
      }
    }
    hideSpinner(dispatch);
  };

  const handleTableNameChange = async (e: string) => {
    const currTableName = e;
    setTableName(currTableName);
    if (currTableName !== SELECT) {
      setIsTableNameValid(true);
      if ([I_ALIGNE_TABLE, I_GROUP_TABLE].includes(currTableName)) {
        setUpFiltersForIalignAndIgroupTable(currTableName);
      }

      if (currTableName === ENDUR_I_GAS_TABLE) {
        setUpFiltersForEndurIgasTable();
      }

      if (currTableName === NUCLEUS_I_POWER_TABLE) {
        setUpFiltersForNucleusIpowerTable();
      }

      if (currTableName === ENDURSLMT_I_POWER_TABLE) {
        setUpFiltersForEndurSlmtIpowerTable();
      }

      if ([ENDURAU_I_GAS, ENDURAU_STATIC_MAP_NCF].includes(currTableName)) {
        setUpFiltersForAustraliaTables();
      }
      // error statement
      fetchOptionsForDealType(currTableName);
    }
  };

  const handleCommodityChange = (e: string) => {
    setCommodity(e);
    setIsCommodityValid(true);
  };

  const handleTransTypeChange = (e: string) => {
    setTransactionType(e);
    setIsTransTypeValid(true);
  };

  const hasCommodityOptionOneRowOrTableNameEitherEmptyOrScopeTables = () => {
    const hasCommodityOptionOneRow = commodityOptions.length === 1;
    return hasCommodityOptionOneRow || isTableEmptyOrScopeTable;
  };

  const isTableNameEitherEmptyOrIrrelevant = (tableNameHere?: string) => {
    const tName = tableNameHere ?? tableName;
    const isTableEmpty = tName === SELECT || tName === '';
    const _isTableAmongScopeTables =
      tName === ENDUR_SEAM_REGION_SCOPE_TABLE ||
      tName === NUCLEUS_SEAM_REGION_SCOPE_TABLE ||
      tName === ENDURSLMT_STATIC_MAP_NCF_TABLE;
    const _isTableForAmericasRegion =
      tName === ENDUR_SEAM_REGION_SCOPE_TABLE ||
      tName === NUCLEUS_SEAM_REGION_SCOPE_TABLE;
    const _isTableForAustraliaScope = tName === ENDURAU_STATIC_MAP_NCF;
    const _isTableEmptyOrScopeTable =
      isTableEmpty || _isTableAmongScopeTables || _isTableForAustraliaScope;

    return {
      isTableEmpty,
      isTableAmongScopeTables: _isTableAmongScopeTables,
      isTableEmptyOrScopeTable: _isTableEmptyOrScopeTable,
      isTableForAmericasRegion: _isTableForAmericasRegion,
      isTableForAustraliaScope: _isTableForAustraliaScope,
    };
  };
  const {
    isTableAmongScopeTables,
    isTableEmptyOrScopeTable,
    isTableForAmericasRegion,
    isTableForAustraliaScope,
  } = isTableNameEitherEmptyOrIrrelevant();
  return (
    <div data-testid="filter">
      <Loader loader={loader} message={''} />
      <Accordion defaultActiveKey={['1']} fullWidth={true}>
        <AccordionPanel header="Filters" key="1" headerClass="class1">
          <Flexbox gap="24px 24px" style={{ marginTop: 15 }}>
            <InfoModal
              isOpen={isFetchOptionFailure}
              message="Unable to fetch Options Data. Please click OK to try again."
              setFuncs={{
                setIsFetchTableFailure,
                setIsServiceFailure,
                setIsFetchOptionFailure,
              }}
            />
            <InfoModal
              isOpen={isServiceFailure}
              message="Server is currently unavailable. Please try after some time."
              setFuncs={{
                setIsFetchTableFailure,
                setIsServiceFailure,
                setIsFetchOptionFailure,
              }}
            />
            <InfoModal
              isOpen={isFetchTableFailure}
              message="Unable to fetch Table Data. Please try again after some time."
              setFuncs={{
                setIsFetchTableFailure,
                setIsServiceFailure,
                setIsFetchOptionFailure,
              }}
            />
            <FormField id="region" label="Region" mandatory={true}>
              <Select
                size="medium"
                allowClear={false}
                options={updatedRegionOptions}
                value={region}
                onChange={(e: string) => handleRegionChange(e)}
                invalid={!isRegionValid}
                disabled={isOneRegionOption}
                data-testid="region"
              />
            </FormField>
            <FormField id="country" label="Country" mandatory={isRegionAsia()}>
              <Select
                size="medium"
                allowClear={false}
                options={countryOptions}
                value={country}
                onChange={(e: string) => handleCountryChange(e)}
                invalid={!isCountryNameValid}
                disabled={region.toUpperCase() !== ASIA}
                data-testid="country"
              />
            </FormField>
            {isViewReport && (
              <FormField id="tableName" label="Table Name" mandatory={true}>
                <Select
                  id="tableNameId"
                  value={tableName}
                  allowClear={false}
                  options={tableNameOptions}
                  onChange={(e: string) => handleTableNameChange(e)}
                  invalid={!isTableNameValid}
                  data-testid="tablename"
                />
              </FormField>
            )}
            {isViewReport && (
              <FormField
                id="commodity"
                label="Commodity"
                mandatory={
                  !hasCommodityOptionOneRowOrTableNameEitherEmptyOrScopeTables()
                }
              >
                <Select
                  size="medium"
                  allowClear={false}
                  value={commodity}
                  id="commodityId"
                  options={commodityOptions}
                  disabled={hasCommodityOptionOneRowOrTableNameEitherEmptyOrScopeTables()}
                  invalid={!isCommodityValid}
                  onChange={handleCommodityChange}
                  defaultValue={commodityOptions[0].value}
                  data-testid="commodity"
                />
              </FormField>
            )}
          </Flexbox>
          <Flexbox gap="24px 24px">
            <FormField
              id="duration"
              label={isViewReport ? 'Delivery Month' : 'Time Duration'}
              mandatory={!isTableAmongScopeTables}
              bottomLeftHelper={{
                content: 'Start Date must not be a future date',
                sentiment: Sentiments.Positive,
              }}
            >
              <RangeDatePicker
                disabledDate={
                  isRegionAsia() ? disableAsiaDateRange : disabledFutureDate
                }
                aria-label="Flights"
                id="deliveryMonthId"
                placeholder={['Start date', 'Finish date']}
                showToday={false}
                onCalendarChange={onCalChange}
                disabled={isTableAmongScopeTables || isTableForAustraliaScope}
                invalid={!isDateValid}
                data-testid="deliverymonth"
                elevation={true}
              />
            </FormField>
            {isViewReport && (
              <FormField
                id="transtype"
                label="Transaction Type"
                mandatory={
                  !isTableEmptyOrScopeTable || isTableForAustraliaScope
                }
              >
                <Select
                  size="medium"
                  allowClear={false}
                  id="transactionTypeId"
                  options={transactionTypeOptions}
                  disabled={isTableEmptyOrScopeTable}
                  value={transactionType}
                  invalid={!isTransTypeValid}
                  onChange={handleTransTypeChange}
                  defaultValue={defaultSelect[0].value}
                  data-testid="transactiontype"
                />
              </FormField>
            )}
            {isViewReport && (
              <FormField
                id="cptyDesc"
                label="Counterparty Short Name"
                mandatory={!isTableForAmericasRegion}
              >
                {isRegionAsia() && country.toUpperCase() === JAPAN ? (
                  <Select
                    size="medium"
                    allowClear={false}
                    id="counterPartyId"
                    value={cptyDesc}
                    data-testid="counterparty"
                    onChange={(e: string) => {
                      setCptyDesc(e);
                      setIsCptyShortNameValid(true);
                    }}
                    invalid={!isCptyShortNameValid}
                  >
                    {cptyOptions.map((val: string) => {
                      return (
                        <Option key={val} value={val}>
                          {val}
                        </Option>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    size="medium"
                    allowClear={false}
                    placeholder="Please type FIRST 2 characters to search"
                    onSearch={onSearch}
                    id="counterPartyId"
                    loading={isSearchingCpty}
                    value={cptyDesc}
                    data-testid="counterparty"
                    onChange={(e: string) => {
                      setCptyDesc(e);
                      setIsCptyShortNameValid(true);
                    }}
                    disabled={
                      isTableForAmericasRegion || isTableForAustraliaScope
                    }
                    autoClearSearchValue={false}
                    invalid={!isCptyShortNameValid}
                  >
                    {cptyOptions.map((val: string) => {
                      return (
                        <Option key={val} value={val}>
                          {val}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormField>
            )}
          </Flexbox>
          {region === 'Americas' && isViewReport && (
            <Flexbox gap="24px 24px">
              <Text size="small" style={{ color: 'orange' }}>
                <em>
                  Disabled field(s) is/are not mandatory for current selections.
                </em>
              </Text>
            </Flexbox>
          )}
          <Flexbox gap="24px 24px" justifyContent="center">
            <Button
              className="getDataBtn"
              disabled={state.isGetData}
              onClick={retrieveTableData}
              data-testid="getDataBtn"
            >
              Get Data
            </Button>
          </Flexbox>
        </AccordionPanel>
      </Accordion>
    </div>
  );
};

export default FilterSection;
