import { Alert, Sentiments } from '@sede-x/shell-ds-react-framework';
import React from 'react';

export const FileProcessingToast = ({
  runReportMessage,
}: {
  runReportMessage: string;
}) => {
  return (
    runReportMessage !== '' && (
      <Alert
        sentiment={Sentiments.Information}
        style={{ marginBottom: '20px', width: 'auto', marginTop: '20px'}}
        elevation
      >
        {runReportMessage}
      </Alert>
    )
  );
};
