import { Flexbox, Grid, Modal, Text } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, SetStateAction } from 'react';

export const SectoralServerErrorPage = ({
  isServerError,
  setIsServerError,
}: {
  isServerError: boolean;
  setIsServerError: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Modal
      showHeader={false}
      open={isServerError}
      onClose={() => setIsServerError(false)}
    >
      <Grid justifyContent="center">
        <Grid.Cell>
          <img src="../shell-logo.png" alt="secm" style={styles.img} />
        </Grid.Cell>
        <Grid.Cell>
          <Flexbox justifyContent="center">
            <Text size="large">Error</Text>
          </Flexbox>
        </Grid.Cell>
        <Grid.Cell>
          <Flexbox justifyContent="center">
            Oops, something went wrong. Please try again later.
          </Flexbox>
        </Grid.Cell>
      </Grid>
    </Modal>
  );
};

const styles = {
  title: {
    margin: '40px',
  },
  img: {
    height: '60px',
    margin: '20px auto',
    display: 'block',
  },
};
