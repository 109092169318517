import jwt_decode from 'jwt-decode';
import { Action, AuthResponse } from '../../types/types';
import {
  AUSTRALIA,
  EUROPE,
  GLOBAL,
  SPECIFIC_REGIONS,
  SUPPORT,
  TWO,
} from '../../api/constants';
import { AuthService } from '../AuthService/AuthService';
import { Dispatch } from 'react';

export const setIsGlobalUser = (
  userRegion: string[],
  dispatch: Dispatch<Action>
) => {
  const isRegionGlobal = userRegion.includes(GLOBAL);
  dispatch({ type: 'setIsGlobalUser', payload: isRegionGlobal });
};

export const login = async () => {
  const authService = new AuthService();
  await authService.login();
};

export const logout = async () => {
  const authService = new AuthService();
  await authService.logout();
};

export const setUpRoleBasedAccess = (
  roles: string[],
  userId: string,
  email: string,
  dispatch: Dispatch<Action>,
  isProdEnv: boolean
) => {
  let userRole = [];
  let userRegions: string[] = [];
  let isRoleNcf = false;

  const { role: userRoleFromFunction, regions, isNcf } = getUserRoleAndRegion(
    roles
  );
  userRole = userRoleFromFunction;
  userRegions = regions;
  isRoleNcf = isNcf;

  // set up logged in user role
  const updatedUserRole = userRole.length === 0 ? ['unauthorized'] : userRole;
  dispatch({
    type: 'setRole',
    payload: updatedUserRole,
  });
  dispatch({ type: 'setIsRoleNcf', payload: isRoleNcf });

  //set up region and environment and respecitve access details
  const isUserOfSpecificRegion = SPECIFIC_REGIONS.some(value =>
    userRegions.includes(value)
  );
  const isPpaUser = userRegions.includes('PPA');
  const isGlobalUser = userRegions.includes(GLOBAL);
  const isAustraliaUser =
    userRegions.length === 1 && userRegions.includes(AUSTRALIA); // if only Australia User
  const isEuropeUser = userRegions.includes(EUROPE);
  const isGlobalUserInNonProd = isGlobalUser && !isProdEnv;
  const isSupportUser = userRegions.includes(SUPPORT);
  const isSupportUserInNonProd = isSupportUser && !isProdEnv;
  const showAllRegions = isGlobalUser || isSupportUser;
  // configure rule page is only for Europe users in prod or support/global users in non-prod environments
  const showConfigRulePage = isEuropeUser || isSupportUser || isGlobalUser;
  // USER OF SPECIFIC REGION or USER OF SUPPORT TEAM/ GLOBAL IN NON PROD
  const isUserOfSpecRegOrSupportOrGlobalInNonProd =
    isUserOfSpecificRegion || isSupportUserInNonProd || isGlobalUserInNonProd;
  const showSectoralPage = isGlobalUser || isSupportUser;

  dispatch({ type: 'setShowAllRegions', payload: showAllRegions });
  dispatch({ type: 'setShowConfigRulePage', payload: showConfigRulePage });
  dispatch({ type: 'setIsAustraliaUser', payload: isAustraliaUser });
  dispatch({ type: 'setShowSectoralPage', payload: showSectoralPage });
  dispatch({ type: 'setIsPpaUser', payload: isPpaUser });

  setIsGlobalUser(userRegions, dispatch);
  // only USER OF SPECIFIC REGION or USER OF SUPPORT TEAM or USER OF OTHER REGION IN NON-PROD can manipulate (CREATE / UPDATE / DELETE) data
  const canUserManipulateData =
    isUserOfSpecificRegion || isSupportUser || isGlobalUserInNonProd;
  dispatch({
    type: 'setCanUserManipulateData',
    payload: canUserManipulateData,
  });
  const canUserChangeSnapshotStatus = canUserManipulateData;
  dispatch({
    type: 'setCanUserAddAndSubmitSnapshot',
    payload: isUserOfSpecRegOrSupportOrGlobalInNonProd,
  });
  dispatch({
    type: 'setCanUserChangeSnapshotStatus',
    payload: canUserChangeSnapshotStatus,
  });
  dispatch({
    type: 'setShowSnapshotActions',
    payload: isUserOfSpecRegOrSupportOrGlobalInNonProd,
  });

  // set user details like mail, username and region
  dispatch({
    type: 'setMail',
    payload: email,
  });
  dispatch({
    type: 'setUsername',
    payload: userId,
  });
  dispatch({
    type: 'setUserRegions',
    payload: userRegions,
  });
};

export const getUser = (dispatch: Dispatch<Action>, isProdEnv: boolean) => {
  const authService = new AuthService();
  authService
    .getUser()
    .then(user => {
      if (user) {
        const currentUser: AuthResponse = jwt_decode(user.access_token);
        const roles = currentUser.realm_access.roles.map((role: string) =>
          role.toUpperCase()
        );
        const email = currentUser.email.toUpperCase();
        const userId = currentUser.preferred_username.toUpperCase();
        setUpRoleBasedAccess(roles, userId, email, dispatch, isProdEnv);
      } else {
        //console : not logged in
      }
    })
    .catch(() => {
      //error statement
    });
};

export const getUserRoleAndRegion = (roles: string[]) => {
  const tansoRole: string[] = roles.filter((item: string) =>
    item.startsWith('TANSO')
  );
  let userRoleArray: string[][] = [];
  let userRegions: string[] = [];
  let isTansoRoleNcf = false;

  if (tansoRole.length > 0) {
    userRoleArray =
      tansoRole.length > 0
        ? tansoRole.map((role: string) => role.split('_'))
        : [];
    userRegions =
      userRoleArray.length > 0
        ? userRoleArray.map(item => item[TWO].toUpperCase())
        : [];
    isTansoRoleNcf = userRoleArray.some(subArray => subArray[1] === 'NCF');
  }

  return {
    role: tansoRole,
    regions: userRegions,
    isNcf: isTansoRoleNcf,
  };
};
