import { Action } from '../../types/types';
import { IAnalyticsSnapshotReportType } from '../../types/IAnalyticsSnapshotReportType';

const AnalyticsSnapshotReportActionHandlers: Record<
  string,
  (
    // eslint-disable-next-line no-unused-vars
    state: IAnalyticsSnapshotReportType,
    payload: any
  ) => IAnalyticsSnapshotReportType
> = {
  setIsError: (state, payload) => ({
    ...state,
    error: {
      ...state.error,
      isError: payload.isError,
      message: payload.message,
    },
  }),
  setAnalyticsSnapshotReportChartData: (state, payload) => ({
    ...state,
    analyticsSnapshotReportChartData: payload,
  }),
  setAnalyticsSnapshotReportTableData: (state, payload) => ({
    ...state,
    analyticsSnapshotReportTableData: payload,
  }),
  setAnalyticsSnapshotReportFilterOptions: (state, payload) => ({
    ...state,
    analyticsSnapshotReportFilterOptions: {
      ...state.analyticsSnapshotReportFilterOptions,
      ...payload,
    },
  }),
  setAnalyticsSnapshotReportFilteredValues: (state, payload) => {
    return {
      ...state,
      analyticsSnapshotReportFilteredValues: payload,
    };
  },
  setAnalyticsSnapshotVolumeByRegionChartData: (state, payload) => ({
    ...state,
    volumeByRegionChartData: {
      ...state.volumeByRegionChartData,
      ...payload,
    },
  }),
  setAnalyticsSnapshotVolumeByRegionTableData: (state, payload) => ({
    ...state,
    volumeByRegionTableData: {
      ...state.volumeByRegionTableData,
      ...payload,
    },
  }),
  setAnalyticsSnapshotReportsData: (state, payload) => ({
    ...state,
    analyticsSnapshotReportFilterOptions: {
      ...state.analyticsSnapshotReportFilterOptions,
      snapshotReports: payload,
    },
  }),
  setRegionVolumeDetailsTableData: (state, payload) => {
    return {
      ...state,
      volumeByRegionDetailsTableData: payload,
    };
  },
};
export const AnalyticsSnapshotReportReducers = (
  state: IAnalyticsSnapshotReportType,
  action: Action
) => {
  if (Object.hasOwn(AnalyticsSnapshotReportActionHandlers, action.type)) {
    const handler = AnalyticsSnapshotReportActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
