import {
  getAnalyticsSnapshotReportLineChartData,
  getAnalyticsNcfSnapshotReportTableData,
} from '../../../../../api/services';
import { TooltipFormatterContextObject } from 'highcharts';
import {
  IAnalyticsSnapshotReportType,
  AnalyticsSnapshotReportLineChartItem,
  ISeriesData,
} from '../../../../../types/IAnalyticsSnapshotReportType';

export const getCommodityName = (abbreviation: string) => {
  switch (abbreviation) {
    case 'PW':
      return 'Power';
    case 'NG':
      return 'Natural Gas';
    default:
      return 'Power';
  }
};

export const fetchDataBasedOnView = async (
  view: string,
  analyticsState: IAnalyticsSnapshotReportType
) => {
  let response;
  try {
    if (view === 'Table') {
      response = await getAnalyticsNcfSnapshotReportTableData(
        analyticsState.analyticsSnapshotReportFilteredValues
      );
    } else {
      response = await getAnalyticsSnapshotReportLineChartData(
        analyticsState.analyticsSnapshotReportFilteredValues
      );
    }
  } catch (error) {
    // Log error once toast feature is implemented
  }
  return response;
};

export const getLineChartCategories = (
  data: AnalyticsSnapshotReportLineChartItem[]
): string[] => {
  return Array.from(
    new Set(
      data.map(
        (item: AnalyticsSnapshotReportLineChartItem) =>
          `${item.YEAR} ${item.QUARTER} ${item.MONTH}`
      )
    )
  );
};

export const getSeriesData = (
  input: AnalyticsSnapshotReportLineChartItem[]
): ISeriesData[] => {
  // First, identify unique year-quarter-month combinations and regions to ensure the output data arrays are correctly sized
  const uniqueYearQuarterMonths = [
    ...new Set(
      input.map(
        (item: AnalyticsSnapshotReportLineChartItem) =>
          `${item.YEAR}${item.QUARTER}${item.MONTH}`
      )
    ),
  ];
  const uniqueRegions = [
    ...new Set(
      input.map((item: AnalyticsSnapshotReportLineChartItem) => item.REGION)
    ),
  ];

  // Initialize the output structure with regions and empty data arrays
  const output = uniqueRegions.map(region => ({
    name: region,
    data: Array.from({ length: uniqueYearQuarterMonths.length }, () => 0), // Fill with 0s to match the number of unique year-quarter-month combinations
  }));

  // Populate the data arrays based on the input
  input.forEach(
    ({
      YEAR,
      QUARTER,
      MONTH,
      QTY,
      REGION,
    }: AnalyticsSnapshotReportLineChartItem) => {
      const yqmIndex = uniqueYearQuarterMonths.indexOf(
        `${YEAR}${QUARTER}${MONTH}`
      ); // Find the index of the year-quarter-month combination
      const regionIndex = output.findIndex(item => item.name === REGION); // Find the index of the region in the output
      if (regionIndex !== -1 && yqmIndex !== -1) {
        output[regionIndex].data[yqmIndex] += QTY; // Aggregate the quantity
      }
    }
  );

  return [...output].map(region => {
    let sum = 0;
    return {
      name: region.name,
      data: region.data.map(value => (sum += value)),
      dataLabels: {
        enabled: true,
        format: '{point.y:.2f}',
      },
    };
  });
};

export const tooltipFormatter = (
  context: TooltipFormatterContextObject,
  mapData: AnalyticsSnapshotReportLineChartItem[]
) => {
  const region: string = context?.series?.name ?? '';
  const x: string | number = context.x ?? '';
  const ncf: number = context.y ?? 0;
  const [YEAR, QUARTER, MONTH] = (x as string).split(' ');
  const transactionType: string | undefined = mapData?.find(
    data =>
      data.QUARTER === QUARTER &&
      data.MONTH === MONTH &&
      data.YEAR === Number(YEAR) &&
      data.REGION === region
  )?.Original_Transaction_Type;

  return `<b>Region:</b> ${region}<br/>${
    transactionType ? `<b>Transaction Type:</b> ${transactionType}<br/>` : ''
  }<b>QTY:</b> ${ncf}`;
};

export const generateColumns = () => {
  const columnNames = [
    'YEAR',
    'QUARTER',
    'MONTH',
    'EUROPE',
    'AMERICAS',
    'ASIA',
  ];

  return columnNames.map(key => ({
    header: key,
    accessorKey: key,
  }));
};
