import {
  Button,
  Flexbox,
  Grid,
  Heading,
  Pecten,
  Sentiments,
} from '@sede-x/shell-ds-react-framework';
import React from 'react';
import {
  BackgroundVideo,
  GridContainer,
  Login,
  LoginContainer,
  LoginGrid,
} from '../AppContent/AppContent.styles';

const LoginPage = ({ login }: { login: () => void }) => {
  return (
    <LoginContainer data-testid="login-page">
      <BackgroundVideo autoPlay loop muted>
        <source src="/pulsating-pecten.mp4" type="video/mp4" />
      </BackgroundVideo>
      <LoginGrid justifyContent="center" alignItems="center">
        <Login>
          <Grid gap="24px" areas={['blank1', 'login', 'blank2']}>
            <Grid.Cell area="blank1" />
            <Grid.Cell area="login">
              <Flexbox justifyContent="center">
                <GridContainer>
                  <Grid
                    gap="24px"
                    areas={[
                      'spinner',
                      'pecten',
                      'signonText',
                      'authText',
                      'button',
                    ]}
                  >
                    <Grid.Cell area="pecten">
                      <Flexbox justifyContent="center">
                        <Pecten size="medium" />
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area="signonText">
                      <Flexbox justifyContent="center">
                        <Heading type="h1">
                          Single sign-on to <strong>Tanso NCF Report</strong>
                        </Heading>
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area="authText">
                      <Flexbox justifyContent="center">
                        <p>
                          Authenticate your account by logging into Shell's
                          single sign-on provider.
                        </p>
                      </Flexbox>
                    </Grid.Cell>
                    <Grid.Cell area="button">
                      <Flexbox justifyContent="center">
                        <Button
                          sentiment={Sentiments.Positive}
                          onClick={login}
                          size="medium"
                          data-testid="login-button"
                        >
                          Continue
                        </Button>
                      </Flexbox>
                    </Grid.Cell>
                  </Grid>
                </GridContainer>
              </Flexbox>
            </Grid.Cell>
            <Grid.Cell area="blank2" />
          </Grid>
        </Login>
      </LoginGrid>
    </LoginContainer>
  );
};

export default LoginPage;
