import { Action, IReconData } from '../../types/types';

const ReconActionHandlers: Record<
  string,
  (state: IReconData, payload: any) => IReconData
> = {
  setReconFilterOptions: (state, payload) => ({
    ...state,
    ReconfilterOptions: payload,
  }),
  setReconFilteredValues: (state, payload) => ({
    ...state,
    ReconfilteredValues: payload,
  }),
  setReconReportTotalVolume: (state, payload) => ({
    ...state,
    reconReportTotalVolume: payload,
  }),
  setReconReportDefaultTotalVolume: (state, payload) => ({
    ...state,
    reconReportDefaultTotalVolume: payload,
  }),
  setReconReportVolumeTableData: (state, payload) => ({
    ...state,
    reconReportVolumeTableData: payload,
  }),
  setReconReportNcfCategoryMismatchTableData: (state, payload) => ({
    ...state,
    reconReportNcfCategoryMismatchTableData: payload,
  }),
  setReconReportLastRefreshedDate: (state, payload) => ({
    ...state,
    reconReportLastRefreshedDate: payload,
  }),
};

export const ReconReportsReducers = (state: IReconData, action: Action) => {
  if (Object.hasOwn(ReconActionHandlers, action.type)) {
    const handler = ReconActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};