import instance from './instance';
import { API_VERSION_ONE, API_VERSION_TWO } from './constants';
import {
  IConfigRuleNewRecord,
  IFetchSnapshotDataOption,
  IOptions,
  IppaInsightFilteredValues,
  RuleConfigDataI,
} from '../types/types';

export const fetchReport = (
  method: string,
  options: {
    pageNumber: number;
    pageSize: number;
    firstDate: string;
    lastDate: string;
    transactionType: string;
    commodity: string;
    cptyDesc: string;
    tableName: string;
  }
) => {
  const getUrl = `${API_VERSION_ONE}records/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&deliveryMonthStart=${options.firstDate}&deliveryMonthEnd=${options.lastDate}&transactionType=${options.transactionType}&commodity=${options.commodity}&counterparty=${options.cptyDesc}&tableName=${options.tableName}`;
  const postUrl = `${API_VERSION_ONE}records/`;
  let result;
  if (method === 'get') {
    result = instance.get(getUrl);
  } else if (method === 'put') {
    result = instance.put(postUrl, options);
  }
  return result;
};

export const fetchMarketOptions = (tableName: string) => {
  const url = `${API_VERSION_ONE}markets/?tableName=${tableName}`;
  return instance.get(url);
};

export const fetchNcfCategoriesOptions = (tableName: string) => {
  const url = `${API_VERSION_ONE}ncf-categories/?tableName=${tableName}`;
  return instance.get(url);
};

export const fetchNcfCategoriesForAsiaAndAmericaRegion = (region: string) => {
  const url = `${API_VERSION_TWO}ncf-categories-by-region/?region=${region}`;
  return instance.get(url);
};

export const fetchAuditReport = (
  options: Record<string, number | Date | string>
) => {
  const url = `${API_VERSION_ONE}audit-logs/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&startDate=${options.startDate}&endDate=${options.endDate}&region=${options.region}`;
  return instance.get(url);
};

export const fetchCommodities = (tableName: string) => {
  const url = `${API_VERSION_ONE}commodities/?tableName=${tableName}`;
  return instance.get(url);
};

export const fetchCptyForAsiaRegion = (region: string) => {
  const url = `${API_VERSION_ONE}counterparties-by-region/?region=${region}`;
  return instance.get(url);
};

export const fetchCpty = (options: {
  pageNumber: number;
  pageSize: number;
  tableName: string;
}) => {
  const url = `${API_VERSION_ONE}counterparties/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&tableName=${options.tableName}`;
  return instance.get(url);
};

export const fetchCountryForSeamRegionTableEdit = (region: string) => {
  const url = `${API_VERSION_ONE}countries-by-region/?region=${region}`;
  return instance.get(url);
};

export const fetchCptySearchResult = (options: {
  tableName: string;
  keyword: string;
}) => {
  const url = `${API_VERSION_ONE}search-counterparties/?tableName=${options.tableName}&keyword=${options.keyword}`;
  return instance.get(url);
};

export const fetchMarketSearchResult = (options: {
  tableName: string;
  keyword: string;
}) => {
  const url = `${API_VERSION_ONE}search-markets/?tableName=${options.tableName}&keyword=${options.keyword}`;
  return instance.get(url);
};

export const fetchDealType = (tableName: string) => {
  const url = `${API_VERSION_ONE}deal-types/?tableName=${tableName}`;
  return instance.get(url);
};

export const fetchRegion = () => {
  const url = `${API_VERSION_ONE}regions`;
  return instance.get(url);
};

export const fetchSnapshotData = (options: IFetchSnapshotDataOption) => {
  let url = `${API_VERSION_ONE}ncf-snapshots/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}`;
  if (options.region) {
    url += `&region=${options.region}`;
  }
  return instance.get(url);
};

export const generateNcfSnapshotName = (options: {
  region: string | undefined;
  commodity: string;
  year: string;
  timePeriod: string;
  unitOfTime: string;
  snapshotStatus?: string;
}) => {
  const url = `${API_VERSION_ONE}generate-ncf-snapshot/`;
  return instance.post(url, options);
};

export const updateSnapshotStatus = (options: {
  ncfSnapshotIds: number[];
  snapshotStatus: string;
}) => {
  const url = `${API_VERSION_ONE}ncf-snapshots-bulk-update/`;
  return instance.put(url, options);
};

export const fetchStorage = (tableName: string) => {
  const url = `${API_VERSION_ONE}storages/?tableName=${tableName}`;
  return instance.get(url);
};

export const fetchConfigRuleReport = (options: RuleConfigDataI) => {
  const url = `${API_VERSION_ONE}rule-configs/?pageNumber=${options.pageNumber}&pageSize=${options.pageSize}&tradeType=${options.tradeType}&cptyShortName=${options.cptyShortName}`;
  return instance.get(url);
};

export const addConfigRule = (options: IConfigRuleNewRecord) => {
  const url = `${API_VERSION_ONE}rule-configs/`;
  return instance.post(url, options);
};

export const authorizeUser = (userid: number) => {
  const url = `${API_VERSION_ONE}user-profile/${userid}`;
  return instance.get(url);
};

export const deleteConfigRule = (id: number) => {
  const url = `${API_VERSION_ONE}rule-configs/${id}`;
  return instance.delete(url);
};

const step01LogUrl = `${API_VERSION_ONE}sectoral-cpty-list-extract-log/`;
const step02LogUrl = `${API_VERSION_ONE}sectoral-upload-mapping-sheets-log/`;
const step03LogUrl = `${API_VERSION_ONE}sectoral-produce-output-log`;
const downloadSecotrWiseNcfVolumes = `${API_VERSION_ONE}download-sector-wise-ncf-volumes/`;
const downloadCptyWiseNcfVolumes = `${API_VERSION_ONE}download-cpty-wise-ncf-volumes/`;
const downloadEntireCtpyListUrl = `${API_VERSION_ONE}download-mapped-cpty-list`;
const downloadUnmappedCtpyListUrl = `${API_VERSION_ONE}download-unmapped-cpty-list`;
const uploadSectoralUrl = `${API_VERSION_ONE}sectoral-file-upload/`;
const lastRefeshUrl = `${API_VERSION_ONE}ncf-volume-last-refresh/`;
const ncfByCountryUrl = `${API_VERSION_ONE}ncf-volume-by-country/`;
const ncfQuarterWiseDataUrl = `${API_VERSION_ONE}ncf-volume-by-transaction-quarter-wise-data/`;
const ncfMonthWiseDataUrl = `${API_VERSION_ONE}ncf-volume-by-transaction-month-wise-data/`;
const ncfTableDataMonthlyUrl = `${API_VERSION_ONE}ncf-volume-by-transaction-month-wise-table-data/`;

const filterDataUrl = `${API_VERSION_ONE}ncf-volume-filter-data/`;
const ciTradeChartAPIUrl = `${API_VERSION_ONE}ci-trade-chart-data/`;
const ciTradeTableAPIUrl = `${API_VERSION_ONE}ci-trade-table-data/`;

const topCounterPartyUrl = `${API_VERSION_ONE}analytics-top-cpty-data`;
const counterPartyFilterUrl = `${API_VERSION_ONE}analytics-filter-data`;

const volumeByRegionChartAPIUrl = `${API_VERSION_ONE}analytics-volume-by-region-chart-data/`;
const volumeByRegionTableAPIUrl = `${API_VERSION_ONE}analytics-volume-by-region-table-data/`;
const regionVolumeDetailsTableApiUrl = `${API_VERSION_ONE}analytics-volume-by-region-details/`;

//Analytics snapshot APIs
const AnalyticsSnapshotReportLineChartApiUrl = `${API_VERSION_ONE}get-analytics-ncf-snapshot-line-chart-data/`;
const AnalyticsNcfSnapshotTableApiUrl = `${API_VERSION_ONE}get-analytics-ncf-snapshot-table-data/`;
const AnalyticsSnapshotVolumeByRegionChartApiUrl = `${API_VERSION_ONE}analytics-snapshot-volume-by-region-chart-data/`;
const AnalyticsSnapshotVolumeByRegionTableApiUrl = `${API_VERSION_ONE}analytics-snapshot-volume-by-region-table-data/`;
const AnalyticsSnapshotReportsApiUrl = `${API_VERSION_ONE}analytics-snapshot-reports/`;
const AnalyticsSnapshotVolumeByRegionDetailsApiUrl = `${API_VERSION_ONE}analytics-snapshot-volume-by-region-details/`;

// PPT APIs
const pptNcfVolumeUrl = `${API_VERSION_ONE}analytics-ppt-ncf-volumes/`;
const pptNcfCategoryUrl = `${API_VERSION_ONE}analytics-ppt-ncf-categories/`;
const pptReportsUrl = `${API_VERSION_ONE}ppt-reports/`;
const biaValidationCheckApiUrl = `${API_VERSION_ONE}data-validation-check/`;
const biaUncatCptyApiUrl = `${API_VERSION_ONE}get-uncategorised-cpty/`;
const biaInactiveCptyApiUrl = `${API_VERSION_ONE}get-inactive-cpty-for-active-volume/`;
const biaGroupAffApiUrl = `${API_VERSION_ONE}get-list-of-group-affiliate/`;

// Reconciliation Reports APIs
const reconReportTotalVolumeApiUrl = `${API_VERSION_ONE}recon-report-total-volume/`;
const reconReportVolumeTableDataApiUrl = `${API_VERSION_ONE}recon-report-volume-table-data/`;
const reconReportNcfCategoryMismatchTableDataApiUrl = `${API_VERSION_ONE}recon-report-ncf-category-mismatch-table-data/`;
const reconReportLastRefreshedDateApiUrl = `${API_VERSION_ONE}recon-report-last-refreshed-date/`;
const reconFilterOptionsApiUrl = `${API_VERSION_ONE}recon-report-filter/`;



/*
on load
sectoral-cpty-list-extract-log/ - for first card
sectoral-upload-mapping-sheets-log/ - for second card
sectoral-produce-output-log_ - for third card
*/
export const getStep01Log = () => {
  return instance.get(step01LogUrl);
};
export const getStep02Log = () => {
  return instance.get(step02LogUrl);
};
export const getStep03Log = () => {
  return instance.get(step03LogUrl);
};

export const downloadEntireCtpyList = () => {
  const url = downloadEntireCtpyListUrl;
  return instance.get(url);
};

export const downloadUnmappedCtpyList = (year: number, qtr: number) => {
  const url = `${downloadUnmappedCtpyListUrl}/?year=${year}&quarter=${qtr}`;
  return instance.get(url);
};

export const uploadSectoralFiles = (formData: FormData) => {
  const url = uploadSectoralUrl;
  return instance.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadSecotrWiseNcfVol = (
  startYear: string,
  startMonth: string,
  endYear: string,
  endMonth: string
) => {
  const url = `${downloadSecotrWiseNcfVolumes}/?startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`;
  return instance.get(url);
};

export const downloadCptyWiseNcfVol = (
  startYear: string,
  startMonth: string,
  endYear: string,
  endMonth: string
) => {
  const url = `${downloadCptyWiseNcfVolumes}/?startYear=${startYear}&startMonth=${startMonth}&endYear=${endYear}&endMonth=${endMonth}`;
  return instance.get(url);
};

export const lastRefesh = () => {
  const url = lastRefeshUrl;
  return instance.get(url);
};

export const ncfByCountry = (formData: object) => {
  const url = ncfByCountryUrl;
  return instance.post(url, formData, {});
};

export const ncfMonthWiseData = (formData: object) => {
  const url = ncfMonthWiseDataUrl;
  return instance.post(url, formData, {});
};

export const ncfQuarterWiseData = (formData: object) => {
  const url = ncfQuarterWiseDataUrl;
  return instance.post(url, formData, {});
};

export const ncfTableDataMonthly = (formData: object) => {
  const url = ncfTableDataMonthlyUrl;
  return instance.post(url, formData, {});
};

export const filterData = () => {
  const url = filterDataUrl;
  return instance.get(url);
};

export const ciTradeChartData = (formData: object) => {
  return instance.post(ciTradeChartAPIUrl, formData, {});
};

export const ciTradeTableData = (formData: object) => {
  return instance.post(ciTradeTableAPIUrl, formData, {});
};

export const topCounterParty = (formData: object) => {
  return instance.post(topCounterPartyUrl, formData, {});
};

export const counterPartyFilter = () => {
  return instance.get(counterPartyFilterUrl);
};

// APIs for PPA Insight
interface PPAOptions {
  pageNumber?: number;
  pageSize?: number;
  year?: number;
  quarter?: string[];
  region?: string[];
  renewableCategory?: string[];
  tenure?: number[];
  contractType?: string[];
}

const generateUrl = (
  base: string,
  options: PPAOptions | IppaInsightFilteredValues
) => {
  let url = `${API_VERSION_ONE}${base}/?`;
  Object.entries(options).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(val => {
        url += `${key}=${val}&`;
      });
    } else if (value) {
      url += `${key}=${value}&`;
    }
  });
  return url.slice(0, -1); // Remove the trailing '&'
};

export const getPpaInsightFormFilterOptions = () => {
  const url = `${API_VERSION_ONE}ppa-filter-form-input-data`;
  return instance.get(url);
};

export const getPpaInsightVolumeByRegionData = (options: IOptions) => {
  const url = generateUrl('ppa-volume-by-region', options);
  return instance.get(url);
};

export const getPpaInsightLongVsShortTermData = (options: IOptions) => {
  const url = generateUrl('ppa-long-term-vs-short-term-deals', options);
  return instance.get(url);
};

export const getPpaInsightVolByTechTypeData = (options: IOptions) => {
  const url = generateUrl('ppa-volume-by-technology-type', options);
  return instance.get(url);
};

export const getPpaInsightTableData = (options: IOptions) => {
  const url = generateUrl('ppa-insights-table-data', options);
  return instance.get(url);
};

export const getPpaInsightExportData = (options: IOptions) => {
  const url = generateUrl('ppa-insights-export', options);
  return instance.get(url);
};

export const getVolumeByRegionChartData = (formData: object) => {
  return instance.post(volumeByRegionChartAPIUrl, formData, {});
};

export const getVolumeByRegionTableData = (formData: object) => {
  return instance.post(volumeByRegionTableAPIUrl, formData, {});
};

// END :: APIs for PPA Insight

// BIA reports APIs
export const getBiaValidationCheckData = (options: IOptions) => {
  return instance.post(biaValidationCheckApiUrl, options, {});
};

export const getBiaUncatCptyData = (options: IOptions) => {
  return instance.post(biaUncatCptyApiUrl, options, {});
};

export const getBiaInactiveCptyData = (options: IOptions) => {
  return instance.post(biaInactiveCptyApiUrl, options, {});
};

export const getBiaGroupAffData = (options: IOptions) => {
  return instance.post(biaGroupAffApiUrl, options, {});
};

export const regionVolumeDetailsTableData = (formData: object) => {
  return instance.post(regionVolumeDetailsTableApiUrl, formData, {});
};

export const getAnalyticsSnapshotReportLineChartData = (formData: object) => {
  return instance.post(AnalyticsSnapshotReportLineChartApiUrl, formData, {});
};

export const getAnalyticsNcfSnapshotReportTableData = (formData: object) => {
  return instance.post(AnalyticsNcfSnapshotTableApiUrl, formData, {});
};

export const getAnalyticsSnapshotVolumeByRegionChartData = (
  formData: object
) => {
  return instance.post(
    AnalyticsSnapshotVolumeByRegionChartApiUrl,
    formData,
    {}
  );
};

export const getAnalyticsSnapshotVolumeByRegionTableData = (
  formData: object
) => {
  return instance.post(
    AnalyticsSnapshotVolumeByRegionTableApiUrl,
    formData,
    {}
  );
};

export const getAnalyticsSnapshotReportsData = (formData: object) => {
  return instance.post(AnalyticsSnapshotReportsApiUrl, formData, {});
};

export const getSnapshotRegionVolumeDetailsTableData = (formData: object) => {
  return instance.post(
    AnalyticsSnapshotVolumeByRegionDetailsApiUrl,
    formData,
    {}
  );
};
// PPT Reporting APIs
export const pptNcfVolume = (formData: object) => {
  return instance.post(pptNcfVolumeUrl, formData, {});
};

export const pptNcfCategory = (formData: object) => {
  return instance.post(pptNcfCategoryUrl, formData, {});
};

export const pptReports = (formData: object) => {
  return instance.post(pptReportsUrl, formData, {});
};

// Reconcdiliation Reports APIs

export const reconReportTotalVolume = (formData: object) => {
  return instance.post(reconReportTotalVolumeApiUrl, formData, {});
};

export const reconReportVolumeTableData = (formData: object) => {
  return instance.post(reconReportVolumeTableDataApiUrl, formData, {});
};

export const reconReportNcfCategoryMismatchTableData = (formData: object) => {
  return instance.post(reconReportNcfCategoryMismatchTableDataApiUrl, formData, {});
};

export const reconReportLastRefreshedDate = (formData: object) => {
  return instance.post(reconReportLastRefreshedDateApiUrl, formData, {});
};

export const reconFilterOptions = (formData: object) => {
  return instance.post(reconFilterOptionsApiUrl, formData, {});
};
