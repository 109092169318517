import { ICiTradeAndVolumeData } from '../../types/types';
import { REGION_NAMES } from '../../api/constants';

export const CiTradeAndVolumeInitialState: ICiTradeAndVolumeData = {
  error: {
    isError: false,
    message: '',
  },
  ciTradeChartData: [
    {
      YEAR: 0,
      QUARTER: '',
      UNIT: '',
      QTY: 0,
      PARAMETER_CD: '',
    },
  ],
  ciTradeTableData: [
    {
      CPTY: '',
      CPTY_DESC: '',
      YEAR: 0,
      MONTH: '',
      QTY: 0,
      NCF_CATEGORY: '',
      DEAL_TYPE_NAME: '',
      NCF_SCOPE: '',
    },
  ],
  ciTradeFilteredValues: {
    year: ['2024'],
    quarter: [],
    region: REGION_NAMES.ASIA,
    uom: 'TWh',
    commodity: [],
  },
  ciTradeFilterOptions: {
    year: [],
    quarter: [],
    region: [],
    unitName: [],
    commodity: [],
  },
};

export type ICiTradeAndVolumeDataState = typeof CiTradeAndVolumeInitialState;
