import { Action, IppaInsightData } from '../../types/types';

const ppaInsightActionHandlers: Record<
  string,
  (state: IppaInsightData, payload: any) => IppaInsightData
> = {
  setIsLoading: (state, payload) => ({
    ...state,
    isLoading: payload,
  }),
  setIsPpaServerError: (state, payload) => ({
    ...state,
    isPpaServerError: payload,
  }),
  setFilterOptions: (state, payload) => ({
    ...state,
    filterOptions: payload,
  }),
  setFilteredValues: (state, payload) => ({
    ...state,
    filteredValues: payload,
  }),
  setTableData: (state, payload) => ({
    ...state,
    tableData: payload,
  }),
  setVolumeByRegionData: (state, payload) => ({
    ...state,
    volumeByRegionData: payload,
  }),
  setVolumeByTechTypeData: (state, payload) => ({
    ...state,
    volumeByTechTypeData: payload,
  }),
  setLongVsShortTermData: (state, payload) => ({
    ...state,
    longVsShortTermData: payload,
  }),
};

export const PpaInsightReducers = (state: IppaInsightData, action: Action) => {
  if (Object.hasOwn(ppaInsightActionHandlers, action.type)) {
    const handler = ppaInsightActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
