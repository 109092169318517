import React, { useContext, useEffect } from 'react';
import { ChartContainer } from './PPAInsightPieCharts.style';
import { GlassChart } from '@sede-x/glass-design-library';
import { AppContext } from '../../../../../Context/AppContext';
import { getLongVsShortTermData } from '../../PpaInsightUtils';
import { IppaInsightLongVsShortTermData } from '../../../../../types/types';
import { PPAInsightNoRecords } from '../../PPAInsightNoRecordsPage/PPAInsightNoRecords';

export const PPAInsightLongVsShortTermPieChart = () => {
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } = useContext(
    AppContext
  ).ppaInsight;
  useEffect(() => {
    getLongVsShortTermData(ppaInsightDispatch, ppaInsightState.filteredValues);
  }, [ppaInsightState.filteredValues]);

  const item: IppaInsightLongVsShortTermData =
    ppaInsightState.longVsShortTermData;

  const chartData = [
    {
      name: 'Long-Term',
      y: item.longTermTotal,
      sliced: true,
      selected: true,
    },
    {
      name: 'Short Term',
      y: item.shortTermTotal,
    },
  ];

  const options = {
    title: {
      text: 'Long-Term vs Short-Term Deals',
      align: 'left',
    },
    subtitle: {
      text: 'A summary to compare Long-term vs short-term deals',
      align: 'left',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Example',
        colorByPoint: true,
        data: chartData,
      },
    ],
  };
  return (
    <ChartContainer>
      {ppaInsightState.longVsShortTermData.longTermTotal === 0 && (
        <PPAInsightNoRecords />
      )}
      {ppaInsightState.longVsShortTermData.longTermTotal !== 0 && (
        <GlassChart options={options} />
      )}
    </ChartContainer>
  );
};
