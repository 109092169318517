import { IEditFeatureData, Action } from '../../types/types';

const appDataActionHandlers: Record<
  string,
  (state: IEditFeatureData, payload: any) => IEditFeatureData
> = {
  setAllFieldsValidated: (state, payload) => ({
    ...state,
    allFieldsValidated: payload,
  }),
  setTableData: (state, payload) => ({ ...state, tableData: payload }),
  setMasterTableData: (state, payload) => ({
    ...state,
    masterTableData: payload,
  }),
  setAuditTableData: (state, payload) => ({
    ...state,
    auditTableData: payload,
  }),
  setSpinner: (state, payload) => ({ ...state, spinner: payload }),
  setCurrentPage: (state, payload) => ({ ...state, currentPage: payload }),
  setTableSelected: (state, payload) => ({ ...state, tableSelected: payload }),
  setFilteredOptions: (state, payload) => ({
    ...state,
    filteredOptions: payload,
  }),
  setMarketOptions: (state, payload) => ({ ...state, marketOptions: payload }),
  setNcfCategoryOptions: (state, payload) => ({
    ...state,
    ncfCategoryOptions: payload,
  }),
  setBuySellOptions: (state, payload) => ({
    ...state,
    buySellOptions: payload,
  }),
  setFilteredRegion: (state, payload) => ({
    ...state,
    filteredRegion: payload,
  }),
  setRole: (state, payload) => ({ ...state, role: payload }),
  setIsRoleNcf: (state, payload) => ({ ...state, isRoleNcf: payload }),
  setUserRegions: (state, payload) => ({ ...state, userRegions: payload }),
  setUsername: (state, payload) => ({ ...state, username: payload }),
  setMail: (state, payload) => ({ ...state, mail: payload }),
  setRecordCount: (state, payload) => ({ ...state, recordCount: payload }),
  setAuditPaginationData: (state, payload) => ({
    ...state,
    auditPaginationData: payload,
  }),
  setPaginationData: (state, payload) => ({
    ...state,
    paginationData: payload,
  }),
  setMasterPaginationData: (state, payload) => ({
    ...state,
    masterPaginationData: payload,
  }),
  setCurrentPaginationNum: (state, payload) => ({
    ...state,
    currentPaginationNum: payload,
  }),
  setHasTableChangesPublished: (state, payload) => ({
    ...state,
    hasTableChangesPublished: payload,
  }),
  setDirtyData: (state, payload) => ({ ...state, dirtyData: payload }),
  setErroredRows: (state, payload) => ({ ...state, erroredRows: payload }),
  setPublishRows: (state, payload) => ({ ...state, publishRows: payload }),
  setMasterErroredRows: (state, payload) => ({
    ...state,
    masterErroredRows: payload,
  }),
  setEditableCol: (state, payload) => ({ ...state, editableCol: payload }),
  setDefaultPageSize: (state, payload) => ({
    ...state,
    defaultPageSize: payload,
  }),
  setIsGlobalUser: (state, payload) => ({ ...state, isGlobalUser: payload }),

  setCanUserManipulateData: (state, payload) => ({
    ...state,
    canUserManipulateData: payload,
  }),
  setCanUserAddAndSubmitSnapshot: (state, payload) => ({
    ...state,
    canUserAddAndSubmitSnapshot: payload,
  }),
  setCanUserChangeSnapshotStatus: (state, payload) => ({
    ...state,
    canUserChangeSnapshotStatus: payload,
  }),
  setShowAllRegions: (state, payload) => ({
    ...state,
    showAllRegions: payload,
  }),
  setShowConfigRulePage: (state, payload) => ({
    ...state,
    showConfigRulePage: payload,
  }),
  setShowSnapshotActions: (state, payload) => ({
    ...state,
    showSnapshotActions: payload,
  }),
  setIsAustraliaUser: (state, payload) => ({
    ...state,
    isAustraliaUser: payload,
  }),
  setShowSectoralPage: (state, payload) => ({
    ...state,
    showSectoralpage: payload,
  }),
  setIsPpaUser: (state, payload) => ({
    ...state,
    isPpaUser: payload,
  }),

  setIsLoggedLoginEvent: (state, payload) => ({
    ...state,
    isLoggedLogin: payload,
  }),
  setIsAnyInvalidComment: (state, payload) => ({
    ...state,
    isAnyInvalidComment: payload,
  }),
  setIsAnyInvalidCountryName: (state, payload) => ({
    ...state,
    isAnyInvalidCountryName: payload,
  }),
  setIsAnyInvalidQuantity: (state, payload) => ({
    ...state,
    isAnyInvalidQuantity: payload,
  }),
  setShowReport: (state, payload) => ({
    ...state,
    showReport: payload,
  }),
  setShowAuditReport: (state, payload) => ({
    ...state,
    showAuditReport: payload,
  }),
  setIsGetData: (state, payload) => ({
    ...state,
    isGetData: payload,
  }),
};

export const DataEditReducers = (state: IEditFeatureData, action: Action) => {
  if (Object.hasOwn(appDataActionHandlers, action.type)) {
    const handler = appDataActionHandlers[action.type];
    return handler(state, action.payload);
  }
  return state;
};
