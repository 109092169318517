import { FormField, Select, Option } from '@sede-x/shell-ds-react-framework';
import React, { Dispatch, SetStateAction } from 'react';

type DispatchType =
  | Dispatch<SetStateAction<string>>
  | Dispatch<SetStateAction<number>>
  | Dispatch<SetStateAction<string[]>>;

interface SelectFormFieldProps {
  id: string;
  label: string;
  testId: string;
  value: string | number | Array<string | number>;
  onChange: DispatchType;
  options: Array<string | number>;
  mode?: 'single' | 'multiple';
  disabled?: boolean;
}

export const PPASelectFormField: React.FC<SelectFormFieldProps> = ({
  id,
  label,
  testId,
  value,
  onChange,
  options,
  mode = 'single',
  disabled = false,
}) => (
  <FormField id={id} label={label} data-testid={testId}>
    <Select
      size="medium"
      allowClear={false}
      aria-label="Select"
      optionLabelProp="label"
      value={value || undefined}
      onChange={onChange}
      mode={mode}
      disabled={disabled}
    >
      {options.map(val => (
        <Option key={val} value={val}>
          {val}
        </Option>
      ))}
    </Select>
  </FormField>
);
