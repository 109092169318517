import React, { useEffect, useState, useContext } from 'react';
import {
  TooltipFormatterContextObject,
  AxisLabelsFormatterContextObject,
} from 'highcharts';
import {
  ChartContainer,
  TableHeading,
  NcfToggle,
  ScrollableDiv,
} from './PowerNcfVolumeChart.style';
import {
  groupAndSortDataByRegion,
  generateSeries,
  getAllUniqueDatesOrQuarters,
  IRegionQuarterData,
  tooltipFormatter,
  getCommodityName,
  getTitleText,
  fetchDataBasedOnView,
  generateColumns,
  axisLabelFormatter,
} from './NcfVolumeUtils';
import Chart from '../../../Chart/Chart';
import { BaseTable, Flexbox } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../../../Context/AppContext';
import { INcfQuarterwise } from '../../../../../../types/types';
import ChartToggle from '../../../../../ReUsableComponents/PPAToggle/PPAToggle';

const PowerNcfVolumeChart: React.FC = () => {
  const [view, setView] = useState('Chart');
  const { state: analyticsState, dispatch: analyticsDispatch } = useContext(
    AppContext
  ).analyticsHome;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDataBasedOnView(view, analyticsState);
        if (response) {
          analyticsDispatch({
            type: 'setNcfQuarterwise',
            payload: response.data,
          });
        }
      } catch (error) {
        // Log error once toast feature is implemented
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, analyticsDispatch, analyticsState.filteredValues]);

  // Group and sort data by region
  const mapData: INcfQuarterwise[] = analyticsState.ncfQuarterwise;
  const dataByRegion = groupAndSortDataByRegion(mapData);
  const series = generateSeries(dataByRegion);

  const options = {
    xAxis: {
      categories: getAllUniqueDatesOrQuarters(
        dataByRegion as Record<string, IRegionQuarterData[]>
      ),

      title: {
        text: getTitleText(mapData),
      },
      labels: {
        // eslint-disable-next-line no-unused-vars
        formatter(this: AxisLabelsFormatterContextObject) {
          return axisLabelFormatter(this);
        },
      },
    },
    yAxis: {
      title: {
        text: `Cummulative Sum(${analyticsState?.filteredValues?.uom})`,
      },
    },
    tooltip: {
      shared: false,
      // eslint-disable-next-line no-unused-vars
      formatter(this: TooltipFormatterContextObject) {
        return tooltipFormatter(this, mapData);
      },
    },
    series,
  };

  return (
    <ChartContainer>
      <Flexbox style={{ width: '100%' }} justifyContent="space-between">
        <TableHeading>
          {getCommodityName(analyticsState?.filteredValues?.commodity[0])} NCF
          Volume
        </TableHeading>
        <NcfToggle>
          <ChartToggle setView={setView} />
        </NcfToggle>
      </Flexbox>
      {view === 'Table' ? (
        <ScrollableDiv>
          <BaseTable
            columns={generateColumns()}
            data={mapData}
            size="large"
            data-testid="base-table"
          />
        </ScrollableDiv>
      ) : (
        <Chart options={options} footer={'Source'} />
      )}
    </ChartContainer>
  );
};

export default PowerNcfVolumeChart;
