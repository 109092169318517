import {
  getVolumeByRegionTableData,
  getVolumeByRegionChartData,
} from '../../../../../../api/services';

const initialColors = {
  colorRed: '#ff887b',
  colorYellow: '#FBCF09',
  colorGreen: '#6FB993',
};

const greyOutColors = {
  colorRed: '#ffc3bd',
  colorYellow: '#fde683',
  colorGreen: '#b7dcc9',
};

export const WHOLESALE_CHURN_PARAMETER_NAME = 'Wholesale Churn';
export const OUT_OF_SCOPE_VOLUME_PARAMETER_NAME = 'out of scope volume';

export interface MapDataItem {
  PARAMETER_NAME: string;
  QTY?: number;
}

export interface IRegionData {
  PARAMETER_NAME: string;
  QTY?: number;
  isSum?: boolean;
  color?: string;
}

export interface ISeriesData {
  name: string;
  y?: number;
}

export interface IRegionQuarterData extends IRegionData {
  QUARTER: string;
  YEAR: number;
}

interface FilteredValues {
  year: string[];
  quarter: string[];
  month: string[];
  region: string[];
  countryCode: string[];
  commodity: string[];
  uom: string;
  ncfType: string;
}

export interface AnalyticsState {
  filteredValues: FilteredValues;
}

export const generateSeries = (
  responseData: IRegionData[],
  selectedParameter: string
) => {
  return [
    {
      name: 'Volume',
      data: generateSeriesData(responseData, selectedParameter),
      dataLabels: {
        enabled: true,
        format: '{point.y:.2f}',
        verticalAlign: 'top',
        y: -20,
      },
      pointPadding: 0,
      borderWidth: 0,
    },
  ];
};

export const getColor = (
  item: IRegionData,
  selectedParameter: string
): string => {
  const isParameterSelected =
    !selectedParameter || item.PARAMETER_NAME === selectedParameter;
  const isParameterSelectedForChurn =
    WHOLESALE_CHURN_PARAMETER_NAME === selectedParameter &&
    (!selectedParameter ||
      item.PARAMETER_NAME.toLowerCase() === OUT_OF_SCOPE_VOLUME_PARAMETER_NAME);

  if (item.QTY === undefined) {
    return isParameterSelected
      ? initialColors.colorRed
      : greyOutColors.colorRed;
  }

  if (item.QTY > 0) {
    const colorName = isParameterSelectedForChurn
      ? initialColors.colorYellow
      : greyOutColors.colorYellow;
    return isParameterSelected ? initialColors.colorYellow : colorName;
  }

  const color = isParameterSelectedForChurn
    ? initialColors.colorRed
    : greyOutColors.colorRed;
  return isParameterSelected ? initialColors.colorRed : color;
};

const generateSeriesData = (
  responseData: IRegionData[],
  selectedParameter: string
): ISeriesData[] => {
  const specialProduct = responseData
    ?.filter((el: IRegionData) => el.PARAMETER_NAME === 'NCF Volume')
    .map((el: IRegionData) => ({
      name: el.PARAMETER_NAME,
      color:
        !selectedParameter || el.PARAMETER_NAME === selectedParameter
          ? initialColors.colorGreen
          : greyOutColors.colorGreen,
      y: el.QTY,
      isSum: true,
    }));

  const otherProducts = responseData
    ?.filter((el: IRegionData) => el.PARAMETER_NAME !== 'NCF Volume')
    .map((el: IRegionData) => ({
      name:
        el.PARAMETER_NAME.toLowerCase() === OUT_OF_SCOPE_VOLUME_PARAMETER_NAME
          ? WHOLESALE_CHURN_PARAMETER_NAME
          : el.PARAMETER_NAME,
      y: el.QTY,
      color: getColor(el, selectedParameter),
    }));

  return [...(otherProducts || []), ...(specialProduct || [])];
};

export const getCommodityName = (abbreviation: string) => {
  switch (abbreviation) {
    case 'PW':
      return 'Power';
    case 'NG':
      return 'Natural Gas';
    default:
      return 'Power';
  }
};

export const fetchDataBasedOnView = async (
  view: string,
  analyticsState: AnalyticsState,
  region: string
) => {
  let response;
  try {
    const payload = {
      ...analyticsState.filteredValues,
      region: [region],
    };
    if (view === 'Table') {
      response = await getVolumeByRegionTableData(payload);
    } else {
      response = await getVolumeByRegionChartData(payload);
    }
  } catch (error) {
    // Log error once toast feature is implemented
  }
  return response;
};

export const generateColumns = (mapData: IRegionData[]) => {
  const staticColumns = ['YEAR', 'QUARTER', 'MONTH'];

  const dynamicColumns = mapData.map(
    (item: IRegionData) => item.PARAMETER_NAME
  );
  const columnNames = [...staticColumns, ...dynamicColumns];
  return columnNames.map(key => ({
    header: key,
    accessorKey: key,
  }));
};
