import React, { useContext, useEffect, useMemo } from 'react';
import { Divider, Flexbox, Grid } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../../../Context/AppContext';
import { DataValidationCheckView } from './DataValidationCheckView';
import NcfReportingHomeHeader from '../NcfReportingUtils/NcfReportingHomeHeader/NcfReportingHomeHeader';
import { UncategorisedCptyView } from './UncategorisedCptyView';
import { InactiveCptyView } from './InactiveCptyView';
import { GroupAffListView } from './GroupAffListView';
import { BiaReportsFilterSection } from './BiaReportsFilterSection';
import BiaDateSideCard from './BiaDateSideCard/BiaDateSideCard';
import { NcfServerErrorMessage } from '../NcfReportingUtils/NcfServerErrorMessage';

export const BiaReportPage = () => {
  const { dispatch } = useContext(AppContext).appData;
  const { state } = useContext(AppContext).biaReports;
  useEffect(() => {
    dispatch({ type: 'setCurrentPage', payload: '' });
  }, []);
  const isRegionEurope = useMemo(
    () => state.biaFilterData.biaRegion.toLowerCase() === 'europe',
    [state.biaFilterData.biaRegion]
  );
  const isRegionEuropeAmericas = useMemo(
    () =>
      isRegionEurope ||
      state.biaFilterData.biaRegion.toLowerCase() === 'americas',
    [isRegionEurope, state.biaFilterData.biaRegion]
  );

  return (
    <div
      style={{ height: '100%', background: '#F5F5F5' }}
      data-testid="analytics-home"
    >
      <Flexbox
        gap="24px 24px"
        style={{ marginLeft: '15px', justifyContent: 'start' }}
      >
        <div style={{ width: '15%' }}>
          <BiaReportsFilterSection />
        </div>
        <div style={{ width: '85%' }}>
          <Grid gap="10px">
            <Grid.Cell>
              <Flexbox justifyContent="space-between">
                <NcfReportingHomeHeader
                  title="BIA Reports"
                  description="Provide subtitle here"
                />
                <BiaDateSideCard />
              </Flexbox>
            </Grid.Cell>
            <Divider size="medium" direction="horizontal" />
            <Grid.Cell>
              <div style={{ width: '99%' }}>
                <DataValidationCheckView />
              </div>
              <NcfServerErrorMessage />
            </Grid.Cell>
            <Grid.Cell>
              {isRegionEuropeAmericas ? (
                <div style={{ width: '99%' }}>
                  <Flexbox alignContent="flex-start" gap="15px">
                    <div style={{ width: '50%' }}>
                      <UncategorisedCptyView />
                    </div>
                    <div style={{ width: '50%' }}>
                      <InactiveCptyView />
                    </div>
                  </Flexbox>
                </div>
              ) : (
                <div style={{ width: '99%' }}>
                  <UncategorisedCptyView />
                </div>
              )}
            </Grid.Cell>
            {isRegionEurope && (
              <Grid.Cell>
                <div style={{ width: '99%' }}>
                  <GroupAffListView />
                </div>
              </Grid.Cell>
            )}
          </Grid>
        </div>
      </Flexbox>
    </div>
  );
};
