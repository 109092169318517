import React, { useContext } from 'react';
import { Divider, Heading } from '@sede-x/shell-ds-react-framework';
import { AppContext } from '../../Context/AppContext';
import { CustomGlobalHeader } from '../GlobalHeader/CustomGlobalHeader';

const HeaderBar = (props: any) => {
  const { state } = useContext(AppContext).appData;
  return (
    <div data-testid="header_bar">
      <CustomGlobalHeader logout={props.logout} />
      <Divider size="medium" direction="horizontal" />
      {state.currentPage && (
        <Heading style={styles.heading}>{state.currentPage}</Heading>
      )}
    </div>
  );
};
export default HeaderBar;

const styles = {
  heading: {
    margin: '30px 0 0 20px',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '24px',
    fontFamily: 'Shell Font',
  },
};
