import { Dispatch } from 'react';
import {
  getPpaInsightFormFilterOptions,
  getPpaInsightVolumeByRegionData,
  getPpaInsightLongVsShortTermData,
  getPpaInsightVolByTechTypeData,
  getPpaInsightExportData,
  getPpaInsightTableData,
} from '../../../api/services';
import {
  Action,
  IOptions,
  IppaInsightFilteredValues,
} from '../../../types/types';
import { FIVE, ONE } from '../../../api/constants';

export const getAndSetFilterOptions = async (
  ppaInsightDispatch: Dispatch<Action>
) => {
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightFormFilterOptions();
    const filterOptionsData = result.data;
    ppaInsightDispatch({
      type: 'setFilterOptions',
      payload: filterOptionsData,
    });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getVolumeByRegionData = async (
  ppaInsightDispatch: Dispatch<Action>,
  filteredValues: IppaInsightFilteredValues
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightVolumeByRegionData(options);
    ppaInsightDispatch({ type: 'setVolumeByRegionData', payload: result.data });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getLongVsShortTermData = async (
  ppaInsightDispatch: Dispatch<Action>,
  filteredValues: IppaInsightFilteredValues
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightLongVsShortTermData(options);
    ppaInsightDispatch({
      type: 'setLongVsShortTermData',
      payload: result.data,
    });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getVolumeByTechTypeData = async (
  ppaInsightDispatch: Dispatch<Action>,
  filteredValues: IppaInsightFilteredValues
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightVolByTechTypeData(options);
    ppaInsightDispatch({
      type: 'setVolumeByTechTypeData',
      payload: result.data,
    });
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const handleExportData = async (
  ppaInsightDispatch: Dispatch<Action>,
  filteredValues: IppaInsightFilteredValues
) => {
  const options = filterEmptyValues(filteredValues);
  showPpaSpinner(ppaInsightDispatch);
  try {
    // Make a GET request to download the CSV file from the API
    const response = await getPpaInsightExportData(options);
    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: 'text/csv' });
    const filename = 'PPA_Insight_Data';
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Programmatically create a link
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    // Simulate a click event to initiate the download
    document.body.appendChild(link);
    link.click();
    // Cleanup: Remove the temporary URL and the link element
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export const getTableData = async (
  ppaInsightDispatch: Dispatch<Action>,
  filteredValues: IppaInsightFilteredValues,
  setColumns?: Dispatch<{ header: string; accessorKey: string }[]>,
  setCount?: Dispatch<number>
) => {
  const options = filterEmptyValues({
    pageNumber: filteredValues.pageNumber ?? ONE,
    pageSize: filteredValues.pageNumber ?? FIVE,
    ...filteredValues,
  });
  showPpaSpinner(ppaInsightDispatch);
  try {
    const result = await getPpaInsightTableData(options);
    ppaInsightDispatch({
      type: 'setTableData',
      payload: result.data,
    });
    if (setCount) {
      setCount(result.data.count);
    }
    if (result.data.count > 0) {
      // Get keys from the first row
      const firstRow = result.data.rows[0];
      const keys = Object.keys(firstRow);

      // Generate columns based on keys
      const columns = keys.map(key => ({
        header: key.toUpperCase().replace(/_/g, ' '),
        accessorKey: key,
      }));
      if (setColumns) {
        setColumns(columns);
      }
    }
  } catch (e) {
    ppaInsightDispatch({
      type: 'setIsPpaServerError',
      payload: true,
    });
  }
  hidePpaSpinner(ppaInsightDispatch);
};

export function filterEmptyValues(obj: IppaInsightFilteredValues) {
  const filteredObj: IOptions = {};
  for (const key in obj) {
    if (obj[key as keyof typeof obj]) {
      filteredObj[key] = obj[key as keyof typeof obj];
    }
  }
  return filteredObj;
}

export const showPpaSpinner = (ppaInsightDispatch: Dispatch<Action>) => {
  ppaInsightDispatch({
    type: 'setIsLoading',
    payload: true,
  });
};

export const hidePpaSpinner = (ppaInsightDispatch: Dispatch<Action>) => {
  ppaInsightDispatch({
    type: 'setIsLoading',
    payload: false,
  });
};
