import {
  Grid,
  Divider,
  Flexbox,
  Button,
  Positions,
} from '@sede-x/shell-ds-react-framework';
import { Download } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import React, { useContext, useState } from 'react';
import { PPAInsightColumnChart } from '../PPAInsightCharts/PPAInsightColumnCharts/PPAInsightColumnCharts';
import { PPAInsightLongVsShortTermPieChart } from '../PPAInsightCharts/PPAInsightPieCharts/PPAInsightLongVsShortTermPieChart';
import { PPAInsightVolByRegionPieChart } from '../PPAInsightCharts/PPAInsightPieCharts/PPAInsightVolByRegionPieChart';
import PPAInsightHeader from '../PPAInsightHeader/PPAInsightHeader';
import { PPAInsightTable } from '../PPAInsightTable/PPAInsightTable';
import { handleExportData } from '../PpaInsightUtils';
import { AppContext } from '../../../../Context/AppContext';
import { TogglePPA } from './PPAInsightDataView.style';
import ChartToggle from '../../../ReUsableComponents/PPAToggle/PPAToggle';

export const PPAInsightDataView = () => {
  const [view, setView] = useState('Chart');
  const { state: ppaInsightState, dispatch: ppaInsightDispatch } = useContext(
    AppContext
  ).ppaInsight;
  const { state } = useContext(AppContext).appData;
  return (
    <Grid gap="10px" flow="row">
      <Grid.Cell>
        <Grid flow="column" justifyContent="space-between">
          <Grid.Cell>
            <PPAInsightHeader />
          </Grid.Cell>
          <Grid.Cell>
            <TogglePPA>
              <ChartToggle setView={setView} />
            </TogglePPA>
          </Grid.Cell>
        </Grid>
        <Divider
          size="medium"
          style={{ marginTop: '20px', marginBottom: '10px' }}
        />
      </Grid.Cell>
      <Grid.Cell>
        {view === 'Table' && (
          <Grid>
            {ppaInsightState.tableData.count > 0 && (
              <Grid.Cell height={30}>
                <Flexbox justifyContent="flex-end">
                  <Button
                    variant="filled"
                    iconPosition={Positions.Left}
                    icon={<Download />}
                    data-testid="export-button"
                    disabled={!state.isPpaUser}
                    onClick={() =>
                      handleExportData(
                        ppaInsightDispatch,
                        ppaInsightState?.filteredValues
                      )
                    }
                  >
                    Export
                  </Button>
                </Flexbox>
              </Grid.Cell>
            )}
            <Grid.Cell>
              <PPAInsightTable />
            </Grid.Cell>
          </Grid>
        )}
        {view === 'Chart' && (
          <Grid>
            <Grid.Cell>
              <Flexbox gap="10px" data-testid="chart-maps-1">
                <PPAInsightVolByRegionPieChart />
                <PPAInsightLongVsShortTermPieChart />
              </Flexbox>
            </Grid.Cell>
            <Grid.Cell>
              <Flexbox justifyContent="flex-start" data-testid="chart-maps-2">
                <PPAInsightColumnChart />
              </Flexbox>
            </Grid.Cell>
          </Grid>
        )}
      </Grid.Cell>
    </Grid>
  );
};
