import React, { useContext } from 'react';
import { ChartContainer } from './PPAInsightPieCharts.style';
import { GlassChart } from '@sede-x/glass-design-library';
import { AppContext } from '../../../../../../Context/AppContext';

// Accept tradeName as a prop
export const PptInScopeVsOutScope: React.FC<{ tradeName: string }> = ({
  tradeName,
}) => {
  const { state: pptState } = useContext(AppContext).pptReports;

  // Filter volumes based on the tradeName prop
  const filteredVolumes = pptState.pptNcfVolumes.filter(
    volume => volume.tradeName === tradeName
  );

  const inScopeQuantity = filteredVolumes
    .filter(volume => Number(volume.ncfScope) !== 0)
    .reduce((acc, volume) => acc + Number(volume.quantity), 0);
  const totalQuantity = filteredVolumes.reduce(
    (acc, volume) => acc + Number(volume.quantity),
    0
  );
  const outOfScopeQuantity = totalQuantity - inScopeQuantity;

  // Calculate percentages
  const inScopePercentage = (inScopeQuantity / totalQuantity) * 100;
  const outOfScopePercentage = (outOfScopeQuantity / totalQuantity) * 100;

  // Prepare chart data
  const chartData = [
    { name: 'In Scope', y: inScopePercentage },
    { name: 'Out of Scope', y: outOfScopePercentage },
  ];

  // Chart options
  const options = {
    title: {
      text: `${tradeName} - In Scope vs Out of Scope`,
      align: 'left',
    },
    subtitle: {
      text: `An overview of In Scope vs Out of Scope for ${tradeName}`,
      align: 'left',
    },
    chart: {
      type: 'pie',
    },
    series: [
      {
        name: 'Scope',
        colorByPoint: true,
        data: chartData,
      },
    ],
  };

  // Conditionally render the chart or a no data message
  return (
    <ChartContainer>
      {totalQuantity > 0 ? (
        <GlassChart options={options} />
      ) : (
        <div>No data available for {tradeName}</div>
      )}
    </ChartContainer>
  );
};
